import React, { useState, useEffect, useRef } from 'react';
import TabletSignatureCanvas from '../Components/Signature/signaturecontainer';
import TopazSignatureCanvas from '../Components/Signature/topazsigncanvas.js';
import ReceivingNotes from '../Components/Notes/index.js';
import DeviceChooserFragment from './devicechooser';
import { Link, useParams } from 'react-router-dom';
import { doc, serverTimestamp, writeBatch, collection, addDoc, updateDoc } from 'firebase/firestore';
import * as Yup from "yup"
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
import { useFirestore, useFirestoreDocData, useAuth } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions.js';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import ConfirmDispatch from './confirmdispatch';
import MetadataCapture from './metadatacapture';
import useJobCardDevices from '../Components/Hooks/useJobcardDevices';

export default function JobCard(props) {
    // HOOKS
    const auth = useAuth();
    const { jobCardId } = useParams();
    const formikRef = useRef(null);
    const navigate = useNavigate();
    const jobcardDevices = useJobCardDevices(jobCardId);

    // STATE
    const [useTopaz, setUseTopaz] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const [signatureBase64, setSignatureBase64] = useState(undefined); // state lifted from topaz component

    // EFFECTS

    useEffect(() => {
        // check for Topaz
        if (isTopazInstalled()) {
            setUseTopaz(true);
        }
    }, []);

    // FIRESTORE fixme: replace with a hook
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'jobcards', jobCardId);
    const { status, data: jobcardRecord } = useFirestoreDocData(documentRef);

    if (status === 'loading') {
        return "Loading...";
    }

    if (jobcardRecord === undefined) {
        return "No such job card";
    }

    // FORM

    const initialValues = { selectedDeviceList: [] };
    const validationSchema = Yup.object({
        selectedDeviceList: Yup.array().required("At least one device is required").min(1),
        metadata: Yup.array(),
        notes: Yup.string().optional()
    });

    async function handleSubmit(form) {
        // alert if user selected devices that aren't calibrated
        if (uncalibratedDevicesSelected() === true) {
            if (window.confirm('One or more devices are not yet calibrated, are you sure you want to proceed?')) {
                console.log("Ignoring and proceeding");
            } else {
                return;
            }
        }

        setFormLoading(true);

        // recheck the form out of caution
        try {
            await validationSchema.validate(form);
            closeErrorBanner();
        } catch (err) {
            showErrorBanner("Form is invalid");
            return;
        }

        // 1. create an event against the job card
        const collectionRef = collection(firestore, `jobcards/${jobCardId}/events`);
        var newRecord = {
            dts: serverTimestamp(),
            action: "dispatch",
            devices: form.selectedDeviceList
        };

        // inject metadata as an array field if any entries were set
        if (form.metadata && form.metadata.length > 0) {
            form.metadata.forEach(item => {
                newRecord[item.fieldKey] = item.fieldValue;
            });
        }

        // inject signature image
        if (signatureBase64 !== undefined) {
            newRecord.signatureBase64 = signatureBase64;
        }

        // inject notes
        if (form.notes !== undefined) {
            newRecord.notes = form.notes;
        }

        await addDoc(collectionRef, newRecord);

        // 2. mark each selected device as dispatched
        const batch = writeBatch(firestore);
        form.selectedDeviceList.forEach(async deviceId => {
            const deviceDocRef = doc(firestore, `jobcards/${jobCardId}/devices`, deviceId);
            batch.set(deviceDocRef, { dispatchStatus: "Dispatched" }, { merge: true });
        });
        await batch.commit();

        // 3. if all devices dispatched, close the job card
        if (allDevicesDispatched()) {
            const jobcardRef = doc(firestore, "jobcards", jobCardId);
            await updateDoc(jobcardRef, { status: "Dispatched" });
        }

        // log this event
        const logConfiguration = collection(firestore, 'calibrationlogs');
        var userDisplayName = "Not set";
        if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
            userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        }
        const descWord = (form.selectedDeviceList.length === 1) ? 'device' : 'devices';
        await addDoc(logConfiguration, {
            user: auth.currentUser.uid,
            dts: serverTimestamp(),
            reference: jobCardId,
            success: true,
            action: "Dispatch units",
            detail: `${form.selectedDeviceList.length} ${descWord} for ${jobcardRecord.clientName}`,
            username: userDisplayName
        });

        // show dialog and then navigate to dashboard
        showSuccessDialog();
    }

    // HELPERS

    function allDevicesDispatched() {
        var numDispatchedOrBeingDispatched = 0;
        jobcardDevices.forEach(device => {
            if ((device.dispatchStatus && device.dispatchStatus === "Dispatched") || formikRef.current.values.selectedDeviceList.includes(device.id)) {
                numDispatchedOrBeingDispatched++;
            }
        });

        // test and return
        if (jobcardDevices.length === numDispatchedOrBeingDispatched) {
            return true;
        }

        return false;
    }

    function uncalibratedDevicesSelected() {
        var matchFound = false;
        jobcardDevices.forEach(device => {
            if (device.status !== "Calibration complete" && formikRef.current.values.selectedDeviceList.includes(device.id)) {
                matchFound = true;
            }
        });

        return matchFound;
    }

    function isTopazInstalled() {
        var isInstalled = document.documentElement.getAttribute('SigPlusExtLiteExtension-installed');

        if (!isInstalled) {
            return false;
        }

        return true;
    }

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations/dispatch'); // outta here
    }

    function showErrorBanner(msg) {
        setLastErrorMessage(msg);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    // LIFTING STATE

    // from Notes
    const handleNotesChange = async (newVal, setFieldValue, setFieldTouched) => {
        await setFieldValue('notes', newVal, true);
        await setFieldTouched('notes', newVal, true);
    }

    // from MetadataCapture
    const handleMetadataChange = async (newVal, setFieldValue, setFieldTouched) => {
        await setFieldValue('metadata', newVal, true);
        await setFieldTouched('metadata', newVal, true);
    }

    // Lifting state helper from SignatureCanvas & TopazSignatureCanvas
    const handleSelectedDevicesChange = async (newVal, setFieldValue, setFieldTouched) => {
        await setFieldValue('selectedDeviceList', newVal, true);
        await setFieldTouched('selectedDeviceList', true);
    }

    // from ConfirmDispatch
    const handleConfirmChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    // Lifting state helper from SignatureCanvas & TopazSignatureCanvas
    const handleSignatureMethodChange = (newVal) => {
        setUseTopaz(newVal);
    }

    // Lifting state helper from SignatureCanvas & TopazSignatureCanvas
    const onTopazSignatureReceived = (newVal) => {
        setSignatureBase64(newVal);
    }

    // Lifting state helper from SignatureCanvas
    const onCanvasSignatureReceived = (newVal) => {
        setSignatureBase64(newVal);
    }

    return (
        <div>
            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, setFieldValue, setFieldTouched, values }) => (
                    <form onSubmit={handleSubmit} className="">
                        <div>
                            <ConfirmDispatch open={successDialogOpen} onChange={handleConfirmChange} />

                            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Overview &nbsp;

                                        {jobcardRecord.status === 'Open' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Open </p>}
                                        {jobcardRecord.status === 'Complete' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Job complete </p>}
                                        {jobcardRecord.status === 'Quoting' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-pink-100 text-pink-800"> Awaiting quote </p>}
                                        {jobcardRecord.status === 'Dispatched' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Dispatched </p>}
                                    </h3>
                                    {formLoading && <LoadingBanner />}
                                    {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                </div>


                                {/* Misc details */}
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-4">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Customer:</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {jobcardRecord.clientName && jobcardRecord.clientName}
                                                    {!jobcardRecord.clientName && "(Not set)"}
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Customer name on certificate:</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {jobcardRecord.certificateClientName && <div>{jobcardRecord.certificateClientName}</div>}
                                                    {!jobcardRecord.certificateClientName && "(Not set)"}
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Date received</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {formatDate(jobcardRecord.created.toDate())}
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Account Manager</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {jobcardRecord.accountManager && jobcardRecord.accountManager.displayName}
                                                    {!jobcardRecord.accountManager && "Not set"}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 shadow sm:rounded-md sm:overflow-hidden">
                                <div className="bg-white p-6 px-4 space-y-6 sm:p-6">
                                    <div>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Devices</h3>
                                        <p className="mt-1 text-sm text-gray-500">Select the devices being dispatched from the list below:</p>
                                    </div>

                                    <DeviceChooserFragment jobcardId={jobCardId} handler={handleSelectedDevicesChange} sfv={setFieldValue} sft={setFieldTouched} />
                                </div>
                            </div>

                            <div className="my-4">
                                <MetadataCapture handler={handleMetadataChange} sfv={setFieldValue} sft={setFieldTouched} />
                            </div>

                            <div className="my-4">
                                <ReceivingNotes handler={handleNotesChange} sfv={setFieldValue} sft={setFieldTouched} />
                            </div>

                            {/* Signature */}
                            <div className="my-4">
                                {useTopaz && <TopazSignatureCanvas onSignatureMethodChange={handleSignatureMethodChange} onSignatureReceived={onTopazSignatureReceived} />}
                                {!useTopaz && <TabletSignatureCanvas onSignatureReceived={onCanvasSignatureReceived} onSignatureMethodChange={handleSignatureMethodChange} />}
                            </div>

                            {/* Button actions */}
                            <div className="mt-5 flex justify-end">
                                <Link to={`/calibrations/jobcard/${jobCardId}`}>
                                    <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Cancel
                                    </button>
                                </Link>
                                <button type="submit" disabled={formLoading || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                    Dispatch
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}
