import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import UsersListFragment from './userslist.js';
import CreateUserFragment from './createuser.js';
import EditUserFragment from './edituser.js';
import UserDetailsFragment from './userdetails.js';

export default function UsersSection(props) {
    const userinfoData = props.userinfo;
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        <Routes>
                            <Route path="*" element={<UsersListFragment userinfo={userinfoData} />} />
                            <Route path="create" element={<CreateUserFragment userinfo={userinfoData} />} />
                            <Route path="user/:userId" element={<UserDetailsFragment userinfo={userinfoData} />} />
                            <Route path="edit/:userId" element={<EditUserFragment userinfo={userinfoData}/>} />
                        </Routes>
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}