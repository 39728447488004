import { collection, query, orderBy, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export default function useUsersList(clientGuid = "7VSUnp2eIdZQlH0GBYsq") {
    const firestore = useFirestore();
    const userInfoCollection = collection(firestore, 'userinfo');

    var activeQuery = query(userInfoCollection, where('clientid', '==', clientGuid), where('enabled', '==', true), orderBy('lname', 'asc'),);
    const { status, data: users } = useFirestoreCollectionData(activeQuery, {
        idField: 'id', // merge id into object created for each document
    });

    if (status === 'loading') {
        return undefined;
    }

    return users;
}
