import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { useFirestore, useAuth } from 'reactfire';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"

// TODO: autofocus customer name https://blog.logrocket.com/how-to-autofocus-using-react-hooks/

// will call props.onChange when done
export default function AddCustomerInline(props) {
    // HOOKS
    const auth = useAuth();
    const firestore = useFirestore();
    const customersConfiguration = collection(firestore, 'calibrationclients');
    const logConfiguration = collection(firestore, 'calibrationlogs');

    // STATE
    const [open, setOpen] = useState(props.open)
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const [formLoading, setFormLoading] = useState(null);

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    // FUNCTIONS

    function closeDialog() {
        console.log("[AddCustomerInline] closeDialog() returning undefined");
        props.onChange(undefined);
        setOpen(false);
    }

    // FORM

    const initialValues = { address1: '', address2: '', city: '', postcode: '', name: (props.autoc) ? props.autoc : '' };
    const validationSchema = Yup.object({
        name: Yup.string("Enter a customer name").required("Required").test('len', 'Too short', val => (val !== undefined && val.trim().length >= 4)),
        address1: Yup.string("Enter street").optional(),
        address2: Yup.string("Enter street").optional(),
        city: Yup.string("Enter street").optional(),
        postcode: Yup.string("Enter street").optional(),
    });

    async function handleSubmit(form) {
        setLastErrorMessage(null);
        setFormLoading(true);

        const docRef = await addDoc(customersConfiguration, {
            creator: auth.currentUser.uid,
            created: serverTimestamp(),
            name: form.name,
            nameidx: form.name.toLowerCase(),
            deleted: false,
            address1: form.address1,
            address2: form.address2,
            city: form.city,
            postcode: form.postcode,
        }).catch((error) => {
            setFormLoading(false);
            setLastErrorMessage("Error: could not save customer record " + error.message);
            console.log(error);
        })

        // merge user display name into form
        var userDisplayName = "Not set";
        if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
            userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        }

        await addDoc(logConfiguration, {
            user: auth.currentUser.uid,
            dts: serverTimestamp(),
            reference: docRef.id,
            success: true,
            action: "Create customer record",
            detail: `${form.name} created via calibrate screen`,
            username: userDisplayName
        });

        if (docRef !== null) {
            props.onChange({ id: docRef.id, name: form.name });
            setOpen(false);
            setFormLoading(false);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                                    <form onSubmit={handleSubmit} className="">

                                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500"
                                                onClick={() => closeDialog()}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                    Add customer record
                                                </Dialog.Title>
                                                <div className="mt-2 mb-2">
                                                    <p className="text-sm text-gray-500">
                                                        {lastErrorMessage === null && "Use the form below to create a new customer:"}
                                                        {lastErrorMessage !== null && <span className='text-red-700 italic'>{lastErrorMessage}</span>}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* start */}
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2">
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Name &nbsp;
                                                        <ErrorMessage name="name" component="span" className="text-red-700 italic" />
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <input type="text" name="name" id="name" autoFocus autoComplete="off" defaultValue={initialValues.name} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Address line 1 &nbsp;
                                                        <ErrorMessage name="address1" component="span" className="text-red-700 italic" />
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <input type="text" name="address1" id="address1" placeholder='Optional' autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Address line 2 &nbsp;
                                                        <ErrorMessage name="address2" component="span" className="text-red-700 italic" />
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <input type="text" name="address2" id="address2" placeholder='Optional' autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        City &nbsp;
                                                        <ErrorMessage name="ncityame" component="span" className="text-red-700 italic" />
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <input type="text" name="city" id="city" autoComplete="off" placeholder='Optional' onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Postcode &nbsp;
                                                        <ErrorMessage name="postcode" component="span" className="text-red-700 italic" />
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <input type="text" name="postcode" id="postcode" autoComplete="off" placeholder='Optional' onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        {/* end */}
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button type="submit" disabled={formLoading || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm">
                                                Save
                                            </button>

                                            <button type="button" disabled={formLoading} className="disabled:opacity-50 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog()} >
                                                Cancel
                                            </button>

                                            {formLoading && <div><img src='/images/loadingtiny.gif' width='32' height='32' alt='' /></div>}
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}