import { useState, useEffect } from 'react'
import { collection, query } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

// via https://codesandbox.io/s/react-select-all-checkbox-jbub2?file=/src/index.js:383-727

export default function DeviceChooserFragment({ jobcardId, handler, sfv, sft }) {
    // STATE
    const [checkedItems, setCheckedItems] = useState([]);

    // CHANGE HANDLER

    useEffect(() => {
        handler(checkedItems, sfv, sft);
    }, [checkedItems, handler, sfv, sft])

    // FIRESTORE fixme: replace with a hook
    const firestore = useFirestore();
    const calibrationCollection = collection(firestore, `jobcards/${jobcardId}/devices`);
    const activeQuery = query(calibrationCollection);
    const { status, data: deviceRecords } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return "Loading...";
    }

    // CHECKBOX HELPERS

    const handleSelectAll = (e) => {
        e.preventDefault();

        setCheckedItems(deviceRecords.docs.map(device => device.id));
    };

    const handleSelectNone = (e) => {
        e.preventDefault();

        setCheckedItems([]);
    };

    const handleClick = (e) => {
        const { id, checked } = e.target;
        if (checked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter(item => item !== id));
        }
    };

    return (
        <div>
            <button onClick={handleSelectAll} className="mt-0 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                Select all
            </button>
            <button onClick={handleSelectNone} className="ml-4 mt-0 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                Select none
            </button>

            <div className="relative bg-white rounded-md -space-y-px">
                {deviceRecords.size > 0 && <table className="mt-6 min-w-full divide-y divide-gray-200 table-auto">
                    <thead className="bg-gray-50">
                        <tr key='hdrDevices'>
                            <th />
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serial number</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Job card note</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deviceRecords.docs.map((record, idx) => (
                            <tr key={record.id} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td>
                                    {(record.data().dispatchStatus !== "Dispatched") &&
                                        <input onChange={handleClick} checked={checkedItems.includes(record.id)} id={record.id} type="checkbox" className="focus:ring-isober-900 h-4 w-4 text-isober-900 border-gray-300 rounded" />
                                    }
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                    <label htmlFor={record.id}>{record.data().model}</label>
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                    <div>
                                        {record.data().serial} &nbsp;
                                    </div>
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                    {record.data().note !== undefined && record.data().note}
                                    {record.data().note === undefined && "—"}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-900">
                                    <p id="pricing-plans-0-description-1" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0">
                                        {record.data().status === 'Repair complete' && <span className="mt-4 px-2 inline-flex items-center rounded-full text-xs font-medium bg-pink-100 text-pink-800"> Awaiting repair quote </span>}
                                        {record.data().status === 'Not started' && <span className="mt-4 px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Not started </span>}
                                        {record.data().status === 'In progress' && <span className="mt-4 px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> In progress </span>}
                                        {record.data().status === 'Calibration complete' && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Calibration complete </span>}
                                        {record.data().status === 'Scrapped' && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800"> Scrapped </span>}
                                        {record.data().certificategenerated === true && record.data().certificateGuid !== undefined && <span className="ml-2 mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800"> Certificate generated </span>}
                                        {record.data().dispatchStatus && record.data().dispatchStatus === "Dispatched" && <span className="ml-2 mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800"> Dispatched </span>}
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>
        </div>
    )
}