import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, InformationCircleIcon, PrinterIcon, BeakerIcon, CloudDownloadIcon, ExclamationIcon, DocumentTextIcon, BackspaceIcon, BadgeCheckIcon, CollectionIcon } from '@heroicons/react/solid';

export default function DeviceActionMenu({ recordId, calibrationGuid, certificateGuid, status, calibrateHandler, infoHandler, voidHandler, generateHandler, downloadHandler, printHandler, removeHandler, noteHandler, historyHandler, scrapHandler }) {
    // HELPERS

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button disabled={status === 'Scrapped'} className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-isober-50 px-3 py-2 text-xs text-isober-1000 shadow-sm ring-inset ring-gray-300 hover:bg-isober-200 focus:ring-isober-500 disabled:text-gray-400 disabled:hover:bg-gray-100 disabled:bg-gray-100">
                    Actions
                    <ChevronDownIcon className="-mr-1 h-4 w-4 text-isober-1000" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {(status === 'Scrapped' || status === 'Voided') &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#2" onClick={(e) => e.stopPropagation()} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        — No action
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        {calibrationGuid === undefined && status === 'Not started' &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#2" onClick={(e) => calibrateHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <BeakerIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Calibrate now
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        {calibrationGuid !== undefined &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#1" onClick={(e) => infoHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <InformationCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Show cal details
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        {calibrationGuid !== undefined && status !== "Void" &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#2" onClick={(e) => printHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <PrinterIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Print label
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        {calibrationGuid !== undefined && certificateGuid === undefined && status !== "Void" &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#3" onClick={(e) => voidHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <ExclamationIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Void calibration
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        {/* disabled for now */ false && calibrationGuid !== undefined && certificateGuid === undefined &&
                            <Menu.Item>
                                {({ active }) => ( // if certificateGuid == undefined && status == calibration complete
                                    <a href="#4" onClick={(e) => generateHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <BadgeCheckIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Generate certificate
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        {calibrationGuid !== undefined && certificateGuid !== undefined &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#5" onClick={(e) => downloadHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <CloudDownloadIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Download certificate
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        <Menu.Item>
                            {({ active }) => (
                                <a href="#2" onClick={(e) => noteHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                    <DocumentTextIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Edit note
                                </a>
                            )}
                        </Menu.Item>

                        {historyHandler !== undefined && recordId !== undefined &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#2" onClick={(e) => historyHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <CollectionIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Calibration history
                                    </a>
                                )}
                            </Menu.Item>}

                        {/* FIXME ¿allow scrap of a device whose certificate is generated or not? */}
                        {scrapHandler !== undefined && recordId !== undefined &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#2" onClick={(e) => scrapHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <CollectionIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Scrap device
                                    </a>
                                )}
                            </Menu.Item>}

                        {calibrationGuid === undefined && status === 'Not started' &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#2" onClick={(e) => removeHandler(e, recordId)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <BackspaceIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Delete from job card
                                    </a>
                                )}
                            </Menu.Item>
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}