import { collection, query, where, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedCustomerJobcards(startItem, include, customerId, rpp) {
    const firestore = useFirestore();
    const jobcardsCollection = collection(firestore, 'jobcards');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(jobcardsCollection, where('clientGuid', '==', customerId), orderBy('created', 'desc'), limit(rpp));
    } else {
        activeQuery = (include) ? query(jobcardsCollection, where('clientGuid', '==', customerId), orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : query(jobcardsCollection, where('clientGuid', '==', customerId), orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: jobcards } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return jobcards;
}
