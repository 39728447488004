import { collection, query, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedCertificates(startItem, include, rpp) {
    const firestore = useFirestore();
    const certificatesCollection = collection(firestore, 'certificates');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(certificatesCollection, orderBy('created', 'desc'), limit(rpp));
    } else {
        activeQuery = (include) ? query(certificatesCollection, orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : query(certificatesCollection, orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: certificates } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return certificates;
}
