import React, { useState, useRef } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from "yup";
import { useFirestore, useAuth } from 'reactfire';
import ConfirmCreateCrm from './confirmcreatecrm.js';
import { addDoc, collection, serverTimestamp, query, where, getDocs, limit } from 'firebase/firestore';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import DevicesAsCheckboxes from '../Components/DeviceChooser/AsCheckboxes.js';
import { formatDateDMY } from '../../Shared/Components/functions.js';
import { BranchControl } from '../../Stock/BranchControl';

export default function CreateCrmPage(props) {
    // HOOKS
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const firestore = useFirestore();
    const crmConfiguration = collection(firestore, 'calibrationcrm');
    const formLotNumberRef = useRef(null);
    const formRefNumberRef = useRef(null);
    const formSupplierRef = useRef(null);
    const formManufactureRef = useRef(null);
    const formBottleVolRef = useRef(null);
    const formLotVolRef = useRef(null);
    const formExpiryRef = useRef(null);

    // STATE

    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // FORMS

    const initialValues = { bottlenumber: '', bottlevolume: '500 ml', deleted: '', expiry: '', lotnumber: '', lotvolume: '500 gal', manufacture: '', reference: '', supplier: '', validdevices: [], branchId: undefined };
    const validationSchema = Yup.object({
        bottlenumber: Yup.string("Enter bottle number").required("Required"),
        bottlevolume: Yup.string("Enter botlew volume").required("Required"),
        expiry: Yup.string("Enter expiry date").required("Required").test("email-include-domain", 'Invalid date format', (value) => stringToDate(value)),
        lotnumber: Yup.string("Enter lot number").required("Required"),
        lotvolume: Yup.string("Enter lot volume").required("Required"),
        manufacture: Yup.string("Enter manufacture date").required("Required"),
        reference: Yup.number("Enter reference").required("Required").min(0).max(1).typeError('A number is required'),
        supplier: Yup.string("Enter supplier").required("Required"),
        validdevices: Yup.array().required(),
        branchId: Yup.string().oneOf(["jhb", "ct", "dbn", "bw", "cs"]).required(),
    });

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        // a) create bottle
        const docRef = await addDoc(crmConfiguration, {
            creator: auth.currentUser.uid,
            created: serverTimestamp(),
            deleted: false,
            bottlenumber: form.bottlenumber,
            bottlevolume: form.bottlevolume,
            expiry: stringToDate(form.expiry),
            lotnumber: form.lotnumber,
            lotvolume: form.lotvolume,
            manufacture: form.manufacture,
            reference: form.reference,
            supplier: form.supplier,
            validfor: form.validdevices,
            branchId: form.branchId
        }).catch((error) => {
            setFormLoading(false);
            showErrorBanner("Error: could not save CRM item " + error.message);
            console.log(error);
            return;
        })

        // b) log this event
        const logConfiguration = collection(firestore, 'calibrationlogs');
        var userDisplayName = "Not set";
        if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
            userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        }
        await addDoc(logConfiguration, {
            user: auth.currentUser.uid,
            dts: serverTimestamp(),
            reference: docRef.id,
            success: true,
            action: "Create CRM",
            detail: `Bottle number: ${form.bottlenumber}`,
            username: userDisplayName
        });

        if (docRef !== undefined) {
            console.log("Then block " + docRef.id);
            setFormLoading(false);
            showSuccessDialog();
        }
    }

    // HELPERS

    function stringToDate(dateString) {

        // First check for the pattern
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
            return null;
        }

        // Parse the date parts to integers
        var datePieces = dateString.split("/");
        var day = parseInt(datePieces[0], 10);
        var month = parseInt(datePieces[1], 10);
        var year = parseInt(datePieces[2], 10);

        // Check the ranges of month and year
        if (year < 2020 || year > 2040 || month === 0 || month > 12) {
            return false;
        }

        // days in month then adjust Feb for leap years
        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        // Check the range of the day
        if (day === 0 || day > monthLength[month - 1]) {
            return false;
        }

        let retval = new Date(datePieces[2], (datePieces[1] - 1), datePieces[0], 23, 59, 0);

        if (retval instanceof Date && !isNaN(retval)) {
            return retval;
        }

        return (null);
    }

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations/admin/crm'); // outta here
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    async function lookupLotNumber(setFieldValue, setFieldTouched) {
        if (!setFieldValue || !setFieldTouched) {
            window.alert("Technical glitch");
            return;
        }

        const lotNumber = formLotNumberRef.current.value.trim();
        if (!lotNumber) {
            window.alert("Please enter a lot number");
            return;
        }

        // crmConfiguration is still in scope from above
        const lotNumberQuery = query(crmConfiguration, where('lotnumber', '==', lotNumber), limit(1));
        const lotNumberSnapshot = await getDocs(lotNumberQuery);
        if (lotNumberSnapshot.size === 1) {
            const crmDoc = lotNumberSnapshot.docs[0].data();

            await setFieldValue('reference', crmDoc.reference, true);
            await setFieldTouched('reference', true);
            formRefNumberRef.current.value = crmDoc.reference;

            await setFieldValue('supplier', crmDoc.supplier, true);
            await setFieldTouched('supplier', true);
            formSupplierRef.current.value = crmDoc.supplier;

            await setFieldValue('manufacture', crmDoc.manufacture, true);
            await setFieldTouched('manufacture', true);
            formManufactureRef.current.value = crmDoc.manufacture;

            await setFieldValue('lotvolume', crmDoc.lotvolume, true);
            await setFieldTouched('lotvolume', true);
            formLotVolRef.current.value = crmDoc.lotvolume;

            await setFieldValue('bottlevolume', crmDoc.lotvolume, true);
            await setFieldTouched('bottlevolume', true);
            formBottleVolRef.current.value = crmDoc.lotvolume;

            await setFieldValue('expiry', formatDateDMY(crmDoc.expiry.toDate()), true);
            await setFieldTouched('expiry', true);
            formExpiryRef.current.value = formatDateDMY(crmDoc.expiry.toDate());

            /*
                "validfor": [ "osvxtCwTKuvGAhnBEIQm", "hCIOmIxVE2OKE9UDstCm" ],
                "bottlevolume": "500 ML",
                "lotvolume": "275 Gal",
                "expiry": { "seconds": 1694642340, "nanoseconds": 0 }
            */

            // return { id: deviceDoc.id, ...deviceDoc.data() };
        } else {
            window.alert("Lot number not found");
        }
    }

    // LIFTING STATE

    const handleConfirmChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    const branchChangeHandler = (newValue, setFieldValue, setFieldTouched) => {
        if (setFieldTouched) {
            setFieldTouched('branchId');
        }

        if (setFieldValue) {
            setFieldValue('branchId', newValue.branch.id);
        }
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, isSubmitting, setFieldValue, setFieldTouched }) => (
                            <form onSubmit={handleSubmit} className="">

                                <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <p className="mt-2 max-w-4xl text-sm text-gray-500">Create a new CRM lookup using the information below.</p>
                                    </div>
                                </div>

                                <ConfirmCreateCrm open={successDialogOpen} onConfirmOpenChange={handleConfirmChange} />


                                {/* Calibration details area */}
                                <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Certified Reference Material</h3>
                                        {formLoading && <LoadingBanner />}
                                        {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Assign bottle number (lookup) &nbsp;
                                                    <ErrorMessage name="bottlenumber" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="bottlenumber" id="bottlenumber" onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Brand supplier &nbsp;
                                                    <ErrorMessage name="supplier" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="supplier" id="supplier" onChange={handleChange} onBlur={handleBlur} ref={formSupplierRef} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Reference amount (e.g. 0.05) &nbsp;
                                                    <ErrorMessage name="reference" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="reference" id="reference" onChange={handleChange} onBlur={handleBlur} ref={formRefNumberRef}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                %
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Lot number &nbsp;
                                                    <ErrorMessage name="lotnumber" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                            <input type="text" name="lotnumber" id="lotnumber" ref={formLotNumberRef} onChange={handleChange} onBlur={handleBlur} className="block w-full rounded-none rounded-l-md border-gray-300 pl-3 focus:border-isober-500 focus:ring-isober-500 sm:text-sm" />
                                                        </div>
                                                        <button type="button" onClick={() => lookupLotNumber(setFieldValue, setFieldTouched)} className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-isober-500 focus:outline-none focus:ring-1 focus:ring-isober-500">
                                                            <span>Lookup</span>
                                                        </button>
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Manufacture date &nbsp;
                                                    <ErrorMessage name="manufacture" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="manufacture" id="manufacture" autoComplete="off" onChange={handleChange} onBlur={handleBlur} ref={formManufactureRef} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Expiry date (DD/MM/YYYY) &nbsp;
                                                    <ErrorMessage name="expiry" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="expiry" id="expiry" autoComplete="off" onChange={handleChange} onBlur={handleBlur} ref={formExpiryRef} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Lot volume &nbsp;
                                                    <ErrorMessage name="lotvolume" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="lotvolume" id="lotvolume" autoComplete="off" defaultValue={initialValues.lotvolume} onChange={handleChange} onBlur={handleBlur} ref={formLotVolRef} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Bottle volume &nbsp;
                                                    <ErrorMessage name="bottlevolume" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="bottlevolume" id="bottlevolume" autoComplete="off" defaultValue={initialValues.bottlevolume} onChange={handleChange} onBlur={handleBlur} ref={formBottleVolRef} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    At branch &nbsp;
                                                    <ErrorMessage name="branchId" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <BranchControl className="overflow-y-auto" sfv={setFieldValue} sft={setFieldTouched} handler={branchChangeHandler} />
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>


                                {/* Valid devices area */}
                                <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Valid for the following devices</h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <Field name="validdevices" component={DevicesAsCheckboxes} initialSelection={initialValues.validdevices} />
                                    </div>
                                </div>

                                {/* Button actions */}
                                <div className="mt-4 flex justify-end">
                                    <Link to="/calibrations/admin/crm">
                                        <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                            Cancel
                                        </button>
                                    </Link>
                                    <button type="submit" disabled={isSubmitting || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                        Save CRM
                                    </button>
                                </div>

                            </form>
                        )}
                    </Formik>
                </div >
            </div >
        </main >
    )
}