import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDateShort } from '../../Shared/Components/functions.js';
import { Link } from 'react-router-dom';

export default function CrmBranchTab({ branch }) {
    // FIRESTORE
    const firestore = useFirestore();
    const crmCollection = collection(firestore, `calibrationcrm`);
    const activeQuery = (branch.key !== 'all') ? query(crmCollection, where('branchId', '==', branch.key), where('deleted', '==', false)) : query(crmCollection, where('deleted', '==', false));
    const { data: crmitems } = useFirestoreCollection(activeQuery);

    // HELPERS

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {crmitems.size === 0 && <div className="mt-5 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">No certified reference material is loaded at the selected branch.</p>
                    </div>
                </div>
            </div>
            }

            {crmitems.size > 0 &&
                <table className="mt-6 min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bottle number</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {branch.key !== 'all' && 'Supplier'}
                                {branch.key === 'all' && 'Branch'}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reference reading</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lot number</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manufacture date</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiry date</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {crmitems.docs.map((item, personIdx) => (
                            <tr key={item.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.data().bottlenumber}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {(branch.key !== 'all') && item.data().supplier}
                                    {branch.key === 'all' && item.data().branchId && capitalize(item.data().branchId)}
                                    {branch.key === 'all' && !item.data().branchId && "—"}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.data().reference}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.data().lotnumber}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.data().manufacture}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatDateShort(item.data().expiry.toDate())}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <div className="flex-shrink-0">
                                        <Link to={`/calibrations/admin/crm/view/${item.id}`}>
                                            <button className="mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                View
                                            </button>
                                        </Link>

                                        <Link to={`/calibrations/admin/crm/edit/${item.id}`}>
                                            <button className="mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                Edit
                                            </button>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    )
}