import React, { useEffect, useState } from 'react';
import usePaginatedUsers from './paginatedusers.js';
import { Link } from 'react-router-dom';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';
import { LoadingBanner } from '../Components/Helpers/index.js';

export default function UsersListPage(props) {
    // HOOKS
    const currentLocation = useLocation();

    // STATE
    const [formLoading /*, setFormLoading */] = useState(false);

    // pagination settings
    const resultsPerPage = 25;
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedClientUsers()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    // set up firestore query
    const clientUsers = usePaginatedUsers(resultsPerPage, referenceItem, include);
    const currentLastVisibleItem = (clientUsers !== undefined) ? clientUsers.docs[clientUsers.size - 1] : null;

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, clientUsers.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (clientUsers !== undefined && clientUsers.size % resultsPerPage !== 0) {
            setNextDisabled(true);
        } else if (clientUsers !== undefined) {
            setNextDisabled(false);
        }
    }, [firstItemStack, clientUsers]);

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">

            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The following users are loaded in the Alchohol Breathalysers account.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            <Link to="create">
                                <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create&nbsp;user</button>
                            </Link>
                        </div>
                    </div>

                    {formLoading === true && <LoadingBanner />}

                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email address</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modules access</th>
                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">Edit</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientUsers !== undefined && clientUsers.docs.map((person, personIdx) => (
                                <tr key={person.data().email} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.data().fname} {person.data().lname}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.data().email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">iSober {person.data().role}</span>
                                        {person.data().modules && person.data().modules && person.data().modules.includes("calibrations") && <span className="inline-flex items-center mr-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">Calibrations</span>}
                                        {person.data().modules && person.data().modules && person.data().modules.includes("stock") && <span className="inline-flex items-center mr-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">Stock</span>}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <Link to={`/system/admin/users/user/${person.id}`}><button className="inline-flex items-center px-2.5 py-1 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                            Details
                                        </button></Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

                {clientUsers.docs.length > 0 && /* pagination start */
                    <nav
                        className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + clientUsers.size}</span>
                            </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                            {firstItemStack.length > 1 &&
                                <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                            }

                            <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                            <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                        </div>
                    </nav>
                }
            </div>

        </main>
    )
}