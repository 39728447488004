import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, /*BeakerIcon*/ ClipboardCheckIcon, CloudDownloadIcon, PrinterIcon, ShieldCheckIcon, InformationCircleIcon, ArrowCircleRightIcon, PencilAltIcon } from '@heroicons/react/solid';

export default function CalibrationsActionMenu({ calibrationData, reassignHandler, downloadHandler, caldetailsHandler, printHandler, enqueueHandler, jobcardHandler, renameHandler }) {
    // HELPERS

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button disabled={calibrationData.id === undefined} className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-isober-50 px-3 py-2 text-xs text-isober-1000 shadow-sm ring-inset ring-gray-300 hover:bg-isober-200 focus:ring-isober-500 disabled:text-gray-400 disabled:hover:bg-gray-100 disabled:bg-gray-100">
                    Actions
                    <ChevronDownIcon className="-mr-1 h-4 w-4 text-isober-1000" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {(calibrationData.id === undefined) && <Menu.Item>
                            {({ active }) => (
                                <a href="#2" onClick={(e) => e.stopPropagation()} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                    — No action
                                </a>
                            )}
                        </Menu.Item>
                        }

                        {caldetailsHandler !== undefined && <Menu.Item>
                            {({ active }) => (
                                <a href="#4" onClick={(e) => caldetailsHandler(e, calibrationData)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                    <InformationCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Show cal details
                                </a>
                            )}
                        </Menu.Item>}

                        {printHandler !== undefined && <Menu.Item>
                            {({ active }) => (
                                <a href="#4" onClick={(e) => printHandler(e, calibrationData)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                    <PrinterIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Print label
                                </a>
                            )}
                        </Menu.Item>}

                        {jobcardHandler !== undefined && <Menu.Item>
                            {({ active }) => (
                                <a href="#4" onClick={(e) => jobcardHandler(e, calibrationData)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                    <ClipboardCheckIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    View job card
                                </a>
                            )}
                        </Menu.Item>}

                        {enqueueHandler !== undefined && (calibrationData.certificateGuid === undefined || calibrationData.certificateGuid === false) && (calibrationData.certificatequeued === undefined || calibrationData.certificatequeued === false) && <Menu.Item>
                            {({ active }) => (
                                <a href="#4" onClick={(e) => enqueueHandler(e, calibrationData)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                    <ShieldCheckIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Generate certificate
                                </a>
                            )}
                        </Menu.Item>}

                        {reassignHandler !== undefined && calibrationData.id !== undefined && calibrationData.certificateGuid === undefined && <Menu.Item>
                            {({ active }) => (
                                <a href="#2" onClick={(e) => reassignHandler(e, calibrationData)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                    <ArrowCircleRightIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Reassign client
                                </a>
                            )}
                        </Menu.Item>
                        }

                        {downloadHandler !== undefined && calibrationData.id !== undefined && calibrationData.certificateGuid !== undefined && calibrationData.certificategenerated === true && (calibrationData.certificatequeued === undefined || calibrationData.certificatequeued === false) &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#5" onClick={(e) => downloadHandler(e, calibrationData.certificateGuid)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <CloudDownloadIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Download certificate
                                    </a>
                                )}
                            </Menu.Item>
                        }

                        {renameHandler !== undefined && calibrationData.id !== undefined && calibrationData.certificateGuid !== undefined && calibrationData.certificategenerated === true && (calibrationData.certificatequeued === undefined || calibrationData.certificatequeued === false) &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a href="#5" onClick={(e) => renameHandler(e, calibrationData)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                                        <PencilAltIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Amend certificate
                                    </a>
                                )}
                            </Menu.Item>
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}