import React from 'react';
import { useLocation } from 'react-router-dom';
import useFeedback from './useFeedback.js';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import { formatDate } from '../../Shared/Components/functions.js'

export default function FeedbackPage(props) {
    const currentLocation = useLocation();
    const feedback = useFeedback();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">

                <PageHeaderAlt location={currentLocation.pathname} />

                {/* start table */}

                <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Recent feedback received</h3>
                        {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Subtitle</p>*/}
                    </div>

                    <table className="min-w-full divide-gray-200">
                        <thead className="divide-gray-200 bg-gray-50">
                            <tr className='divide-x divide-gray-200 border-t'>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Received</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">iSober app</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Handset</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Breathalyser</th>
                            </tr>
                        </thead>
                        <tbody className="divide-x divide-gray-200">
                            {feedback.map((item, idx) => (
                                <>
                                    <tr key={`r1_${idx}`} className="divide-x divide-gray-200">
                                        <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                            {formatDate(item.created.toDate())}
                                        </td>
                                        <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                            {item.username && <p>{item.username}</p>}
                                            {item.email && <p>{item.email}</p>}
                                            {!item.email && <p className='text-gray-400'>Email not provided</p>}
                                        </td>
                                        <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                            {item.appversion && <p>iSober {item.appversion}</p>}
                                            {item.region && <p>{item.region}</p>}
                                        </td>
                                        <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                            {item.model && <p>{item.model}</p>}
                                            {item.platform && item.osversion && <p>iSober {item.platform} {item.osversion}</p>}
                                        </td>
                                        <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                            {item.breathalyserModel && <p>{item.breathalyserModel}</p>}
                                            {!item.breathalyserModel && <p className='text-gray-400'>Unknown</p>}
                                            {item.breathalyserSerial && <p>{item.breathalyserSerial}</p>}
                                        </td>
                                    </tr>
                                    <tr key={`r2_${idx}`} className="divide-x divide-gray-200">
                                        <td colspan="5" className="px-6 py-4 text-sm text-gray-700">
                                            {item.comments && item.comments}
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    )
}