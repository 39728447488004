import React, { useState } from 'react';
import { doc } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions.js'
import CreatedBy from './createdby.js';
import { useAuth } from 'reactfire';
import { PencilIcon } from '@heroicons/react/solid'
import { sendPasswordResetEmail } from "firebase/auth";

// to use parameters, the page must be a function not a class
// see: https://github.com/ReactTraining/react-router/issues/7256

export default function UserDetailsFragment() {
    // hooks
    const { userId } = useParams();
    const { clientId } = useParams();
    const auth = useAuth();

    // state
    const [confirmEmailOpen, setConfirmEmailOpen] = useState(false);
    const [confirmEmailSentOpen, setConfirmEmailSentOpen] = useState(false);
    const [emailFormLoading, setEmailFormLoading] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // set up firestore query for the document
    const firestore = useFirestore();
    const collectionPath = `userinfo`;
    const documentRef = doc(firestore, collectionPath, userId);
    const { status, data: user } = useFirestoreDocData(documentRef);

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    function showConfirmEmail() {
        setConfirmEmailOpen(true);
    }

    function hideConfirmEmail() {
        setEmailFormLoading(false);
        setConfirmEmailOpen(false);
    }

    function hideConfirmationSent() {
        setConfirmEmailSentOpen(false);
    }

    function showConfirmationSent() {
        setConfirmEmailOpen(false);
        setConfirmEmailSentOpen(true);
    }

    function showErrorBanner(msg) {
        setLastErrorMessage(msg);
        setErrorBannerOpen(true);
    }

    async function emailConfirmSubmit() {
        setEmailFormLoading(true);

        // validate that we have an email address
        if (user.email === null || user.email === undefined) {
            hideConfirmEmail();
            return;
        }

        sendPasswordResetEmail(auth, user.email)
            .then(() => {
                showConfirmationSent();
            })
            .catch((error) => {
                hideConfirmEmail();
                showErrorBanner(error.message); // also error.code;
            });
    }

    return (
        <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">The details for {user.fname} {user.lname} appear below.</p>
                </div>

                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <Link to={`/system/clients/${clientId}/user/edit/${userId}`}>
                        <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Edit&nbsp;details
                            <PencilIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                        </button>
                    </Link>
                </div>
            </div>

            { /* Confirm send reset email user modal */}
            {confirmEmailOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-isober-1000" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Are you sure you want to send a password reset email?
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button type="button" disabled={emailFormLoading} onClick={emailConfirmSubmit} className="disabled:opacity-50 disabled:bg-isober-1000 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">
                                Send
                            </button>
                            <button type="button" onClick={hideConfirmEmail} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm">
                                Cancel
                            </button>
                            {emailFormLoading &&
                                <div className="w-full inline-flex justify-center px-4 py-2 bg-white sm:w-auto">
                                    <img src="/images/loading.gif" width="25" height="25" alt="" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }

            {/* Decativate modal confirmation */}
            {confirmEmailSentOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/*  This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Heroicon name: outline/check */}
                                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        The password reset email was sent successfully.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button onClick={hideConfirmationSent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            {/* Error banner */}
            {errorBannerOpen && <div className="mt-4 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">{lastErrorMessage}</p>
                    </div>
                </div>
            </div>
            }

            <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">User Details</h3>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">User's name</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {user.fname} {user.lname}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.email}</dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Date created</dt>
                            <dd className="mt-1 text-sm text-gray-900">{formatDate(user.created.toDate())}</dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Created by</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {user.createdby !== undefined && <CreatedBy user={user.createdby} />}
                                {user.createdby === undefined && "–"}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Features</dt>
                            <dd className="mt-1 text-sm text-gray-900">Alerts tab on mobile:
                                {user.alerts === undefined && " No"}
                                {user.alerts !== undefined && user.alerts === true && " Yes"}
                                {user.alerts !== undefined && user.alerts === false && " No"}

                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Roles</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {user.role === "superadmin" && "superadmin, clientadmin"}
                                {user.role === "clientadmin" && "clientadmin"}
                                {user.role === "user" && "user"}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {user.enabled === undefined && "Account is enabled"}
                                {user.enabled !== undefined && user.enabled === true && "Account is enabled"}
                                {user.enabled !== undefined && user.enabled === false && <span className="text-red-700">Account is disabled</span>}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">ID number</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {user.idnumber !== null && user.idnumber}
                                {user.idnumber === undefined && "Not set"}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Password</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <button type="button" onClick={showConfirmEmail} className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Send password reset email
                                </button>
                            </dd>
                        </div>

                        {user.extra !== undefined &&
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Other information</dt>
                                <dd className="mt-1 text-sm text-gray-900">{user.extra}</dd>
                            </div>
                        }

                    </dl>
                </div>
            </div>
        </div>
    )
}