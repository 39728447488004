export default function ReadingChip(props) {
    const greenStyleFg = 'text-green-600';
    const amberStyleFg = 'text-yellow-600';
    const redStyleFg = 'text-red-700';

    const greenStyleBg = 'bg-green-100';
    const amberStyleBg = 'bg-yellow-100';
    const redStyleBg = 'bg-red-100';

    // defaults for zero readings
    var valueFormatted = (props.unit !== "mg/100mL") ? props.value.toFixed(3) : props.value;
    var thisStyleFg = greenStyleFg;
    var thisStyleBg = greenStyleBg;

    // if not a zero reading then set new colours
    if (props.value !== 0) {
        switch (props.unit) {
            case "% BAC":
                thisStyleFg = (props.value !== "0.05") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "0.05") ? redStyleBg : amberStyleBg;
                break;
            case "g/L":
                thisStyleFg = (props.value !== "0.5") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "0.5") ? redStyleBg : amberStyleBg;
                break;
            case "‰":
                thisStyleFg = (props.value !== "0.5") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "0.5") ? redStyleBg : amberStyleBg;
                break;
            case "mg/L":
                thisStyleFg = (props.value !== "0.24") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "0.24") ? redStyleBg : amberStyleBg;
                valueFormatted = props.value.toFixed(3);
                break;
            case "μg/L":
                thisStyleFg = (props.value !== "0.240") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "0.240") ? redStyleBg : amberStyleBg;
                break;
            case "μg/100mL":
                thisStyleFg = (props.value !== "0.24") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "0.24") ? redStyleBg : amberStyleBg;
                break;
            case "g/210L":
                thisStyleFg = (props.value !== "0.048") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "0.048") ? redStyleBg : amberStyleBg;
                break;
            case "mg/100mL":
                thisStyleFg = (props.value !== "48") ? redStyleFg : amberStyleFg;
                thisStyleBg = (props.value !== "48") ? redStyleBg : amberStyleBg;
                break;
            default:
                thisStyleFg = amberStyleFg;
                thisStyleBg = amberStyleBg;
        }
    }

    return (
        <div className="flex overflow-hidden -space-x-1 mr-3">
            <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${thisStyleFg} ${thisStyleBg}`}>{valueFormatted} {props.unit}</span>
        </div>
    )
}