export function EmptyStateReadings() {
    return (
        <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <div className="text-center">
                <p className="text-lg leading-6 font-medium text-gray-900">No measurements</p>
                <p className="mt-2 text-sm text-gray-500">
                    No measurements have been recorded yet; they will appear here as they are taken.
                </p>
            </div>
        </div>
    )
}

export function EmptyStateAlerts() {
    return (
        <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <div className="text-center">
                <p className="text-lg leading-6 font-medium text-gray-900">No alerts</p>
                <p className="mt-2 text-sm text-gray-500">
                    The user has not triggered any alerts; measurement alerts will appear here as they are triggered.
                </p>
            </div>
        </div>
    )
}