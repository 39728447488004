import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// props: .claims
export default function SystemChooser(props) {
    var allowedSystems = [];

    // always have breathalysers for now
    allowedSystems.push({ title: "iSober Cloud", href: "/app", active: false });

    if (props.claims && props.claims.superadmin && props.claims.superadmin === true) {
        allowedSystems.push({ title: "System Admin", href: "/system", active: false });
    }

    if (props.claims && props.claims.stock && props.claims.stock === true) {
        allowedSystems.push({ title: "Stock Control", href: "/stock", active: true });
    }

    if (props.claims && props.claims.calibrations && props.claims.calibrations === true) {
        allowedSystems.push({ title: "Calibrations Workflow", href: "/calibrations", active: false });
    }

    // only show if more than one to choose from
    if (allowedSystems.length < 2) {
        return <span />
    }

    return (
        <Menu as="div" className="px-3 mt-6 relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none">
                            <span className="flex w-full justify-between items-center">
                                <span className="flex min-w-0 items-center justify-between space-x-3">
                                    <span className="flex-1 flex flex-col min-w-0">
                                        <span className="text-gray-900 text-sm font-medium truncate">Active module</span>
                                        <span className="text-gray-500 text-sm truncate">Stock Control</span>
                                    </span>
                                </span>
                                <SelectorIcon className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            </span>
                        </Menu.Button>
                    </div>
                    <Transition show={open} as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">

                        <Menu.Items static className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                            <div className="py-1">
                                {allowedSystems.map((system) => (
                                    <Menu.Item key={system.title}>
                                        <Link to={system.href} className={classNames(
                                            system.active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}>
                                            {system.title}
                                        </Link>
                                    </Menu.Item>
                                ))
                                }
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}



