import { doc } from 'firebase/firestore';
import { useFirestoreDocDataOnce, useFirestore } from 'reactfire';

// hook to get clientid, fname, lname from firestore client record
// via: import useClientInfo from './Components/ClientInfo';
//      const wakaX = useClientInfo(clientid);

export default function useClientInfo(clientGuid) {
    console.log("[useClientInfo] for " + clientGuid);

    // a parent component contains a `FirebaseAppProvider`
    const clientInfoDocRef = doc(useFirestore(), 'clients', clientGuid);
    const { status, data: clientinfo } = useFirestoreDocDataOnce(clientInfoDocRef);

    if (status === 'loading') {
        return undefined;
    }
    
    return clientinfo;
}