import { collection, query, where, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedLogs(userGuid, startItem, include, rpp) {
    const firestore = useFirestore();
    const logsCollection = collection(firestore, 'calibrationlogs');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = (userGuid === undefined) ? query(logsCollection, orderBy('dts', 'desc'), limit(rpp)) : query(logsCollection, where('user', '==', userGuid), orderBy('dts', 'desc'), limit(rpp));
    } else {
        if (userGuid === undefined) {
            activeQuery = (include) ? query(logsCollection, orderBy('dts', 'desc'), limit(rpp), startAt(startItem)) : query(logsCollection, orderBy('dts', 'desc'), limit(rpp), startAfter(startItem));
        } else {
            activeQuery = (include) ? query(logsCollection, where('user', '==', userGuid), orderBy('dts', 'desc'), limit(rpp), startAt(startItem)) : query(logsCollection, where('user', '==', userGuid), orderBy('dts', 'desc'), limit(rpp), startAfter(startItem));
        }
    }

    const { status, data: logevents } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return logevents;
}
