import React from 'react';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';
import LogsList from './loglist.js';

function LogsPage(props) {
    const userinfoData = props.userinfo;
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        <LogsList userinfo={userinfoData} />
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}

export default LogsPage;
