import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useFirestore } from 'reactfire';
import { collection, addDoc, serverTimestamp, updateDoc, doc } from 'firebase/firestore';
import { CategoryDropdown } from './CategoryDropdown';
import { Formik } from 'formik';
import * as Yup from "yup"

/*
    handler: functipon called on close
*/
export default function EditSkuModal({ handler, idToken, mode = 'create', sku = undefined }) {
    // STATE
    const [open, setOpen] = useState(true)
    const [formLoading, setFormLoading] = useState(false);

    // HOOKS
    const cancelButtonRef = useRef(null)
    const firestore = useFirestore();

    // FORMIK

    const mayEditSku = (mode === 'create' || (idToken && idToken.claims && idToken.claims.superadmin !== undefined && idToken.claims.superadmin === true)) ? true : false;
    const initialValues = { sku: sku?.sku, warning: sku?.warning ? sku?.warning : 0, category: sku?.category };
    const validationSchema = Yup.object({
        sku: Yup.string().required(),
        warning: Yup.number("Enter warning level").required("Required").min(0).max(250000).typeError('A number is required').transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        category: Yup.string().oneOf([
            "breathalyser",
            "accessory",
            "drug tester",
            "hygiene product",
            "crm",
            "parts",
        ])
    });

    // HELPERS

    const cancelPressed = () => {
        setOpen(false);
        handler();
    }

    const closeDialog = () => {
        setOpen(false);
        handler();
    }

    // HANDLERS

    const handleSubmit = async (form) => {
        setFormLoading(true);

        if (mode === 'create') {
            const stockConfiguration = collection(firestore, 'stock');
            await addDoc(stockConfiguration, {
                created: serverTimestamp(),
                enabled: true,
                sku: form.sku,
                category: form.category,
                warning: Number(form.warning),
            }).catch((error) => {
                setFormLoading(false);
                window.alert("Error: could not save SKU " + error.message);
                console.log(error);
            })
        } else if (mode === 'edit') {
            if (!sku.sku) {
                window.alert("Error: could not save SKU, sku not set");
                closeDialog();
                return;
            }

            // SKU: {"sku":"iSober S Pro","category":"breathalyser","balance":{"jhb":3,"ct":4},"id":"NRxXOmdP4oAM55lbANd4"}
            // FORM: {"sku":"AL1100F","warning":"31","category":"breathalyser"}
            const docReference = doc(firestore, "stock", sku.id);

            // fields to update
            var docFields = {
                category: form.category,
                warning: form.warning,
            };

            // only try to set this field if permission allows
            if (mayEditSku) {
                docFields.sku = form.sku;
            }

            await updateDoc(docReference, docFields)
                .catch((error) => {
                    window.alert("Error uploading " + error);
                });
        }

        // outta here
        setFormLoading(false);
        closeDialog();
    }

    const categoryChangedHandler = (newValue, setFieldValue, setFieldTouched) => {
        if (setFieldValue) {
            setFieldValue('category', newValue.category.name);
        }

        if (setFieldTouched) {
            setFieldTouched('category');
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => { }}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-visible">

                                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount={true} >
                                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty, errors, setFieldValue, setFieldTouched, values }) => (
                                        <form onSubmit={handleSubmit} className="">
                                            <div>
                                                <div className="text-center">
                                                    <Dialog.Title as="h3" className="justify-left text-left text-base font-semibold leading-6 text-gray-900">
                                                        {mode === 'create' && "Add a new SKU"}
                                                        {mode === 'edit' && "Edit an existing SKU"}
                                                    </Dialog.Title>

                                                    {/*<p className='text-left mt-2'>Errors: {JSON.stringify(errors)}</p>
                                                    <p className='text-left mt-2'>Values: {JSON.stringify(values)}</p>
                                                    <p className='text-left mt-2'>Category: {JSON.stringify(values.category)}</p>
                                                    <p className='text-left mt-2'>Dirty: {JSON.stringify(dirty)}</p>
                                                    <p className='text-left mt-2'>Valid: {JSON.stringify(isValid)}</p>*/}

                                                    {/* Could allow editing SKU name but disabling for now */}
                                                    <div className="relative mt-5">
                                                        <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">SKU Name</label>
                                                        <input type="text" name="sku" id="sku" defaultValue={initialValues.sku} disabled={!mayEditSku} onChange={handleChange} onBlur={handleBlur} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-isober-600 sm:text-sm sm:leading-6" />
                                                    </div>

                                                    <div className="relative mt-5">
                                                        <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Warning Level</label>
                                                        <input type="text" name="warning" id="warning" onChange={handleChange} onBlur={handleBlur} defaultValue={initialValues.warning} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-isober-600 sm:text-sm sm:leading-6" />
                                                    </div>

                                                    <CategoryDropdown name="category" initialValue={sku?.category} handler={categoryChangedHandler} sfv={setFieldValue} sft={setFieldTouched} />
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <button type="submit" disabled={values.category === undefined || formLoading || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 inline-flex w-full justify-center rounded-md bg-isober-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-isober-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-isober-800 sm:col-start-2">
                                                    Save
                                                </button>
                                                <button type="button" onClick={() => cancelPressed()} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}