import React, { Component } from "react";
import Downshift from "downshift";
import debounce from 'lodash.debounce';
import AddCustomerInline from './customeraddinline.js';

// https://codesandbox.io/examples/package/downshift
// https://codesandbox.io/s/1rj327v2jj

// state is passed up via props.handler
export default class CustomerAutocomplete extends Component {
    constructor(props) {
        super(props);

        const sc = this.props.showCreateOption === true ? true : false;
        this.state = { customers: [], loading: false, showCreateOption: sc, showCreateInterface: false, currentSearchTerm: undefined };
        this.fetchCustomers = this.fetchCustomers.bind(this);
        this.handleAddCustomerChange = this.handleAddCustomerChange.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.handlerFunc = this.handlerFunc.bind(this);

        this.emitChangeDebounced = debounce(this.inputOnChange, 300);
    }

    handlerFunc(p1) {
        this.props.handler(p1, this.props.sfv, this.props.sft)
    }

    inputOnChange(event) {
        if (!event.target.value) {
            return;
        }
        this.setState({ currentSearchTerm: event.target.value });
        this.fetchCustomers(event.target.value);
    }

    downshiftOnChange(selectedCustomer) {
        console.log(`[CustomerAutocomplete] selected customer ${selectedCustomer.id} / ${selectedCustomer.name}`);

        if (selectedCustomer.id === '0') {
            console.log("[CustomerAutocomplete] show create client interface");
            this.setState({ showCreateInterface: true});
        } else {
            // lift state upwards
            console.log(`[CustomerAutocomplete] lift selected customer upwards ${selectedCustomer.id}`);
            this.handlerFunc(selectedCustomer);
        }
    }

    async fetchCustomers(searchTerm) {
        if (searchTerm === undefined || searchTerm === null || searchTerm.trim() === '') {
            return
        }

        this.setState({ loading: true });
        const body = { params: `query=${searchTerm}&hitsPerPage=9` };

        fetch('https://3SEANAS33X-dsn.algolia.net/1/indexes/clientNameIndex/query', {
            method: 'POST',
            headers: {
                "X-Algolia-API-Key": "8ff6c7ff57ebf9447a56d076888484dd",
                "X-Algolia-Application-Id": "3SEANAS33X",
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ loading: false });
                if (data.error) {
                    window.alert("Error: " + data.message);
                } else {
                    var re = [];
                    if (data.hits) {
                        data.hits.forEach(hit => {
                            re.push({ id: hit.objectID, name: hit.name });
                        });
                    }

                    if (this.state.showCreateOption === true) {
                        re.push({ id: '0', name: 'Create new customer record' })
                    }

                    this.setState({ customers: re });
                }
            });
    }

    // LIFTING STATE FROM customeraddinline

    handleAddCustomerChange(selectedCustomer) {
        console.log("[CustomerAutocomplete] handleAddCustomerChange() " + JSON.stringify(selectedCustomer));
        this.setState({ showCreateInterface: false});
        this.handlerFunc(selectedCustomer);
    }

    render() {
        return (
            <>
                <AddCustomerInline open={this.state.showCreateInterface} onChange={this.handleAddCustomerChange} userinfo={this.props.userinfo} autoc={this.state.currentSearchTerm} />
                <Downshift onChange={this.downshiftOnChange.bind(this)} itemToString={item => (item ? item.name : "")} >
                    {({
                        selectedItem,
                        getInputProps,
                        getItemProps,
                        highlightedIndex,
                        isOpen,
                        // inputValue
                    }) => (
                        <div className="relative">
                            <div className='absolute top-3 right-2'>
                                {this.state.loading && <img src='/images/loadingtiny.gif' width='16' height='16' alt='' />}
                            </div>
                            <input className="w-full p-2 text-sm focus:outline-none focus:shadow-outline rounded border border-gray-300 focus:ring-1 focus:ring-isober-500 focus:border-isober-500"
                                {...getInputProps({
                                    placeholder: "Start typing...",
                                    onChange: this.emitChangeDebounced
                                })}
                            />
                            {isOpen ? (
                                <div className="border border-gray-300 bg-white shadow-md">
                                    {this.state.customers
                                        .slice(0, 10)
                                        .map((item, index) => (
                                            <div
                                                className="dropdown-item"
                                                {...getItemProps({ key: index, index, item })}
                                                style={{
                                                    backgroundColor: highlightedIndex === index ? "lightgray" : "white",
                                                    fontWeight: selectedItem === item ? "bold" : "normal"
                                                }}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                </div>
                            ) : null}
                        </div>

                    )}
                </Downshift>
            </>
        );
    }
}