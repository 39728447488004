import React from 'react';
import DashboardGrid from './DashboardGrid';
import DashboardTable from './DashboardTable';

export default function DashboardTab({ category='breathalyser', viewMode }) {

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                <div className="">
                    {viewMode === 0 && <DashboardTable category={category} />}
                    {viewMode === 1 && <DashboardGrid category={category} />}
                </div>
                {/* /End replace */}
            </div>
        </main >
    )
}