import useUserCounts from "./useUserCounts";
import { formatDate } from '../../Shared/Components/functions.js';

export default function UsersCount(props) {
    const userCounts = useUserCounts();
    const totalUsers = (userCounts !== undefined) ? userCounts.counts["apple.com"] + userCounts.counts["google.com"] + userCounts.counts["facebook.com"] : 0;

    function formatDecimal(n) {
        return n.toLocaleString();
    }

    return (
        <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Total number of social users:</h3>

            <dl className="mt-2 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
                <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                        <div className="absolute bg-gray-100 rounded-md p-2">
                            <svg viewBox="0 0 28 24" width="25" height="25" xmlns="http://www.w3.org/2000/svg">
                                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                                    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
                                    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
                                    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
                                    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
                                </g>
                            </svg>
                        </div>
                        <p className="ml-16 text-sm font-medium text-gray-500 truncate">Google</p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                            {formatDecimal(userCounts.counts["google.com"])}
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-isober-900">
                            {parseFloat((userCounts.counts["google.com"]/totalUsers)*100).toFixed(1)}%
                        </p>
                    </dd>
                </div>

                <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                        <div className="absolute bg-gray-100 rounded-md p-2">
                            <svg height="25" viewBox="126.445 2.281 589 589" width="25" xmlns="http://www.w3.org/2000/svg"><circle cx="420.945" cy="296.781" fill="#3c5a9a" r="294.5" /><path d="m516.704 92.677h-65.239c-38.715 0-81.777 16.283-81.777 72.402.189 19.554 0 38.281 0 59.357h-44.788v71.271h46.174v205.177h84.847v-206.531h56.002l5.067-70.117h-62.531s.14-31.191 0-40.249c0-22.177 23.076-20.907 24.464-20.907 10.981 0 32.332.032 37.813 0v-70.403z" fill="#fff" /></svg>
                        </div>
                        <p className="ml-16 text-sm font-medium text-gray-500 truncate">Facebook</p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                            {formatDecimal(userCounts.counts["facebook.com"])}
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-isober-900">
                        {parseFloat((userCounts.counts["facebook.com"]/totalUsers)*100).toFixed(1)}%
                        </p>
                    </dd>
                </div>

                <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                        <div className="absolute bg-gray-100 rounded-md p-2">
                            <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
                                <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="apple" transform="translate(2.010804, 0.000000)" fill="#000000" fillRule="nonzero">
                                        <path d="M17.5390156,13.1962785 C17.5270108,11.1494598 18.4543818,9.60684274 20.3271309,8.46938776 C19.2797119,6.96878752 17.695078,6.14345738 15.6062425,5.98439376 C13.6284514,5.82833133 11.4645858,7.13685474 10.6722689,7.13685474 C9.83493397,7.13685474 7.92016807,6.03841537 6.41356543,6.03841537 C3.30432173,6.08643457 0,8.51740696 0,13.4633854 C0,14.92497 0.267106843,16.4345738 0.801320528,17.9891957 C1.51560624,20.0360144 4.09063625,25.0510204 6.77671068,24.969988 C8.18127251,24.9369748 9.17466987,23.9735894 11.002401,23.9735894 C12.7761104,23.9735894 13.6944778,24.969988 15.2611044,24.969988 C17.9711885,24.9309724 20.30012,20.3721489 20.9783914,18.3193277 C17.3439376,16.6056423 17.5390156,13.3013205 17.5390156,13.1962785 Z M14.3847539,4.04261705 C15.9063625,2.23589436 15.7683073,0.591236495 15.7232893,1.70598496e-15 C14.3787515,0.0780312125 12.8241297,0.915366146 11.9387755,1.94477791 C10.9633854,3.04921969 10.3901561,4.41476591 10.5132053,5.95438175 C11.9657863,6.06542617 13.2923169,5.31812725 14.3847539,4.04261705 L14.3847539,4.04261705 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <p className="ml-16 text-sm font-medium text-gray-500 truncate">Apple</p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                            {formatDecimal(userCounts.counts["apple.com"])}
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-isober-900">
                        {parseFloat((userCounts.counts["apple.com"]/totalUsers)*100).toFixed(1)}%
                        </p>
                    </dd>
                </div>
            </dl>
            <p className="text-sm mt-2">Last updated: {formatDate(userCounts.lastupdate.toDate())}</p>
        </div>
    );
}