import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useFirestore } from 'reactfire';
import { doc, runTransaction } from 'firebase/firestore';
import { BranchControl } from './BranchControl'
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"

/*
    item: the item passed by the caller
    skuGuid: Firebase ID of the SKU being updated
    branchId: internal id of the branch (optional)
*/
export default function UpdateBalanceModal({ item, skuGuid, branchId, handler }) {
    // STATE
    const [open, setOpen] = useState(true)
    const [formLoading, setFormLoading] = useState(false);

    // HOOKS
    const cancelButtonRef = useRef(null)
    const firestore = useFirestore();

    // FORMIK

    const initialValues = { branchId: undefined, quantity: 0, quantityDemo: 0 };
    const validationSchema = Yup.object({
        quantity: Yup.number("Enter quantity on hand").required("Required").min(0).max(250000).typeError('A number is required').transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        quantityDemo: Yup.number("Enter demo units on hand").required("Required").min(0).max(250000).typeError('A number is required').transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        branchId: Yup.string().oneOf([
            "jhb",
            "ct",
            "dbn",
            "bw",
            "cs"])
    });

    // HELPERS

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const cancelPressed = () => {
        setOpen(false);
        handler();
    }

    // HANDLERS

    const branchChangedHandler = (newValue, setFieldValue, setFieldTouched) => {
        if (setFieldValue) {
            setFieldValue('branchId', newValue.branch.id);
        } else {
            window.alert("Err1");
        }

        if (setFieldTouched) {
            setFieldTouched('branchId');
        } else {
            window.alert("Err2");
        }
    }

    const handleSubmit = async (form) => {
        // validate item ID from calling function
        if (!item || !item.id) {
            window.alert("Cannot save, item ID is not set.");
            return;
        }

        // validate count
        if (form.quantity === undefined || /\D/.test(form.quantity)) {
            window.alert("Cannot save, item count is invalid.");
            return;
        }

        // validate branch
        if (!form.branchId || !['jhb', 'ct', 'dbn', 'bw', 'cs'].includes(form.branchId)) {
            window.alert("Cannot save, branch is invalid.");
            return;
        }

        setFormLoading(true);

        // save to firebase        
        try {
            const documentRef = doc(firestore, 'stock', item.id);
            await runTransaction(firestore, async (t) => {
                const doc = await t.get(documentRef);
                
                var newBalance = doc.data().balance ? doc.data().balance : {}; // need a default empty value or firestore will crash
                newBalance[form.branchId] = Number(form.quantity);
                var newDemoBalance = doc.data().demobalance ? doc.data().demobalance : {}; // need a default empty value or firestore will crash
                newDemoBalance[form.branchId] = Number(form.quantityDemo);
                
                t.update(documentRef, { balance: newBalance, demobalance: newDemoBalance });
            });

            console.log('Transaction successful');
        } catch (e) {
            window.alert("Cannot save, error (" + e + ")");
            console.log('Transaction failure:', e);
        }

        setFormLoading(false);

        setOpen(false);
        handler();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => { }}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-visible">

                                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount={true} >
                                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty, errors, setFieldValue, setFieldTouched, values }) => (
                                        <form onSubmit={handleSubmit} className="">
                                            <div>
                                                <div className="text-center">
                                                    <Dialog.Title as="h3" className="justify-left text-left text-base font-semibold leading-6 text-gray-900">
                                                        Update stock on hand
                                                    </Dialog.Title>

                                                    <BranchControl name="branchId" handler={branchChangedHandler} sfv={setFieldValue} sft={setFieldTouched} />

                                                    <div className="mt-2 justify-left text-left">
                                                        <div className="relative mt-2 rounded-md shadow-sm">
                                                            Regular stock on hand:
                                                            <input type="text" name="quantity" id="quantity" onChange={handleChange} onBlur={handleBlur} defaultValue={initialValues.quantity} className={classNames(
                                                                errors.quantity ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-300 focus:ring-gray-500',
                                                                "block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                                            )} />

                                                            {errors.quantity && <div className="pointer-events-none absolute mt-6 inset-y-0 right-0 flex items-center pr-3">
                                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                            </div>}
                                                        </div>

                                                        <ErrorMessage name="quantity" component="div" className="mt-2 text-sm text-red-600" />
                                                    </div>

                                                    <div className="mt-2 justify-left text-left">
                                                        <div className="relative mt-2 rounded-md shadow-sm">
                                                            Demo units on hand:
                                                            <input type="text" name="quantityDemo" id="quantityDemo" onChange={handleChange} onBlur={handleBlur} defaultValue={initialValues.quantityDemo} className={classNames(
                                                                errors.quantityDemo ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-300 focus:ring-gray-500',
                                                                "block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                                            )} />

                                                            {errors.quantityDemo && <div className="pointer-events-none absolute mt-6 inset-y-0 right-0 flex items-center pr-3">
                                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                            </div>}
                                                        </div>

                                                        <ErrorMessage name="quantityDemo" component="div" className="mt-2 text-sm text-red-600" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <button type="submit" disabled={values.branchId === undefined || formLoading || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 inline-flex w-full justify-center rounded-md bg-isober-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-isober-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-isober-800 sm:col-start-2">
                                                    Update
                                                </button>
                                                <button type="button" onClick={() => cancelPressed()} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}