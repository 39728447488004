import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ActionsMenuControl(props) {
    return (
        <Menu as="div" className="mr-2 relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-isober-500">
                    Actions
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="w-full py-1">
                        {/* <Menu.Item>
                            {({ active }) => (
                                <button className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'text-left w-full block px-4 py-2 text-sm' )} >
                                    Attach item (WIP)...
                                </button>
                            )}
                        </Menu.Item> */}
                        {/* <Menu.Item>
                            {({ active }) => (
                                <button onClick={props.item4Handler} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'text-left w-full block px-4 py-2 text-sm' )} >
                                    Repair unit (WIP)...
                                </button>
                            )}
                        </Menu.Item> */}

                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={props.item3Handler} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'text-left w-full block px-4 py-2 text-sm')} >
                                    Scrap unit...
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={props.item1Handler} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'text-left w-full block px-4 py-2 text-sm')}>
                                    Add note to job card...
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={props.backdateHandler} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'text-left w-full block px-4 py-2 text-sm')}>
                                    Backdate calibration...
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}