import React, { useState } from 'react';
import { useFirestore } from 'reactfire';
import { doc, updateDoc, Bytes } from 'firebase/firestore';

//  See https://stackoverflow.com/questions/61985832/how-to-load-image-and-convert-to-blob-in-react
//      https://stackoverflow.com/questions/32556664/getting-byte-array-through-input-type-file

// props.userId
// props.closeHandler

async function getAsByteArray(file) {
    return new Uint8Array(await readFile(file))
}

function readFile(file) {
    return new Promise((resolve, reject) => {
        // Create file reader
        let reader = new FileReader()

        // Register event listeners
        reader.addEventListener("loadend", e => resolve(e.target.result))
        reader.addEventListener("error", reject)

        // Read file
        reader.readAsArrayBuffer(file)
    })
}

export default function SignatureUpload(props) {
    const firestore = useFirestore();

    // STATE
    const [file, setFile] = useState();

    const closeDialog = () => {
        props.closeHandler();
    };

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadAction = async () => {
        if (!file) {
            return;
        }

        const blob = await getAsByteArray(file);
        const bytes = Bytes.fromUint8Array(blob);

        // Send to firebase
        const userinfoRecordRef = doc(firestore, "userinfo", props.userId);
        await updateDoc(userinfoRecordRef, { signature: bytes })
        .catch((error) => {
            window.alert("Error uploading " + error);
        })
        .then(() => {
            closeDialog();
        });
    }

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                {/*  This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                    <div>
                        <div className="mt-0 text-center sm:mt-0">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Upload signature</h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    <b>Specifications:</b> the image's aspect ratio must be 5:3 in order to fit within the allotted space on the document template. 500 px x 300 px is ideal.
                                </p>
                            </div>
                            <div className="my-5">
                                <input type="file" onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={() => handleUploadAction()} className="disabled:opacity-50 disabled:bg-isober-1000 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">
                            Upload
                        </button>
                        <button type="button" onClick={() => closeDialog()} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                        {/*emailFormLoading &&
                            <div className="w-full inline-flex justify-center px-4 py-2 bg-white sm:w-auto">
                                <img src="/images/loading.gif" width="25" height="25" alt="" />
                            </div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}