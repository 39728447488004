import React, { useState } from 'react';
import { ErrorBanner } from '../Components/Helpers/index.js';
import { collection, query, where, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import FileSaver from "file-saver";

export default function CerticatesByDeviceSerial(props) {
    // FIRESTORE
    const firestore = useFirestore();
    const calibrationCollection = collection(firestore, `certificates`);
    const activeQuery = query(calibrationCollection, where('serialIdx', 'array-contains', props.query), orderBy('created', 'desc'));
    const { status, data: certificates } = useFirestoreCollection(activeQuery);

    // STATE
    const [isDownloading, setIsDownloading] = useState(false);

    if (status === 'loading') {
        return 'Loading...';
    }

    // HELPERS

    const trim = (str, ch) => {
        var start = 0, 
            end = str.length;
    
        while(start < end && str[start] === ch)
            ++start;
    
        while(end > start && str[end - 1] === ch)
            --end;
    
        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    const friendlyCompanyForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Company";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";
    
        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;
    
            if (alphaNumeric.test(str[i])) {
                retval += str[i];    
            } else if (retval.slice(-1) !== "_") {
                retval += "_";
            }
        }
    
        // remove leading/trailing underscores
        retval = trim(retval, "_");
    
        // return something if end up with nothing
        if (!retval) {
            return "Company";
        }
    
        return retval;
    }

    const friendlySerialForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Number";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";
    
        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;
    
            if (alphaNumeric.test(str[i])) {
                retval += str[i];    
            } else if (retval.slice(-1) !== "-") {
                retval += "-";
            }
        }
    
        // remove leading/trailing dashes
        retval = trim(retval, "-");
    
        // return something if end up with nothing
        if (!retval) {
            return "Number";
        }
    
        return retval;
    }

    const servePdf = (certificate) => {
        // compose filename overloaded with fields from the record:
        var friendlyCompanyName = friendlyCompanyForFilename(certificate.data().certificateCustomerName);
        var friendlyCertificateNo = friendlySerialForFilename(certificate.data().serial);
        
        var friendlySerialNo = friendlySerialForFilename(certificate.data().serialIdx.length > 1 ? `${certificate.data().serialIdx[0]}_${certificate.data().serialIdx[1]}` : certificate.data().serialIdx[0]);
        // var friendlySerialNo = friendlySerialForFilename(certificate.data().serialIdx[0]);
        
        var friendlyFileName = `Certificate_${friendlyCompanyName}_${friendlySerialNo}_${friendlyCertificateNo}.pdf`;

        setIsDownloading(true);
        const storage = getStorage();
        getDownloadURL(ref(storage, `certificates/${certificate.id}.pdf`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    setIsDownloading(false);
                    FileSaver.saveAs(blob, friendlyFileName);
                };
                xhr.onerror = (error) => {
                    setIsDownloading(false);
                    window.alert("Error: could not download file");
                }
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                setIsDownloading(false);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        window.alert("Error: object not found");
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        window.alert("Error: unauthorized");
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        window.alert("Error: request cancelled");
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        window.alert("Unknown error occurred");
                        break;
                    default:
                        window.alert("Unknown error occurred");
                        break;
                }
            });
    }

    return (
        <div>
            {certificates.size > 0 && <div>
                <div className="mt-6 font-bold text-gray-900">The following certificates were found for <span className='italic'>{props.query}</span> :</div>

                <table className="mt-6 min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr key='headerrow'>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Number</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Issued to</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serial number</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {certificates.docs.map((certificateItem, itemIdx) => (
                            <React.Fragment key={`F_${itemIdx}`}>
                                <tr key={`A_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                    <td className="px-6 pt-4 whitespace-nowrap text-sm text-gray-900">{formatDate(certificateItem.data().created.toDate())}</td>
                                    <td className="px-6 pt-4 whitespace-nowrap text-sm text-gray-900">
                                        {certificateItem.data().serial} &nbsp;
                                        {certificateItem.data().reissue !== undefined && certificateItem.data().reissue === true && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800"> Reissue </span>}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {certificateItem.data().certificateCustomerName} 
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{certificateItem.data().modelName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{certificateItem.data().serialIdx[0]}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={() => servePdf(certificateItem)} disabled={isDownloading} className="disabled:opacity-50 ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                Download
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>}

            {certificates.size === 0 && <ErrorBanner message='No certificates match your certificate number search.' />}
        </div>
    )
}