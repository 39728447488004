import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../Shared/Components/functions';
import { usePaginatedCustomerJobcards } from './usePaginatedCustomerJobcards';
import useCustomer from '../Components/Hooks/usecustomer';

export default function TabCustomerJobCards({ clientId }) {
    // STATE
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedJobcards()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const resultsPerPage = 20;

    // HOOKS
    const customerRecord = useCustomer(clientId);
    const jobcards = usePaginatedCustomerJobcards(referenceItem, include, clientId, resultsPerPage);
    const currentLastVisibleItem = (jobcards !== undefined) ? jobcards.docs[jobcards.size - 1] : null;

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, jobcards.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (jobcards !== undefined && jobcards.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (jobcards !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, jobcards]);

    return (
        <div>
            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Customer Calibrations</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Below are the calibrations for the customer <span className='font-bold'>{customerRecord.name}</span>:</p>
                </div>
            </div>

            {jobcards.size === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No job cards</p>
                    <p className="mt-2 text-sm text-gray-500">
                        No job cards found for the selected branch; they will appear here as they are recorded.
                    </p>
                </div>
            </div>}

            {jobcards.size > 0 && <table className="mt-6 min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr key='hdr'>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Branch</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Devices</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {jobcards.docs.map((jobcard, itemIdx) => (
                        <tr key={`A_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {jobcard.data().branch === 1 && 'Johannesburg'}
                                {jobcard.data().branch === 2 && 'Cape Town'}
                                {jobcard.data().branch === 3 && 'Durban'}
                                {jobcard.data().branch === 4 && 'Client site'}
                                {jobcard.data().branch === 5 && 'Botswana'}
                            </td>
                            <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">{formatDate(jobcard.data().created.toDate())}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{jobcard.data().clientName}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {jobcard.data().numDevices !== undefined && jobcard.data().numDevices}
                                {jobcard.data().numDevices === undefined && jobcard.data().deviceIdx !== undefined && jobcard.data().deviceIdx.length}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {jobcard.data().status === 'Open' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Open </p>}
                                {jobcard.data().status === 'Complete' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Job complete </p>}
                                {jobcard.data().status === 'Quoting' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-pink-100 text-pink-800"> Awaiting quote </p>}
                                {jobcard.data().status === 'Dispatched' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Dispatched </p>}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-900">
                                <Link to={`/calibrations/jobcard/${jobcard.id}`}>
                                    <button className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Details
                                    </button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}

            {/* pagination area */}
            {jobcards.docs.length > 0 &&
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{firstItemStack.length === 0 ? jobcards.docs.length : (firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }
        </div>
    )
}