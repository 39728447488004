import { collection, query, where, limit } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export default function useCalibrationHistory(unitSerial, sensorSerial) {    

    // set up search term
    var searchTerms = [];
    if (unitSerial) {
        searchTerms.push(unitSerial);
        searchTerms.push(unitSerial.toUpperCase());
    }
    if (sensorSerial) {
        searchTerms.push(sensorSerial);
        searchTerms.push(sensorSerial.toUpperCase());
    }

    // go
    const firestore = useFirestore();
    const calibrationCollection = collection(firestore, `calibrations`);
    const activeQuery = query(calibrationCollection, where('serialidx', 'array-contains-any', searchTerms), limit(25));
    const { status, data: calibrations } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return calibrations;
}
