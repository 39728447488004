import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/solid'
import ReadingChip from '../Components/ReadingChip/index.js'
import { usePaginatedUserAlerts } from './paginatedalerts.js';
import { formatDate } from '../../Shared/Components/functions.js'
import useUserInfo from '../Components/UserInfo';
import { EmptyStateAlerts } from '../Components/emptystates.js';

export default function AlertsByUser(props) {
    const resultsPerPage = 10;
    const { userId } = useParams();
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedClientReadings()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const userinfoData = props.userinfo;
    const readingUserinfo = useUserInfo(userId);

    // set up firestore query
    const collectionPath = `clients/${userinfoData.clientid}/alerts`;
    const alertReadings = usePaginatedUserAlerts(collectionPath, userId, resultsPerPage, referenceItem, include);
    const currentLastVisibleItem = (alertReadings !== undefined) ? alertReadings.docs[alertReadings.size - 1] : null;

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, alertReadings.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (alertReadings !== undefined && alertReadings.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (alertReadings !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, alertReadings]);

    return (
        <div>
            {   // empty state
                alertReadings.docs.length === 0 && <EmptyStateAlerts />
            }

            <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                    {alertReadings !== undefined && alertReadings.docs.map((alert) => (

                        <li key={alert.id}>
                            <Link to={`/app/reading/${alert.data().readinguid}`} className="block hover:bg-gray-50">
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center">
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <p className="text-sm font-medium text-isober-900 truncate">
                                                    {readingUserinfo.fname} {readingUserinfo.lname}
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <span className="truncate">{formatDate(alert.data().created.toDate())}</span>
                                                </p>
                                            </div>
                                            <div className="hidden md:block">
                                                <div>
                                                    <p className="text-sm text-gray-900">
                                                        {alert.data().placemark && alert.data().placemark.name && alert.data().placemark.name}
                                                        {(!alert.data().placemark || !alert.data().placemark.name) && "—"}
                                                    </p>
                                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                                        {alert.data().subject && alert.data().subject.name && `Testing ${alert.data().subject.name}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ReadingChip value={alert.data().baclevel} unit={alert.data().bacscale} />

                                    <div>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                </div>
                            </Link>
                        </li>

                    ))
                    }
                </ul>
            </div>

            {/* pagination start */}
            { alertReadings.docs.length > 0 && /* pagination start */
                    <nav
                        className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                                <span className="font-medium">20</span> results
                            </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                            {firstItemStack.length > 1 &&
                                <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                            }
                            <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                            <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                        </div>
                    </nav>
            }
            {/* end pagination */}
        </div>
    )
}