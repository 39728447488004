import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export default function useJobCard(guid) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'jobcards', guid);
    const { status, data: jobcard } = useFirestoreDocDataOnce(documentRef, { idField: "id" });

    if (status !== 'success') {
        return undefined;
    }
    
    return jobcard;
}