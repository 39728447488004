import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import ClientListPage from './clientlist.js';
import UsersListFragment from './userlist.js';
import ClientDetailsPage from './clientdetails.js';
import CreateClientPage from './createclient.js';
import UserDetailsFragment from './userdetails.js';
import EditClientPage from './editclient.js';
import CreateUserFragment from './createuser.js';
import EditUserFragment from './edituser.js';

function ClientsPage(props) {
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        <Routes>
                            <Route path="*" element={<ClientListPage />} />
                            <Route path="create" element={<CreateClientPage />} />
                            <Route path="users/:clientId" element={<UsersListFragment />} />
                            <Route path="users/:clientId/create" element={<CreateUserFragment />} />
                            <Route path="edit/:clientId" element={<EditClientPage />} />
                            <Route path=":clientId" element={<ClientDetailsPage />} />
                            <Route path=":clientId/user/edit/:userId" element={<EditUserFragment />} />
                            <Route path=":clientId/user/:userId" element={<UserDetailsFragment />} />
                        </Routes>
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}

export default ClientsPage;
