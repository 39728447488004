import { collection, query, orderBy, limit } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export default function useSubscriptionHistory(subscriptionId) {
    // set up firestore query
    const firestore = useFirestore();
    const socialStatsCollection = collection(firestore, `subscriptions/` + subscriptionId + '/history');
    const collectionQuery = query(socialStatsCollection, orderBy('created', 'desc'), limit(10));
    const { status, data: statsList } = useFirestoreCollectionData(collectionQuery, {
        idField: 'id', // merge id into object created for each document
    });

    if (status === 'loading') {
        return undefined;
    }

    return statsList;
}