import React from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api';

// https://github.com/JustFly1984/react-google-maps-api
// https://react-google-maps-api-docs.netlify.app

const containerStyle = {
    width: '100%',
    height: '100%'
};

export default function MapCanvas(props) {
    const center = {
        lat: props.latitude,
        lng: props.longitude
    };

    return (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
            <Marker position={{ lat: props.latitude, lng: props.longitude }} />
        </GoogleMap>
    );
}