import React from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDateShort } from '../../Shared/Components/functions.js'

export default function AlertsList(props) {
    const userinfoData = props.userinfo;
    const firestore = useFirestore();
    const alertsCollection = collection(firestore, `clients/${userinfoData.clientid}/alertsconfig`);
    const activeQuery = query(alertsCollection, where('clientid', '==', userinfoData.clientid), where('hidden', '==', false));
    const { status, data: alerts } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    const formatThreshold = (t) => {
        if (t === 'L_red') {
            return 'Greater than 0.24 Mg/l';
        } else if (t === 'L_amber') {
            return 'Up to 0.24 Mg/l';
        } else {
            return 'Any positive reading';
        }
    }

    function usersuidlookup(guid) {
        if (guid === '*') {
            return "Any user";
        }

        if (props.clientUsers.has(guid)) {
            return <div>{props.clientUsers.get(guid)}</div>;
        } else {
            return <div>—</div>;
        }
    }

    return (
        <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">The alert rules configured for your organisation appear below. Select Manage to view details or select Create Alert to configure a new alert rule.</p>
                </div>
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <Link to="create">
                        <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create&nbsp;alert</button>
                    </Link>
                </div>
            </div>


            <ul className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {alerts.docs.map((alert) => (
                    <li key={alert.id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                            <div className="flex-1 truncate">
                                <div className="flex items-center space-x-3">
                                    <h3 className="text-gray-900 text-sm font-medium truncate">{formatThreshold(alert.data().threshold)}</h3>

                                    {alert.data().enabled && alert.data().enabled === true &&
                                        <span className="inline-flex items-center flex-shrink-0 inline-block px-2 py-0.5 text-xs rounded-full bg-green-100 text-green-800">
                                            <svg className="mr-1.5 h-2 w-2 text-green-800" fill="currentColor" viewBox="0 0 8 8"><circle cx={4} cy={4} r={3} /></svg>
                                            Active
                                        </span>
                                    }

                                    {alert.data().enabled === false &&
                                        <span className="flex-shrink-0 inline-block px-2 py-0.5 text-xs rounded-full bg-gray-100 text-gray-800">Disabled</span>
                                    }

                                </div>
                                <p className="mt-1 text-gray-500 text-sm truncate">{typeof alert.data().subject === 'string' ? usersuidlookup(alert.subject) : alert.data().subject.map((item) => { return usersuidlookup(item) })}</p>
                                {/* <p className="mt-1 text-gray-500 text-sm truncate">{typeof alert.data().recipient === 'string' ? usersuidlookup(alert.recipient) : alert.data().recipient.map((item) => { return usersuidlookup(item) })}</p> */}
                                <p className="mt-1 text-gray-500 text-sm truncate">
                                    {alert.data().last !== undefined && "Last triggered on " + formatDateShort(alert.data().last.toDate())}
                                    {alert.data().last === undefined && "Never been triggered"}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="-mt-px flex divide-x divide-gray-200">
                                <div className="w-0 flex-1 flex">
                                    <Link to={`/app/alerts/${alert.id}`} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-isober-900 font-medium border border-transparent rounded-bl-lg hover:text-isober-1100">
                                        <span className="ml-3">Manage</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

        </div>


    )
}