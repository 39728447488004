import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { useLocation, useParams } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import useUser from '../Components/Hooks/useuser.js';
import { formatDateDMY, formatDate } from '../../Shared/Components/functions.js';
// import { ErrorBanner } from '../Components/Helpers/index.js';

export default function ViewCrmPage(props) {
    // HOOKS
    const currentLocation = useLocation();
    const { crmId } = useParams();
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'calibrationcrm', crmId);
    const { status, data: crm } = useFirestoreDocData(documentRef);
    const user = useUser(crm.creator);

    // STATE
    // const [lastErrorMessage, setLastErrorMessage] = useState(null);
    // const [errorBannerOpen, setErrorBannerOpen] = useState(null);

    // LOADING

    if (status === 'loading') {
        return "Loading";
    }

    // console.log("[EditCrmPage] initial selection: " + JSON.stringify(initialValues.validdevices));

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The selected CRM details appear below.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            {/* button here right aligned */}
                        </div>
                    </div>

                    {/* Calibration details area */}
                    <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Certified Reference Material</h3>
                            {/* {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />} */}
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Assigned bottle number
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.bottlenumber && crm.bottlenumber}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Brand supplier
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.supplier && crm.supplier}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Reference amount
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            {crm.reference && crm.reference}
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                    %
                                                </span>
                                            </div>
                                        </div>
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Lot number
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.lotnumber && crm.lotnumber}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Manufacture date
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.manufacture && crm.manufacture}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Expiry date (DD/MM/YYYY)
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.expiry && formatDateDMY(crm.expiry.toDate())}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Lot volume
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.lotvolume && crm.lotvolume}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Bottle volume
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.bottlevolume && crm.bottlevolume}
                                    </dd>
                                </div>
                                <div className="sm: col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        At branch
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.branchId && crm.branchId}
                                    </dd>
                                </div>
                                <div className="sm: col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Date created
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {crm.created.toDate() && formatDate(crm.created.toDate())}
                                    </dd>
                                </div>
                                <div className="sm: col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Created by
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {user.fname && user.fname}
                                        {user.lname && user.lname}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    {/* TODO: decompose device IDs into list: <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Valid for the following devices</h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            FIXME
                        </div>
                    </div> */}
                </div>
            </div>
        </main>
    )
}