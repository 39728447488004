import React from 'react';
import { Link } from 'react-router-dom';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';

export default function MenuFragment(props) {
    // HOOKS
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            <Link to="/system/admin/users">
                                <li className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex justify-center space-x-3">
                                                <span className="text-white text-md font-medium truncate">Manage users</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Link>

                            <Link to="/system/admin/logs">
                                <li className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex justify-center space-x-3">
                                                <span className="text-white text-md font-medium truncate">View logs</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}