import React, { useRef, useCallback, useEffect } from 'react';
import * as Yup from "yup"
import cloneDeep from 'lodash/cloneDeep';
import DeviceChooser from '../Components/DeviceChooser/AsDropdown.js';

export default function DeviceListCapture({ handler, noteHandler, sfv, sft }) {
    // REFS
    const serialRef = useRef();
    const sensorRef = useRef();
    const handleDeviceModelChange = useCallback((newVal, setFieldValue) => {
        setModel(newVal);
    }, []);

    // STATE
    const [deviceList, setDeviceList] = React.useState([])
    const [model, setModel] = React.useState(undefined)

    useEffect(() => {
        handler(deviceList, sfv, sft);
    }, [deviceList, handler, sfv, sft]);

    // YUP

    const formSchema = Yup.object({
        modelGuid: Yup.string("Enter a model ID").required(),
        modelName: Yup.string("Enter a model name").required(),
        serialNumber: Yup.string().test(function (value) {
            const { sensorSerial } = this.parent;
            if (!sensorSerial) return (value !== null && value.trim() !== "")
            return true
        }),
        sensorSerial: Yup.string().test(function (value) {
            const { serialNumber } = this.parent;
            if (!serialNumber) return (value !== null && value.trim() !== "")
            return true
        }),
    });

    // ACTIONS

    const insertItem = async () => {
        try {
            await formSchema.validate({ "serialNumber": serialRef.current.value, "sensorSerial": sensorRef.current.value, "modelName": model.modelName, "modelGuid": model.id });
        } catch (err) {
            window.alert("Please choose a model and enter a serial number");
            return;
        }

        // check we don't already have this model/serial number
        var isDuplicate = false;
        deviceList.forEach(item =>{
            if (serialRef.current.value.trim() && (item.modelGuid === model.id) && (item.serial.trim() === serialRef.current.value.trim() || item.serial.trim() === sensorRef.current.value.trim())) {
                // serial field has a value, compare to each item's serial & sensorSerial
                isDuplicate = true;
            } 
            
            if (sensorRef.current.value.trim() && (item.modelGuid === model.id) && (item.serial.trim() === sensorRef.current.value.trim() || item.serial.trim() === sensorRef.current.value.trim())) {
                // sensor field has a value, compare to each item's serial & sensorSerial
                isDuplicate = true;
            }
        });

        if (isDuplicate) {
            const confirmAdd = window.confirm('That serial number has already been captured below. Choose OK to add it anyway. Choose Cancel to not add the duplicate.');
            if (!confirmAdd) {
                return; // User chose to cancel, do not proceed
            }
                
            // Proceed with adding the duplicate if user confirmed
        }

        const ledStyle = (model.ledStyle && model.ledStyle === true) ? true : false;
        await setDeviceList([{ "modelGuid": model.id, "modelName": model.modelName, "serial": serialRef.current.value, "sensorSerial": sensorRef.current.value, "ledStyle": ledStyle }, ...deviceList]);
        serialRef.current.value = null;
        sensorRef.current.value = null;
        serialRef.current.focus();

        // finally, pass the current set of devices/serials upwards
        // useEffect will ensure handler() is called when deviceList changes
        // per https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
    }

    const removeItem = async (key) => {
        const deviceListClone = cloneDeep(deviceList);
        deviceListClone.splice(key, 1);
        await setDeviceList(deviceListClone);

        // useEffect will ensure handler() is called when deviceList changes
        // per https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
    }
    
    const noteChanged = (e) =>{
        noteHandler(e.target.id, e.target.value); // modelGuid##serial , note text
      }

    return (
        <div className="shadow sm:rounded-md overflow-y-visible">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Device List</h3>
                    <p className="mt-1 text-sm text-gray-500">Enter the devices being received below.</p>
                </div>

                {/* start form */}
                <div className="grid grid-cols-10 gap-6">
                    <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                            Device model &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <DeviceChooser handler={handleDeviceModelChange} sfv={undefined} />
                        </dd>
                    </div>

                    <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                            Device serial number &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                    <input type="text" name="serialNumber" id="serialNumber" ref={serialRef} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" />
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                            Sensor serial number &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                    <input type="text" name="sensorSerial" id="sensorSerial" ref={sensorRef} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" />
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                            &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <button type="button" onClick={insertItem} className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md disabled:text-gray-300 text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500">
                                <span>Add</span>
                            </button>
                        </dd>
                    </div>
                </div>
                {/* end form */}

                {deviceList.length > 0 && <div>
                    {deviceList.map((device, idx) => (
                        <div key={`note_${device.modelGuid}_${device.serial}`} className="grid grid-cols-10 gap-6 my-3">
                            <div className="sm:col-span-3">
                                <p className="text-sm text-gray-700 py-3">
                                    Model: <span className='font-semibold'>{device.modelName}</span> <br/>
                                    Device serial: <span className='font-semibold'>
                                        {device.serial && device.serial}
                                        {!device.serial && "—"}
                                    </span> <br/>
                                    Sensor serial: <span className='font-semibold'>
                                        {device.sensorSerial && device.sensorSerial}
                                        {!device.sensorSerial && "—"}
                                    </span> <br/>
                                    Indicator style: <span className='font-semibold'>
                                        {device.ledStyle && device.ledStyle === true && "LED style"}
                                        {!device.ledStyle && "Numeric"}
                                    </span> <br/>
                                </p>
                            </div>

                            <div className="sm:col-span-6">
                                <input type="text" name={`note_${device.modelGuid}_${device.serial}`} id={`${device.modelGuid}##${device.serial}`} defaultValue="" onChange={noteChanged} placeholder="Note (optional)" className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" />
                            </div>

                            <div className="sm:col-span-1">
                                <span className="inline-flex items-center my-2 px-2 py-1 rounded-full text-xs font-medium leading-4 bg-isober-50 text-isober-900 focus:outline-none focus:shadow-outline mr-2">
                                    <button type="button" onClick={() => removeItem(idx)} className="flex-shrink-0 ml-0 inline-flex text-isober-500 focus:outline-none focus:text-isober-700">
                                        <span>Remove</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>}

            </div>
        </div>
    )
}