import { collection, query } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export default function useClientList() {

    // set up firestore query
    const firestore = useFirestore();
    const clientsCollection = collection(firestore, 'clients');
    const clientsQuery = query(clientsCollection);
    const { status, data: clientList } = useFirestoreCollectionData(clientsQuery, {
        idField: 'id', // merge id into object created for each document
    });

    if (status === 'loading') {
        return undefined;
    }

    return clientList;
}