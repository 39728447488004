import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import UpdateBalanceModal from './UpdateBalanceModal';
import SkuDetailModal from './SkuDetailModal';
import SkuActionMenu from './SkuActionMenu';
import useSkus from './useSkus';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DashboardGrid({ category }) {
    // STATE
    var [updateSkuModalOpen, setUpdateSkuModalOpen] = useState(false);
    var [skuDetailModalOpen, setSkuDetailModalOpen] = useState(false);
    var [selectedSkuItem, setSelectedSkuItem] = useState(undefined);

    // HOOKS
    let navigate = useNavigate();
    let skus = useSkus(category);

    // HELPERS

    const showUpdateSku = (e, item) => {
        e.preventDefault();
        setSelectedSkuItem(item);
        setUpdateSkuModalOpen(true);
    }

    const showSkuDetail = (e, item) => {
        e.preventDefault();
        setSkuDetailModalOpen(true);
        setSelectedSkuItem(item);
    }

    // HANDLER CALLBACKS

    const closeUpdateHandler = () => {
        setUpdateSkuModalOpen(false);
        setSelectedSkuItem(undefined);
    }

    const closeSkuDetailHandler = () => {
        setSkuDetailModalOpen(false);
        setSelectedSkuItem(undefined);
    }

    const showHistory = (e) => {
        e.preventDefault();

        navigate("/stock/sku/guid");
    }

    const getTotal = (balance) => {
        var retval = 0;

        if (balance?.jhb) {
            retval += balance.jhb;
        }

        if (balance?.ct) {
            retval += balance.ct;
        }

        if (balance?.dbn) {
            retval += balance.dbn;
        }

        if (balance?.bw) {
            retval += balance.bw;
        }

        if (balance?.cs) {
            retval += balance.cs;
        }

        return retval;
    }

    return (
        <div>
            {updateSkuModalOpen === true && <UpdateBalanceModal item={selectedSkuItem} handler={closeUpdateHandler} />}
            {skuDetailModalOpen === true && <SkuDetailModal item={selectedSkuItem} handler={closeSkuDetailHandler} />}

            {/* Empty state */}
            {skus.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No SKUs</p>
                    <p className="mt-2 text-sm text-gray-500">
                        No SKUs found for the selected category.
                    </p>
                </div>
            </div>}

            {/* Not empty state */}
            <ul className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 overflow-visible">
                {skus.map((item) => (
                    <li key={item.sku} className="col-span-1 flex rounded-md shadow-sm overflow-visible"> {/* Overflow-visible else menu will be occluded */}
                        <div className={getTotal(item.balance) < item.warning ? classNames('bg-orange-500', 'flex w-20 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white') : classNames('bg-green-500', 'flex w-20 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white')}>
                            <span className='text-center'>
                                {getTotal(item.balance)}
                                {getTotal(item.balance) < item.warning && ` / ${item.warning}`}
                            </span>
                        </div>
                        <div className="flex flex-1 items-center justify-between rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                            <div className="flex-1 px-4 py-2 text-sm">
                                {item.sku}
                                <p className="text-gray-500">{item.category}</p>
                            </div>
                            <div className="flex-shrink-0 pr-2">
                                <SkuActionMenu item={item} updateCountHandler={showUpdateSku} showHistoryHandler={showHistory} showDetailHandler={showSkuDetail} className="h-5 w-5" />
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}