import React from 'react';
import { useSigninCheck } from 'reactfire';
import SignInPage from './Components/SignInPage/index.js';
import { useUser } from 'reactfire';
import CalibrationsHome from './CalibrationsHome.js'
import { HelmetProvider } from 'react-helmet-async';

export default function AuthCheck() {
    const { status, data: signInCheckResult } = useSigninCheck();
    const { data: authUser } = useUser();

    if (status === 'loading') {
        return "LOADING (AuthCheck)...";
    }

    if (signInCheckResult.signedIn === true && authUser != null && authUser.uid != null) {
        console.log("[AuthCheck] will render CalibrationsHome");
        return (
            <HelmetProvider>
                <CalibrationsHome authUser={authUser} />
            </HelmetProvider>
        );
    } else {
        console.log("[AuthCheck] will render SignInPage");
        return <SignInPage />;
    }
}