import React from 'react';
import { Routes, Route } from 'react-router-dom';
import JobCardMain from './jobcardsmain.js';
import JobCardDetails from './jobcarddetails.js';

export default function JobCardsTab(props) {

    return (
        <Routes>
            <Route path="*" element={<JobCardMain />} />
            <Route path="jobcard/:jobCardId" element={<JobCardDetails userinfo={props.userinfo} />} />
        </Routes>
    )
}