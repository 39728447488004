import { collection, query, where, orderBy, limit } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/solid';
import ReadingChip from '../Components/ReadingChip/index.js';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions.js';

// props: collectionPath, alertuid
export default function AlertsById(props) {
    const firestore = useFirestore();
    const alertsCollection = collection(firestore, props.collectionPath);
    var alertsQuery = query(alertsCollection, where('alertuid', '==', props.alertuid), orderBy('created', 'desc'), limit(10));
    const { status, data: readings } = useFirestoreCollection(alertsQuery);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    return (
        <div className="mt-0 bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200">
                {readings !== undefined && readings.docs.map((reading) => (
                    <li key={reading.id}>
                        <Link to={`/app/reading/${reading.data().readinguid}`} className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                            <p className="text-sm font-medium text-isober-900 truncate">
                                                {props.clientusers.has(reading.data().useruid) === true && props.clientusers.get(reading.data().useruid)}
                                                {props.clientusers.has(reading.data().useruid) !== true && "No name"}
                                            </p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <span className="truncate">{formatDate(reading.data().created.toDate())}</span>
                                            </p>
                                        </div>
                                        <div className="hidden md:block">
                                            <div>
                                                <p className="text-sm text-gray-900">
                                                    {reading.data().placemark && reading.data().placemark.name && reading.data().placemark.name}
                                                    {(!reading.data().placemark || !reading.data().placemark.name) && "—"}
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    {reading.data().subject && reading.data().subject.name && `Testing ${reading.data().subject.name}`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ReadingChip value={reading.data().baclevel} unit={reading.data().bacscale} />

                                <div>
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </Link>
                    </li>
                ))
                }

                {readings.docs.length === 0 &&
                    <li className='px-4 py-4 sm:px-6' key='NeverBeen'>
                        <p>This alert has never been triggered.</p>
                    </li>
                }
            </ul>
        </div>
    );
}