import React, { useState, useRef } from 'react';
import DeviceListCapture from './devicelistcapture.js';
import { BranchControl } from '../Components/Forms/branch.js';
import { Formik } from 'formik';
import * as Yup from "yup"
import { useNavigate } from "react-router-dom";
import ConfirmReceipt from './confirmReceipt.js';
import { useFirestore, useAuth } from 'reactfire';
import { addDoc, collection, serverTimestamp, writeBatch, doc } from 'firebase/firestore';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import CustomerAutocomplete from '../Components/Autocomplete/customersearch';
import AccountManagerControl from './AccountManager.js';

// https://reactjs.org/docs/lifting-state-up.html

export default function QuickReceiveTab(props) {
    // HOOKS
    const formikRef = useRef(null);
    const navigate = useNavigate();
    const auth = useAuth();
    const firestore = useFirestore();

    // eslint-disable-next-line
    const logConfiguration = collection(firestore, 'calibrationlogs');
    const jobcardsConfiguration = collection(firestore, 'jobcards');

    // STATE
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState();
    const [selectedCustomerNameForDisplay, setSelectedCustomerNameForDisplay] = useState(null);
    const [notes, setNotes] = useState(new Map());
    const [jobcardDocId, setJobcardDocId] = useState(undefined);
    const [deviceDocId, setDeviceDocId] = useState(undefined);

    // LIFTING STATE

    // from DeviceListCapture
    const handleDeviceListChange = async (newVal, setFieldValue, setFieldTouched) => {
        await setFieldValue('deviceList', newVal, true);
        await setFieldTouched('deviceList', newVal, true);
    }

    // a note field in DeviceListCapture was changed
    const handleNoteChange = async (identifier, value) => {
        await setNotes(new Map(notes.set(identifier, value))); // need to clone 'notes' or setState will see the same object IDs and noop
    }

    // from CustomerAutocomplete
    const handleCustomerChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        setFieldValue('customerGuid', newVal.id);
        setFieldValue('customerName', newVal.name);
        setFieldValue('certificateCustomerName', newVal.name);

        // keep our own copy outside the form to display to the user
        setSelectedCustomerNameForDisplay(newVal.name);
    }

    // from BranchControl
    const handleBranchChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        await setFieldValue('branchId', newVal, true);
        await setFieldTouched('branchId', true);
    }

    // from AccountManagerControl
    const handleAccountManagerChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        await setFieldValue('accountManager', newVal, true);
        await setFieldTouched('accountManager', true);
    }

    // from ConfirmReceipt
    const handleConfirmChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    // FORMS

    const initialValues = { customerGuid: '', customerName: '', certificateCustomerName: '', deviceList: [], branchId: 1 };
    const validationSchema = Yup.object({
        customerGuid: Yup.string("Choose customer").required("Required"),
        customerName: Yup.string("Choose customer").required("Required"),
        deviceList: Yup.array().required("At least one device is required").min(1),
        branchId: Yup.number("Choose branch").required("Required").min(1).max(5),
        certificateCustomerName: Yup.string("Enter certificate customer name").required("Required"),
    });

    async function handleSubmit(form) {
        setFormLoading(true);

        // recheck the form out of caution
        try {
            await validationSchema.validate(form);
            closeErrorBanner();
        } catch (err) {
            showErrorBanner("Form is invalid");
            return;
        }

        var deviceSerials = [];
        form.deviceList.forEach(d => {
            if (d.serial.trim()) {
                deviceSerials.push(d.serial.trim().toUpperCase());
            }
            if (d.sensorSerial.trim()) {
                deviceSerials.push(d.sensorSerial.trim().toUpperCase());
            }
        });

        var newDocument = {
            creator: auth.currentUser.uid,
            created: serverTimestamp(),
            status: 'Open',
            branch: Number(form.branchId),
            clientGuid: form.customerGuid,
            clientName: form.customerName,
            certificateClientName: form.certificateCustomerName,
            deviceIdx: deviceSerials,
            numDevices: form.deviceList.length
        };

        // merge in optional account manager
        if (form.accountManager) {
            newDocument.accountManager = form.accountManager;
        }

        try {
            const docRef = await addDoc(jobcardsConfiguration, newDocument);

            // save jobcard ID for when we redirect to calibrate
            setJobcardDocId(docRef.id);

            // perform a batch write of devices to the job card
            const thisJobcardCollectionRef = collection(firestore, `jobcards/${docRef.id}/devices`);
            const batch = writeBatch(firestore);
            form.deviceList.forEach(d => {
                const docRef = doc(thisJobcardCollectionRef);
                const ledStyle = (d.ledStyle && d.ledStyle === true) ? true : false;
                var newDeviceRecord = { modelId: d.modelGuid, model: d.modelName, status: 'Not started', ledStyle: ledStyle };

                // one or both of device/sensor serial will be set
                if (d.serial) {
                    newDeviceRecord.serial = d.serial;
                }

                // one or both of device/sensor serial will be set
                if (d.sensorSerial) {
                    newDeviceRecord.sensorSerial = d.sensorSerial;
                }

                // dial in the note if we have one
                const k = `${d.modelGuid}##${d.serial}`
                if (notes.has(k)) {
                    newDeviceRecord.note = notes.get(k);
                }

                batch.set(docRef, newDeviceRecord);

                // save jobcard ID for when we redirect to calibrate
                setDeviceDocId(docRef.id)
            });
            await batch.commit();

            // log this event
            // merge user display name into form
            const logConfiguration = collection(firestore, 'calibrationlogs');
            var userDisplayName = "Not set";
            if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
                userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
            }
            const descWord = (form.deviceList.length === 1) ? 'device' : 'devices';
            await addDoc(logConfiguration, {
                user: auth.currentUser.uid,
                dts: serverTimestamp(),
                reference: docRef.id,
                success: true,
                action: "Receive units (quick tab)",
                detail: `${form.deviceList.length} ${descWord} for ${form.customerName}`,
                username: userDisplayName
            });

            // show dialog and then navigate to dashboard
            showSuccessDialog();
        } catch (err) {
            setFormLoading(false);
            showErrorBanner("Error: could not save customer record " + err.message);
        }

        setFormLoading(false);
    }

    // HELPERS

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);

        // when done with quick receive, we navigate directly to calibrate the 
        // only, or the first device on the list
        if (!jobcardDocId || !deviceDocId) {
            window.alert("Error, cannot redirect to calibrate");
            navigate('/calibrations'); // outta here
        } else {
            gotoCalibrate(jobcardDocId, deviceDocId);
        }
    }

    function showErrorBanner(msg) {
        setLastErrorMessage(msg);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    const gotoCalibrate = (jobcardGuid, deviceGuid) => {
        if (!jobcardGuid || !deviceGuid) {
            return;
        }

        navigate(`/calibrations/calibrate/${jobcardGuid}/${deviceGuid}`); // outta here
    }

    return (
        <div>
            <div className="py-4">

                <ConfirmReceipt open={successDialogOpen} onChange={handleConfirmChange} />

                <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty, setFieldValue, setFieldTouched, values }) => (
                        <form onSubmit={handleSubmit}>

                            <div className="my-4">
                                <div className="shadow sm:rounded-md sm:overflow-display">
                                    <div className="bg-white px-4 space-y-4 sm:p-4">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Alcohol Breathalysers</h3>
                                            <p className="mt-1 text-sm text-gray-500">Use the form below to capture devices without driver information:</p>
                                        </div>

                                        <div className="mt-0">
                                            {formLoading && <LoadingBanner />}
                                            {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                        </div>

                                        {/* Account manager */}
                                        <AccountManagerControl handler={handleAccountManagerChange} sfv={setFieldValue} sft={setFieldTouched} />

                                        {/* Branch */}
                                        <BranchControl handler={handleBranchChange} sfv={setFieldValue} sft={setFieldTouched} />
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Customer Information</h3>
                                            <p className="mt-1 text-sm text-gray-500">Enter information about the company or person delivering the units.</p>
                                        </div>

                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                                    Customer name
                                                    {selectedCustomerNameForDisplay !== null && <span className='text-green-700'>&nbsp; {selectedCustomerNameForDisplay}</span>}
                                                </label>
                                                <CustomerAutocomplete handler={handleCustomerChange} sfv={setFieldValue} sft={setFieldTouched} showCreateOption={true} userinfo={props.userinfo} />
                                            </div>
                                            <div className="sm:col-span-6">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Customer name as it will appear on certificate
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="certificateCustomerName" id="certificateCustomerName" value={values.certificateCustomerName} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <DeviceListCapture handler={handleDeviceListChange} sfv={setFieldValue} sft={setFieldTouched} noteHandler={handleNoteChange} />
                            </div>

                            {/* Button actions */}
                            <div className="mt-4 flex justify-end">
                                <button type="submit" disabled={formLoading || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}