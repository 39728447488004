import React, { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import CustomerAutocomplete from '../Components/Autocomplete/customersearch';
import { Formik, ErrorMessage } from 'formik';
import AccountManagerControl from '../Receiving/AccountManager.js';
import { BranchControl } from '../../Stock/BranchControl';
import * as Yup from "yup"

export default function ReassignClientDialog({ isOpen, handler, userinfo }) {
    // STATE
    const [selectedCustomerNameForDisplay, setSelectedCustomerNameForDisplay] = useState(undefined);
    const [open, setOpen] = useState(isOpen)

    // HOOKS
    const certificateNameRef = useRef(undefined);

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])

    // FUNCTIONS

    // close the window
    function closeDialog() {
        setOpen(false);
        handler(undefined);
    }

    // from CustomerAutocomplete
    const handleCustomerChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        setFieldValue('customerGuid', newVal.id);
        setFieldTouched('customerGuid', true);

        setFieldValue('customerName', newVal.name);
        setFieldTouched('customerName', true);

        setFieldValue('customerNameCertificate', newVal.name);
        setFieldTouched('customerNameCertificate', true);
        certificateNameRef.current.value = newVal.name;

        // keep our own copy outside the form to display to the user
        setSelectedCustomerNameForDisplay(newVal.name);
    }

    const initialValues = { customerGuid: '', customerName: '', reasonNote: '', customerNameCertificate: '' };
    const validationSchema = Yup.object({
        customerGuid: Yup.string("Choose customer").required("Required"),
        customerName: Yup.string("Choose customer").required("Required"),
        reasonNote: Yup.string("Enter a reason note").required("Required"),
        branchId: Yup.number("Choose branch").required("Required").min(1).max(5),
        customerNameCertificate: Yup.string("Enter certificate customer name").required("Required"),
        accountManager: Yup.object({
            id: Yup.string().required('Account manager ID is required'),
            displayName: Yup.string().required('Account manager display name is required'),
        }),
    });

    // from AccountManagerControl
    const handleAccountManagerChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        await setFieldValue('accountManager', newVal, true);
        await setFieldTouched('accountManager', true);
    }

    // from BranchControl
    const handleBranchChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 

        if (newVal.branch.numeric) {
            await setFieldValue('branchId', Number(newVal.branch.numeric), true);
            await setFieldTouched('branchId', true);
        }
    }

    // re-validates data and calls the handler
    async function handleSubmit(form) {
        // // recheck the form out of caution
        try {
            await validationSchema.validate(form);

            setOpen(false);
            handler(form); // handler receives object with values we want
        } catch (err) {
            window.alert("Form is not valid");
            return;
        }
    }

    return (
        <>
            {/* onSubmit={handleSubmit} */}
            <Formik validator={() => ({})} onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema} >
                {({ handleSubmit, handleChange, handleBlur, setFieldValue, setFieldTouched, isValid, dirty, isSubmitting, isValidating, errors }) => (
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => { /* no-op */ }}>
                            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                </Transition.Child>

                                <form onSubmit={handleSubmit}>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500" onClick={() => closeDialog()} >
                                                    <span className="sr-only">Close</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                                </div>
                                                <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                        Reassign Calibration Client
                                                    </Dialog.Title>

                                                    <p className='text-gray-500'>This calibration will be moved over to a new job card for the selected client. Enter their details below.</p>
                                                    {/* <p className='text-gray-500'>Submitting: {JSON.stringify(isSubmitting)}</p>
                                                    <p className='text-gray-500'>Valid: {JSON.stringify(isValid)}</p>
                                                    <p className='text-gray-500'>Validating: {JSON.stringify(isValidating)}</p>
                                                    <p className='text-gray-500'>dirty: {JSON.stringify(dirty)}</p>
                                                    <p className='text-gray-500'>Errors: {JSON.stringify(errors)}</p> */}
                                                    <div className="mt-2">
                                                        <div className="">
                                                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                                                Customer name
                                                                {selectedCustomerNameForDisplay !== null && <span className='text-green-700'>&nbsp; {selectedCustomerNameForDisplay}</span>}
                                                            </label>
                                                            <CustomerAutocomplete handler={handleCustomerChange} sfv={setFieldValue} sft={setFieldTouched} showCreateOption={true} userinfo={userinfo} />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="customerNameCertificate´" className="block text-sm font-medium text-gray-700">
                                                            Customer name on certificate: &nbsp;
                                                            <ErrorMessage name="customerNameCertificate" component="span" className="text-red-700 italic" />
                                                        </label>
                                                        <div className="">
                                                            <input type="text" ref={certificateNameRef} onChange={handleChange} onBlur={handleBlur} name="customerNameCertificate" id="customerNameCertificate" className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 sm:text-sm border-gray-300 rounded-md" placeholder="Customer name on certificate" />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="reasonNote" className="block text-sm font-medium text-gray-700">
                                                            Account manager: &nbsp;
                                                            <ErrorMessage name="accountManager" component="span" className="text-red-700 italic" />
                                                        </label>

                                                        <AccountManagerControl handler={handleAccountManagerChange} sfv={setFieldValue} sft={setFieldTouched} hideTitle={true} />
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="reasonNote" className="block text-sm font-medium text-gray-700">
                                                            Branch: &nbsp;
                                                            <ErrorMessage name="branchId" component="span" className="text-red-700 italic" />
                                                        </label>

                                                        <BranchControl handler={handleBranchChange} sfv={setFieldValue} sft={setFieldTouched} />
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="reasonNote" className="block text-sm font-medium text-gray-700">
                                                            Reason note: &nbsp;
                                                            <ErrorMessage name="reasonNote" component="span" className="text-red-700 italic" />
                                                        </label>
                                                        <div className="">
                                                            <input type="text" onChange={handleChange} onBlur={handleBlur} name="reasonNote" id="reasonNote" className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 sm:text-sm border-gray-300 rounded-md" placeholder="Reason" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <button type="submit" disabled={!dirty || !isValid || isSubmitting} className="disabled:opacity-40 disabled:bg-isober-1000 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-800 text-base font-medium text-white hover:bg-isober-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" >
                                                    Confirm
                                                </button>
                                                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => closeDialog()} >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>

                                    </Transition.Child>
                                </form>
                            </div>
                        </Dialog>
                    </Transition.Root>

                )}
            </Formik>

        </>



    )
}