import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export default function useCrm(crmGuid) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'calibrationcrm', crmGuid);

    // eslint-disable-next-line no-unused-vars
    const { status, data: crm } = useFirestoreDocDataOnce(documentRef, { idField: "id" });

    if (status !== 'success') {
        console.log("CRM for " + status + " = undefined");
        return undefined;
    }

    return crm;
}