import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './Landing/index.js';
import PrivacyPage from './Landing/PrivacyPage/index.js';
import TermsPage from './Landing/TermsPage/index.js';
import NotFoundPage from './Landing/NotFoundPage/index.js';
import Application from './Application/index.js';
import Calibrations from './Calibrations/index.js';
import System from './System/index.js';
import Stock from './Stock/index.js';
import "./tailwind.output.css"
import firebaseConfig from './firebaseConfig';
import { FirebaseAppProvider } from 'reactfire';
import LoadingSpinner from './Shared/Components/loading.js';
import './tailwind.css';

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={ <LoadingSpinner /> }>
            <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />                    
                    <Route path="/app/*" element={<Application />} />
                    <Route path="/calibrations/*" element={<Calibrations />} />
                    <Route path="/system/*" element={<System />} />
                    <Route path="/stock/*" element={<Stock />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/*" element={<NotFoundPage />} />
                </Routes>
            </Router>
            </FirebaseAppProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);