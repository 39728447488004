import { Routes, Route } from 'react-router-dom';
import SubscriptionFragment from './subscriptionFragment.js';
import SubscriptionHistoryFragment from './subscriptionHistory.js';

export default function AndroidTab(props) {

    return (
        <Routes>
            <Route path="*" element={ <SubscriptionFragment /> } />
            <Route path="subscription/:subscriptionId" element={ <SubscriptionHistoryFragment /> } />
        </Routes>

    )
}