import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { matchSorter } from 'match-sorter'
import { usePopper } from 'react-popper'
import { useCombobox, useMultipleSelection } from 'downshift'
import { useDeepCompareEffect } from 'react-use'
import Highlighter from 'react-highlight-words'
import cc from 'classcat'
import { useParams } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import { doc, updateDoc, deleteField } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

/*
<Formik
      initialValues={{
        email: ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleLogin(values, setSubmitting);
      }} // removing this line make Typescript complain
    >
 */

function RecipientPicker(props) {
    const {
        items,
        optionFilterFunc = defaultOptionFilterFunc,
        itemRenderer = defaultItemRenderer,
        placeholder,
        selectedItems,
        ...downshiftProps
    } = props

    const [inputItems, setInputItems] = useState(items)
    const disclosureRef = useRef(null)
    const popoverRef = useRef(null)
    const { styles, attributes, forceUpdate } = usePopper(
        disclosureRef.current,
        popoverRef.current,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        },
    )

    const {
        getSelectedItemProps,
        getDropdownProps,
        addSelectedItem,
        removeSelectedItem,
    } = useMultipleSelection({
        ...downshiftProps,
        selectedItems,
        stateReducer: (_, actionAndChanges) => {
            const { type, changes } = actionAndChanges
            switch (type) {
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    return {
                        ...changes,
                        // activeIndex: null,
                    }
                default:
                    return changes
            }
        },
    })

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        selectItem,
        inputValue,
    } = useCombobox({
        selectedItem: null,
        items: inputItems,
        onInputValueChange: ({ inputValue }) => {
            const filteredItems = optionFilterFunc(items, inputValue || '')

            setInputItems(filteredItems)
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges
            switch (type) {
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        highlightedIndex: state.highlightedIndex,
                        inputValue: '',
                    }
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        highlightedIndex: state.highlightedIndex,
                        isOpen: true,
                        inputValue: '',
                    }
                default:
                    return changes
            }
        },
        onStateChange: ({ type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        if (selectedItemValues.includes(selectedItem.value)) {
                            removeSelectedItem(selectedItem)
                        } else {
                            // we limit the number of recipients to 3 
                            if (selectedItemValues.length < 3) {
                                addSelectedItem(selectedItem)
                            }
                        }

                        selectItem(null)
                    }
                    break
                default:
                    break
            }
        },
    })

    useDeepCompareEffect(() => {
        setInputItems(items)
    }, [items])

    React.useEffect(() => {
        if (selectedItems && forceUpdate) {
            forceUpdate()
        }
    }, [selectedItems, forceUpdate])

    const selectedItemValues = selectedItems.map((item) => item.value)

    return (
        <div className="relative w-full">
            <label
                {...getLabelProps({
                    className: 'font-medium text-gray-700 text-xs mb-2 block',
                })}
            >
                Choose up to 3 users:
            </label>
            <div>
                <div className="my-2">
                    {selectedItems.map((selectedItem, index) => (
                        <span
                            key={`selected-item-${index}`}
                            className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium leading-4 bg-isober-50 text-isober-900 focus:outline-none focus:shadow-outline mr-2"
                            {...getSelectedItemProps({ selectedItem, index })}
                        >
                            {selectedItem.label}
                            <button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    removeSelectedItem(selectedItem)
                                }}
                                type="button"
                                className="flex-shrink-0 ml-1 inline-flex text-isober-500 focus:outline-none focus:text-isober-700"
                                aria-label="Remove small badge"
                            >
                                &#10005;
                            </button>
                        </span>
                    ))}
                </div>
                <div className="relative" {...getComboboxProps()}>
                    <input
                        {...getInputProps(
                            getDropdownProps({
                                className:
                                    'w-full p-2 text-sm focus:outline-none focus:shadow-outline rounded border border-gray-300',
                                placeholder,
                                onClick: isOpen ? () => { } : openMenu,
                                onFocus: isOpen ? () => { } : openMenu,
                                ref: disclosureRef,
                            }),
                        )}
                    />

                </div>
                <div
                    style={styles.popper}
                    {...attributes.popper}
                    {...getMenuProps({ ref: popoverRef, className: 'z-50 w-full' })}
                >
                    <ul className="bg-white shadow-md">
                        {isOpen &&
                            inputItems.map((item, index) => (
                                <li
                                    className={cc({
                                        'p-2 text-sm bg-white border-b': true,
                                        'bg-gray-100': highlightedIndex === index,
                                    })}
                                    key={`${item.value}${index}`}
                                    {...getItemProps({ item, index })}
                                >
                                    {
                                        <div className="flex items-center space-x-2">
                                            {selectedItemValues.includes(item.value) && (
                                                <span role="img" aria-label="Selected">
                                                    ✅
                                                </span>
                                            )}
                                            <Highlighter autoEscape searchWords={[inputValue || '']} textToHighlight={itemRenderer(item)} highlightClassName="bg-yellow-300" />
                                        </div>
                                    }
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

function SubjectPicker(props) {
    const {
        items,
        optionFilterFunc = defaultOptionFilterFunc,
        itemRenderer = defaultItemRenderer,
        placeholder,
        selectedItems,
        ...downshiftProps
    } = props

    const [inputItems, setInputItems] = useState(items)
    const disclosureRef = useRef(null)
    const popoverRef = useRef(null)
    const { styles, attributes, forceUpdate } = usePopper(
        disclosureRef.current,
        popoverRef.current,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        },
    )

    const {
        getSelectedItemProps,
        getDropdownProps,
        addSelectedItem,
        removeSelectedItem,
    } = useMultipleSelection({
        ...downshiftProps,
        selectedItems,
        stateReducer: (_, actionAndChanges) => {
            const { type, changes } = actionAndChanges
            switch (type) {
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    return {
                        ...changes,
                        // activeIndex: null,
                    }
                default:
                    return changes
            }
        },
    })

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        selectItem,
        inputValue,
    } = useCombobox({
        selectedItem: null,
        items: inputItems,
        onInputValueChange: ({ inputValue }) => {
            const filteredItems = optionFilterFunc(items, inputValue || '')

            setInputItems(filteredItems)
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges
            switch (type) {
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        highlightedIndex: state.highlightedIndex,
                        inputValue: '',
                    }
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        highlightedIndex: state.highlightedIndex,
                        isOpen: true,
                        inputValue: '',
                    }
                default:
                    return changes
            }
        },
        onStateChange: ({ type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        if (selectedItemValues.includes(selectedItem.value)) {
                            removeSelectedItem(selectedItem)
                        } else {
                            addSelectedItem(selectedItem)
                        }

                        selectItem(null)
                    }
                    break
                default:
                    break
            }
        },
    })

    useDeepCompareEffect(() => {
        setInputItems(items)
    }, [items])

    React.useEffect(() => {
        if (selectedItems && forceUpdate) {
            forceUpdate()
        }
    }, [selectedItems, forceUpdate])

    const selectedItemValues = selectedItems.map((item) => item.value)

    return (
        <div className="relative w-full">
            <label
                {...getLabelProps({
                    className: 'font-medium text-gray-700 text-xs mb-2 block',
                })}
            >
                Choose users:
            </label>
            <div>
                <div className="my-2">
                    {selectedItems.map((selectedItem, index) => (
                        <span
                            key={`selected-item-${index}`}
                            className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium leading-4 bg-isober-50 text-isober-900 focus:outline-none focus:shadow-outline mr-2"
                            {...getSelectedItemProps({ selectedItem, index })}
                        >
                            {selectedItem.label}
                            {selectedItem.uid !== "*" && <button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    removeSelectedItem(selectedItem)
                                }}
                                type="button"
                                className="flex-shrink-0 ml-1 inline-flex text-isober-500 focus:outline-none focus:text-isober-700"
                                aria-label="Remove small badge"
                            >
                                &#10005;
                            </button>}
                        </span>
                    ))}
                </div>
                <div className="relative" {...getComboboxProps()}>
                    <input
                        {...getInputProps(
                            getDropdownProps({
                                className:
                                    'w-full p-2 text-sm focus:outline-none focus:shadow-outline rounded border border-gray-300',
                                placeholder,
                                onClick: isOpen ? () => { } : openMenu,
                                onFocus: isOpen ? () => { } : openMenu,
                                ref: disclosureRef,
                            }),
                        )}
                    />

                </div>
                <div
                    style={styles.popper}
                    {...attributes.popper}
                    {...getMenuProps({ ref: popoverRef, className: ' w-full' })}
                >
                    <ul className="bg-white shadow-md">
                        {isOpen &&
                            inputItems.map((item, index) => (
                                <li
                                    className={cc({
                                        'p-2 text-sm bg-white border-b': true,
                                        'bg-gray-100': highlightedIndex === index,
                                    })}
                                    key={`${item.value}${index}`}
                                    {...getItemProps({ item, index })}
                                >
                                    {
                                        <div className="flex items-center space-x-2">
                                            {selectedItemValues.includes(item.value) && (
                                                <span role="img" aria-label="Selected">
                                                    ✅
                                                </span>
                                            )}
                                            <Highlighter autoEscape searchWords={[inputValue || '']} textToHighlight={itemRenderer(item)} highlightClassName="bg-yellow-300" />
                                        </div>
                                    }
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

function defaultOptionFilterFunc(items, inputValue) {
    return matchSorter(items, inputValue, { keys: ['value', 'label'] })
}

function defaultItemRenderer(selected) {
    return selected.label
}

export default function EditAlert(props) {

    // set up firestore query for the document
    const clientId = props.userinfo.clientid;
    const { alertId } = useParams();
    const firestore = useFirestore();
    const configCollectionPath = `clients/${clientId}/alertsconfig`;
    const documentRef = doc(firestore, configCollectionPath, alertId);
    const { status, data: alertDocument } = useFirestoreDocData(documentRef);

    // variable setup
    const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    const minutes = ["00", "30"];
    const clientUsersArray = Array.from(props.clientUsers, ([value, label]) => ({ value, label })); // convert the lookup map to an array

    // Initial values
    const initialRecipients = alertDocument.recipient.map(function (recipientUid) {
        let rv = {};
        rv.value = recipientUid;
        rv.label = usernameFromGuid(recipientUid);
        return rv;
    });
    const initialSubjects = alertDocument.subject.map(function (subjectUid) {
        let rv = {};
        rv.uid = subjectUid;
        rv.label = usernameFromGuid(subjectUid);
        return rv;
    });

    // Form
    const initialValues = { threshold: alertDocument.threshold, weekday: alertDocument.weekday, subjects: alertDocument.subject, recipients: alertDocument.recipient, notbefore: alertDocument.notbefore, notafter: alertDocument.notafter };
    const validationSchema = Yup.object({
        recipients: Yup.array().of(Yup.string().typeError("Invalid entry")).required("At least one recipient is required").min(1).max(3, "Max recipients is 3"),
        subjects: Yup.array().required("At least one subject is required").min(1),
        threshold: Yup.string("Select a threshold").matches(/(L_any|L_amber|L_red)/, { message: "Invalid option" }),
        weekday: Yup.string("Select a day of the week").matches(/(any|weekdays|weekends|mon|tue|wed|thu|fri|sat|sun)/, { message: "Invalid option" }),
        notbefore: Yup.string().test(function (value) {
            const { notafter } = this.parent;
            if (notafter && notafter.match(/[0-9][0-9]:[0-9][0-9]/) && !value) return this.createError({ message: 'Required' });
            return true
        }),
        notafter: Yup.string().test(function (value) {
            const { notbefore } = this.parent;
            if (notbefore && notbefore.match(/[0-9][0-9]:[0-9][0-9]/) && !value) return this.createError({ message: 'Required' });
            return true
        })
    });

    // Hooks
    let navigate = useNavigate();

    // State
    const [pickerItems /*, setPickerItems */] = React.useState(clientUsersArray)
    const [selectedRecipients, setSelectedRecipients] = React.useState(initialRecipients)
    const [selectedSubjects, setSelectedSubjects] = React.useState(initialSubjects)
    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // Functions

    function usernameFromGuid(userGuid) {
        if (userGuid === "*") {
            return "Any user";
        } else if (props.clientUsers.has(userGuid)) {
            return props.clientUsers.get(userGuid);
        } else {
            return "No name";
        }
    }

    const handleSelectedRecipientsChange = (selectedItems, setFieldValue) => {
        if (selectedItems) {
            // only use the user's guid
            var selectedGuids = [];
            selectedItems.forEach(element => {
                selectedGuids.push(element.value);
            });

            setSelectedRecipients(selectedItems)
            setFieldValue('recipients', selectedGuids); // set formik value to just the array of ids
        }
    }

    const handleSelectedSubjectsChange = (selectedItems, setFieldValue) => {
        if (selectedItems) {
            // only use the user's guid
            var selectedGuids = [];
            var selectedItemsClone = [];

            if (selectedItems.length === 0) {
                selectedGuids.push("*");
                setSelectedSubjects([{ label: 'Any user', uid: '*' }])
            } else {
                selectedItems.forEach(element => {
                    if (element.uid !== "*") {
                        selectedGuids.push(element.value);
                        selectedItemsClone.push(element);
                    }
                });
                setSelectedSubjects(selectedItemsClone)
            }

            setFieldValue('subjects', selectedGuids); // set formik value to just the array of ids
        }
    }

    async function handleCancel(event) {
        event.preventDefault();
        navigate(-1, { replace: true });
    }

    function showSuccessDialog() {
        setSuccessDialogOpen(true);
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/app/alerts'); // outta here
    }

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        // window.alert(JSON.stringify(form));

        let updatedDocumentData = { "subject": form.subjects, "recipient": form.recipients, "threshold": form.threshold }

        // copy threshold into numeric values
        if (form.threshold === 'L_red') {
            updatedDocumentData.threshold_min = 0.2379;
            updatedDocumentData.threshold_max = 1000;
        } else if (form.threshold === 'L_amber') {
            updatedDocumentData.threshold_min = 0;
            updatedDocumentData.threshold_max = 0.2379;
        } else {
            updatedDocumentData.threshold_min = 0;
            updatedDocumentData.threshold_max = 1000;
        }

        // validate weekday
        if (form.weekday && form.weekday.match(/(any|weekdays|weekends|mon|tue|wed|thu|fri|sat|sun)/)) {
            updatedDocumentData.weekday = form.weekday;
        } else {
            updatedDocumentData.weekday = "any";
        }

        if (form.notbefore && form.notbefore.match(/[0-9][0-9]:[0-9][0-9]/) && form.notafter && form.notafter.match(/[0-9][0-9]:[0-9][0-9]/)) {
            updatedDocumentData.notbefore = form.notbefore;
            updatedDocumentData.notafter = form.notafter;
        } else {
            updatedDocumentData.notbefore = deleteField();
            updatedDocumentData.notafter = deleteField();
        }

        // window.alert(JSON.stringify(updatedDocumentData));

        // the part that works
        await updateDoc(documentRef, updatedDocumentData)
        .catch((error) => {
            setFormLoading(false);
            showErrorBanner("Could not update alert details.");
            console.log(error);
        })
        .then(() => {
            setFormLoading(false);
            showSuccessDialog();
        });
    }

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg py-5">

            {/* Update user modal confirmation */}
            {successDialogOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/*  This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Heroicon name: outline/check */}
                                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        The alert was updated successfully.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button onClick={hideSuccessDialog} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values, isSubmitting, setFieldValue, setSubmitting }) => (
                    <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div>
                                <div className="px-4 sm:px-6 flex items-center justify-between">
                                    <div>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex items-center">Alert Details</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Use the form below to update this alert.</p>
                                    </div>

                                    {alertDocument.enabled && alertDocument.enabled === true &&
                                        <span className="inline-flex items-center flex-shrink-0 inline-block px-2 py-0.5 text-sm rounded-full bg-green-100 text-green-800 inline-flex items-center">
                                            <svg className="mr-1.5 h-2 w-2 text-green-800" fill="currentColor" viewBox="0 0 8 8"><circle cx={4} cy={4} r={3} /></svg>
                                            Alert is active
                                        </span>
                                    }

                                    {alertDocument.enabled === false &&
                                        <span className="flex-shrink-0 inline-block px-2 py-0.5 text-sm rounded-full bg-gray-100 text-gray-800 inline-flex items-center">Alert is disabled</span>
                                    }
                                </div>

                                <div className="border-t border-gray-200 mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3 pt-6">

                                    {/* Loading spinner */}
                                    {formLoading &&
                                        <div className="sm:col-span-6 mx-6">
                                            <img src="/images/loading.gif" width="40" height="40" alt="" />
                                        </div>
                                    }

                                    {/* Error banner */}
                                    {errorBannerOpen && <div className="mx-6 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-sm text-yellow-700">{lastErrorMessage}</p>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className="sm:col-span-3 px-4 sm:px-6">
                                        <label htmlFor="recipients" className="block text-sm font-medium text-gray-700">
                                            Send an alert to &nbsp;
                                            <ErrorMessage name="recipients" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <RecipientPicker placeholder="Type name of user"
                                                items={pickerItems}
                                                selectedItems={selectedRecipients}
                                                onSelectedItemsChange={(changes) =>
                                                    handleSelectedRecipientsChange(changes.selectedItems, setFieldValue)
                                                } />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3 px-4 sm:px-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="subjects" className="block text-sm font-medium text-gray-700">
                                            When a reading by &nbsp;
                                            <ErrorMessage name="subjects" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <SubjectPicker placeholder="Type name of user"
                                                items={pickerItems}
                                                selectedItems={selectedSubjects}
                                                onSelectedItemsChange={(changes) =>
                                                    handleSelectedSubjectsChange(changes.selectedItems, setFieldValue)
                                                } />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 px-4 sm:px-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="threshold" className="block text-sm font-medium text-gray-700">
                                            Exceeds the value &nbsp;
                                            <ErrorMessage name="threshold" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <select id="threshold" name="threshold" defaultValue={values.threshold} onChange={handleChange} onBlur={handleBlur} className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm rounded-md">
                                                <option value="L_any">Any positive reading</option>
                                                <option value="L_amber">Amber (up to 0.24 Mg/l BrAC)</option>
                                                <option value="L_red">Red (greater than 0.24 Mg/l BrAC)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="weekday" className="block text-sm font-medium text-gray-700">
                                            Day of week is &nbsp;
                                            <ErrorMessage name="weekday" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <select id="weekday" name="weekday" defaultValue={values.weekday} onChange={handleChange} onBlur={handleBlur} className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm rounded-md">
                                                <option value="any">Any day</option>
                                                <option value="weekdays">Weekdays</option>
                                                <option value="weekends">Weekends</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 px-4 sm:px-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="notafter" className="block text-sm font-medium text-gray-700">
                                            Measurement is at or after &nbsp;
                                            <ErrorMessage name="notafter" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <select id="notafter" name="notafter" defaultValue={values.notafter} onChange={handleChange} onBlur={handleBlur} className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm rounded-md">
                                                <option value="" />
                                                {hours.map(hour => (
                                                    minutes.map(minute => (
                                                        <option key={hour + ":" + minute} value={hour + ":" + minute}>{hour}:{minute} {(parseInt(hour, 10) >= 12) ? "PM" : "AM"}</option>
                                                    ))
                                                ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="notbefore" className="block text-sm font-medium text-gray-700">
                                            Measurement is before &nbsp;
                                            <ErrorMessage name="notbefore" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <select id="notbefore" name="notbefore" defaultValue={values.notbefore} onChange={handleChange} onBlur={handleBlur} className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm rounded-md">
                                                <option value="" />
                                                {hours.map(hour => (
                                                    minutes.map(minute => (
                                                        <option key={hour + ":" + minute} value={hour + ":" + minute}>{hour}:{minute} {(parseInt(hour, 10) >= 12) ? "PM" : "AM"}</option>
                                                    ))
                                                ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-5 px-4 sm:px-6">
                            <div className="flex justify-end">
                                <button onClick={handleCancel} type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Cancel</button>
                                <button type="submit" disabled={isSubmitting || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Save</button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}