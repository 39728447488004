import React, { useState } from 'react';
import { getIdToken } from "firebase/auth";
import { useAuth } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { ErrorBanner } from '../Components/Helpers/index.js';
import { collection, query, where, doc, getDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions';
import FileSaver from "file-saver";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import CalibrationsActionMenu from '../Dashboard/CalibrationsActionMenu.js';
import ModalLoadingActivity from '../Components/ModalLoadingActivity.js';
import ReassignSuccessDialog from './ReassignSuccessDialog.js';
import RenameSuccessDialog from './RenameSuccessDialog.js';
import ReassignClientDialog from './reassignClientDialog.js';
import RenameCertificateDialog from './RenameCertificateDialog.js';

export default function CalibrationsByDeviceSerial(props) {
    // STATE
    // eslint-disable-next-line
    const [isDownloading, setIsDownloading] = useState(false);
    const [reassignClientDialogOpen, setReassignClientDialogOpen] = useState(false);
    const [renameCertificateDialogOpen, setRenameCertificateDialogOpen] = useState(false);
    const [modalDialogOpen, setModalDialogOpen] = useState(false);
    const [selectedCalibration, setSelectedCalibration] = useState(undefined);
    const [selectedCalibrationCertificateName, setSelectedCalibrationCertificateName] = useState(undefined);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState(null);
    const [reassignSuccessDialogOpen, setReassignSuccessDialogOpen] = useState(false);
    const [renameSuccessDialogOpen, setRenameSuccessDialogOpen] = useState(false);

    // HOOKS
    const navigate = useNavigate();
    const firestore = useFirestore();
    const auth = useAuth();
    
    // FIREBASE
    const calibrationCollection = collection(firestore, `calibrations`);
    const activeQuery = query(calibrationCollection, where('serialidx', 'array-contains-any', [props.query, props.query.toUpperCase()]));
    const { status, data: calevents } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return 'Loading...';
    }

    // CERTIFICATE DOWNLOAD

    const servePdf = async (e, certificateGuid) => {
        e.stopPropagation();

        setIsDownloading(true);
        const docRef = doc(firestore, `certificates`, certificateGuid);
        const docSnap = await getDoc(docRef);

        // existence check
        if (!docSnap.exists()) {
            window.alert("Not exists");
        }

        // certificate data should be truthy from here
        const certificate = docSnap.data();

        // compose filename overloaded with fields from the record:
        var friendlyCompanyName = friendlyCompanyForFilename(certificate.certificateCustomerName);
        var friendlyCertificateNo = friendlySerialForFilename(certificate.serial);
        
        var friendlySerialNo = friendlySerialForFilename(certificate.serialIdx.length > 1 ? `${certificate.serialIdx[0]}_${certificate.serialIdx[1]}` : certificate.serialIdx[0]);
        
        var friendlyFileName = `Certificate_${friendlyCompanyName}_${friendlySerialNo}_${friendlyCertificateNo}.pdf`;

        const storage = getStorage();

        getDownloadURL(ref(storage, `certificates/${certificateGuid}.pdf`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    setIsDownloading(false);
                    FileSaver.saveAs(blob, friendlyFileName);
                };
                xhr.onerror = (error) => {
                    setIsDownloading(false);
                    window.alert("Error: could not download file");
                }
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                setIsDownloading(false);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        window.alert("Error: object not found");
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        window.alert("Error: unauthorized");
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        window.alert("Error: request cancelled");
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        window.alert("Unknown error occurred");
                        break;
                    default:
                        window.alert("Unknown error occurred");
                        break;
                }
            });

        setIsDownloading(false);
    }

    // HELPERS

    const friendlyCompanyForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Company";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "_") {
                retval += "_";
            }
        }

        // remove leading/trailing underscores
        retval = trim(retval, "_");

        // return something if end up with nothing
        if (!retval) {
            return "Company";
        }

        return retval;
    }

    const friendlySerialForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Number";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "-") {
                retval += "-";
            }
        }

        // remove leading/trailing dashes
        retval = trim(retval, "-");

        // return something if end up with nothing
        if (!retval) {
            return "Number";
        }

        return retval;
    }

    const trim = (str, ch) => {
        var start = 0,
            end = str.length;

        while (start < end && str[start] === ch)
            ++start;

        while (end > start && str[end - 1] === ch)
            --end;

        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    function showErrorBanner(message) {
        setErrorMessageText(message);
        setErrorBannerOpen(true);
    }

    function formatLedStyle(n) {
        switch (n) {
            case "0":
                return "PASS";
            case "1":
                return "WARN";
            case "2":
                return "FAIL";
            case "3":
                return "WARN/FAIL";
            default:
                return "UNKNOWN";
        }
    }

    const gotoJobcard = (e, calibrationData) => {
        e.stopPropagation();

        // validate
        let docIdRegex = /^[A-Za-z0-9]{10,30}$/;
        if (!calibrationData || !calibrationData.jobcardGuid || !docIdRegex.test(calibrationData.jobcardGuid)) {
            window.alert("Cannot view job card: identifier is not set.");
            return;
        }


        // outta here
        navigate(`/calibrations/jobcard/${calibrationData.jobcardGuid}`);
    }

    const showRenameCertificate = (e, calibrationData) => {
        if (calibrationData) {
            setSelectedCalibration(calibrationData);
            setSelectedCalibrationCertificateName(calibrationData.certificateCustomerName);
            setRenameCertificateDialogOpen(true);
        } else {
            window.alert("Error in showRenameClient() cannot rename.");
        }
    }

    const showReassignClient = (e, calibrationData) => {
        e.stopPropagation();

        if (calibrationData) {
            setSelectedCalibration(calibrationData);
            setReassignClientDialogOpen(true);
        }
    }

    const renameCertificateHandler = async (result) => {
        setRenameCertificateDialogOpen(false);

        // cancel was pressed, do nothing
        if (!result) {
            setSelectedCalibration(undefined);
            setSelectedCalibrationCertificateName(undefined);
            return;
        }

        // validate selected calibration is not bust
        if (!selectedCalibration) {
            window.alert("Error: no selected calibration");
            return;
        }

        // Merge in other required fields
        result.authUserName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        result.calibrationGuid = selectedCalibration.id;
        result.jobcardGuid = selectedCalibration.jobcardGuid;
        result.certificateGuid = selectedCalibration.certificateGuid;

        // Debug: window.alert(JSON.stringify(result)); return;
        // {"reasonNote":"yyy","customerNameCertificate":"Verbia Systems xxx","authUserName":"Michael Schwinges","calibrationGuid":"MTSz2xeCiWEtrR1syDjr","jobcardGuid":"OHxI4jXwepRbt7Eyb9Yu","certificateGuid":"N9u82g2Cozwp7f5ABL8F"}
        
        // FORM REQUIRES:
        //    - reasonNote ✓
        //    - customerNameCertificate ✓
        //    - authUserName ✓
        //    - calibrationGuid ✓
        //    - jobcardGuid ✓
        //    - certificateGuid ✓

        // show progress spinner
        setModalDialogOpen(true);
        
        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/cal/amend', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(result)
                })
                    .catch((err) => {
                        setModalDialogOpen(false);
                        showErrorBanner("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        setModalDialogOpen(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            showRenameSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setModalDialogOpen(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    const reassignClientHandler = async (result) => {
        setReassignClientDialogOpen(false);

        // cancel was pressed, do nothing
        if (!result) {
            setSelectedCalibration(undefined);
            return;
        }

        // validate selected calibration is not bust
        if (!selectedCalibration) {
            window.alert("Error: no selected calibration");
            return;
        }

        // validate not assigning to the same client
        if (selectedCalibration.clientGuid === result.customerGuid) {
            showErrorBanner("Cannot assign to the same client");
            return;
        }

        // show progress spinner
        setModalDialogOpen(true);

        // Merge in other required fields
        result.authUserName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        result.calibrationGuid = selectedCalibration.id;
        result.jobcardGuid = selectedCalibration.jobcardGuid;
        result.deviceGuid = selectedCalibration.deviceGuid;

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/cal/reassign', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(result)
                })
                    .catch((err) => {
                        setModalDialogOpen(false);
                        showErrorBanner("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        setModalDialogOpen(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            setModalDialogOpen(false);
                            showReasignSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setModalDialogOpen(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    // Customer autocomplete returns:
    // - "customerGuid": "CAwLyQf9fugGwsEpetm5",
    // - "customerName": "Verbia Systems",
    // - "certificateCustomerName": "Verbia Systems"

    // reassignClientHandler receives as result:
    // {
    //     "customerGuid": "CAwLyQf9fugGwsEpetm5",
    //     "customerName": "Verbia Systems",
    //     "reasonNote": "xXx",
    //     "customerNameCertificate": "Verbia Systems Pty Ltd",
    //     "branchId": 5,
    //     "accountManager": {
    //         "id": "dis49O1br6OCNjxWL4vkWR8AokA2",
    //         "displayName": "Nikki Berry"
    //     }
    // }

    const showReasignSuccessDialog = () => {
        setReassignSuccessDialogOpen(true);
    }

    const closeReasignSuccessDialog = () => {
        setReassignSuccessDialogOpen(false);
    }

    const showRenameSuccessDialog = () => {
        setRenameSuccessDialogOpen(true);
    }

    const closeRenameSuccessDialog = () => {
        setRenameSuccessDialogOpen(false);
    }

    return (
        <div>
            {reassignClientDialogOpen && <ReassignClientDialog isOpen={reassignClientDialogOpen} handler={reassignClientHandler} userinfo={props.userinfo} />}
            {renameCertificateDialogOpen && <RenameCertificateDialog isOpen={renameCertificateDialogOpen} handler={renameCertificateHandler} selectedCalibrationCertificateName={selectedCalibrationCertificateName} />}
            {modalDialogOpen && <ModalLoadingActivity isOpen={modalDialogOpen} message="Please wait while device is reassigned" />}
            {errorBannerOpen && <ErrorBanner message={errorMessageText} />}
            {reassignSuccessDialogOpen && <ReassignSuccessDialog open={reassignSuccessDialogOpen} onChange={closeReasignSuccessDialog} /> }
            {renameSuccessDialogOpen && <RenameSuccessDialog open={renameSuccessDialogOpen} onChange={closeRenameSuccessDialog} /> }

            {calevents.size > 0 && <div>
                <div className="mt-6 font-bold text-gray-900">The following calibrations were found for <span className='italic'>{props.query}</span> :</div>

                <table className="mt-6 min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr key='headerrow'>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date, model</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Measurements</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pre zero</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pre alcohol</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sensor condition</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Post zero</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Post alcohol</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calevents.docs.map((calibrationItem, itemIdx) => (
                            <React.Fragment key={`F_${itemIdx}`}>
                                <tr key={`A_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                    <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">{formatDate(calibrationItem.data().dts.toDate())}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().readingscount}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().precalzero && calibrationItem.data().unitScale && `${calibrationItem.data().precalzero} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().precalzero && formatLedStyle(calibrationItem.data().precalzero)}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().precalalcohol && calibrationItem.data().unitScale && `${calibrationItem.data().precalalcohol} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().precalalcohol && formatLedStyle(calibrationItem.data().precalalcohol)}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().calvalue}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().postcalzero && calibrationItem.data().unitScale && `${calibrationItem.data().postcalzero} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().postcalzero && formatLedStyle(calibrationItem.data().postcalzero)}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().postcalreading && calibrationItem.data().unitScale && `${calibrationItem.data().postcalreading} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().postcalreading && formatLedStyle(calibrationItem.data().postcalreading)}
                                    </td>
                                    <td rowSpan={2}>
                                        {/* 
                                            - Reassign client (if not generated)
                                            - Download certificate (if generated)
                                            - Edit certificate 'issued to' name (if generated) => • regenerate certificate
                                        */}
                                        {<CalibrationsActionMenu calibrationData={Object.assign(calibrationItem.data(), { id: calibrationItem.id })} reassignHandler={showReassignClient} downloadHandler={servePdf} renameHandler={showRenameCertificate} jobcardHandler={gotoJobcard} />}
                                    </td>
                                </tr>
                                <tr key={`B_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                    <td className="px-6 pb-2 whitespace-nowrap text-sm text-gray-900">
                                        <span className='font-bold'>{calibrationItem.data().modelName}</span>
                                    </td>
                                    <td colSpan={6} className="px-6 pb-2 whitespace-nowrap text-sm text-gray-900">
                                        {calibrationItem.data().authUserName && <span>Performed by {calibrationItem.data().authUserName}. </span>}
                                        {calibrationItem.data().technicianNote && calibrationItem.data().technicianNote}
                                        {!calibrationItem.data().technicianNote && <span>No note.</span>}

                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>}

            {calevents.size === 0 && <ErrorBanner message='No devices match your serial number search.' />}
        </div>
    )
}