import React, { useState } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { useAuth } from 'reactfire';
import * as Yup from "yup"
import { Formik, ErrorMessage } from 'formik';

export default function PasswordTab(props) {
    // hooks
    const auth = useAuth();

    // state
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const [formLoading, setFormLoading] = useState(false);

    // formik
    const changePasswordFormInitialValues = { passwordCurrent: '', passwordNew: '', passwordNewConfirm: '' };
    const changePasswordFormValidationSchema = Yup.object({
        passwordCurrent: Yup.string("Enter your current password").required("Required"),
        passwordNew: Yup.string("Enter your new password").required("Required"),
        passwordNewConfirm: Yup.string("Enter your new password again").required("Required").oneOf([Yup.ref('passwordNew'), null], 'Passwords must match')
    });

    // functions

    function closeConfirm() {
        setConfirmationOpen(false);
    }

    function startLoading() {
        setFormLoading(true);
    }

    function stopLoading() {
        setFormLoading(false);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    function handleSubmit(form) {
        closeErrorBanner();
        startLoading();

        const email = auth.currentUser.email;
        const credential = EmailAuthProvider.credential(email, form.passwordCurrent);

        reauthenticateWithCredential(auth.currentUser, credential)
            .then(() => {
                updatePassword(auth.currentUser, form.passwordNew)
                    .then(() => {
                        stopLoading();
                        setConfirmationOpen(true);
                    })
                    .catch((errorInner) => {
                        stopLoading();
                        setLastErrorMessage(`An unexpected error occurred (${errorInner.code}).`);
                        setErrorBannerOpen(true);
                    });
            })
            .catch((errorOuter) => {
                switch (errorOuter.code) {
                    case 'auth/wrong-password':
                        setLastErrorMessage('The current password entered is incorrect.');
                        break;
                    case 'auth/too-many-requests':
                        setLastErrorMessage('Too many requests; please try again later.');
                        break;
                    default:
                        setLastErrorMessage(`An unexpected error occurred (${errorOuter.code}).`);
                        break;
                }

                stopLoading();
                setErrorBannerOpen(true);
            });
    }

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Change password</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Use the form below to reset your password.</p>
            </div>

            {/* Modal confirmation dialog */}
            {confirmationOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/*  This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Heroicon name: outline/check */}
                                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Your password was changed successfully.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button onClick={closeConfirm} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:text-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            <div className="border-t border-gray-200 pt-6 pb-8 px-8">

                {formLoading && <img src="/images/loading.gif" width="40" height="40" alt="" className="mb-4" />}

                {/* Error banner */}
                {errorBannerOpen && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-yellow-700">{lastErrorMessage}</p>
                        </div>
                    </div>
                </div>
                }


                <Formik initialValues={changePasswordFormInitialValues} onSubmit={handleSubmit} validationSchema={changePasswordFormValidationSchema} >
                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values, }) => (
                        <form onSubmit={handleSubmit} className="divide-y divide-gray-200">
                            <div className="divide-y divide-gray-200">
                                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="passwordCurrent" className="block text-sm font-medium text-gray-700">
                                            Current password &nbsp;
                                            <ErrorMessage name="passwordCurrent" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <input id="passwordCurrent" name="passwordCurrent" type="password" value={values.passwordCurrent} onChange={handleChange} onBlur={handleBlur} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3" />

                                    <div className="sm:col-span-3">
                                        <label htmlFor="passwordNew" className="block text-sm font-medium text-gray-700">
                                            New password &nbsp;
                                            <ErrorMessage name="passwordNew" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <input type="password" name="passwordNew" id="passwordNew" value={values.passwordNew} onChange={handleChange} onBlur={handleBlur} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="passwordNewConfirm" className="block text-sm font-medium text-gray-700">
                                            Confirm new password &nbsp;
                                            <ErrorMessage name="passwordNewConfirm" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <input type="password" name="passwordNewConfirm" id="passwordNewConfirm" value={values.passwordNewConfirm} onChange={handleChange} onBlur={handleBlur} autoComplete="family-name" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Change password
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}