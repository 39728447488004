import React from 'react';
import { Link } from 'react-router-dom';

function Header(props) {
    return (
        <div className="p-8 bg-isober-900">
            <div>
            <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link to="/" className="text-gray-400 hover:text-gray-500">
                            <Link to="/" className="text-sm font-medium text-gray-200 hover:text-gray-100">Home</Link>
                        </Link>
                    </div>
                </li>
                </ol>
            </nav>
            </div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
                {props.title}
                </h2>
            </div>
            </div>
        </div>
    )
}

export default Header;