import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

export function useStickyCrm(userGuid, deviceGuid) {
    // HOOKS
    const firestore = useFirestore();

    // VALIDATE
    const userGuidChecked = (userGuid) ? userGuid : 'FAILME';
    const deviceGuidChecked = (deviceGuid) ? deviceGuid : 'FAILME';

    // FIRESTORE
    const documentRef = doc(firestore, `/userinfo/${userGuidChecked}/devicemodels`, deviceGuidChecked);
    const { data: userDeviceRecord } = useFirestoreDocData(documentRef);

    if (userDeviceRecord && userDeviceRecord.lastcrm) {
        console.log(JSON.stringify(userDeviceRecord));
        return userDeviceRecord.lastcrm;
    }

    return undefined;
}
