import React, { useState } from 'react';
import SignatureCanvas from './signcanvas.js';

// passes the base64 data upwards by calling onSignatureReceived

export default function TabletSignatureCanvas(props) {
    const [signatureImage, setSignatureImage] = useState(undefined);
    const [signpadOpen, setSignpadOpen] = useState(false);

    function showPopup() {
        setSignpadOpen(true);
    }

    function hidePopup() {
        setSignpadOpen(false);
    }

    // LIFTING STATE
    
    function switchSignPen() {
        props.onSignatureMethodChange(true);
    }

    function handleImage(img) {
        if (img !== undefined) {
            setSignatureImage(img);
            props.onSignatureReceived(img);
        }
    }

    return (
        <div>
            {signpadOpen && <SignatureCanvas open={signpadOpen} closehandler={hidePopup} imagehandler={handleImage} /> }

            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white px-4 space-y-6 sm:p-4">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Tablet Signature</h3>
                        <p className="mt-1 text-sm text-gray-500">Use the Topaz signature pad to accept the information above as correct.</p>
                    </div>

                    <div className="flex flex-row">
                        <div className="border-2 border-gray-300 rounded-md w-[24rem] h-[11rem]">
                            <div className="w-[24rem] h-[11rem]">
                                {signatureImage !== undefined &&
                                    <img src={signatureImage} className='mt-1' alt="Signature" style={{ width: '23rem', height: '10rem' }} />
                                } </div>
                        </div>
                        <div className=""> {/* wrap button in div or becomes part of flex on height */}

                            <button onClick={showPopup} type="button" className="ml-5 bg-isober-50 text-isober-900 py-2 px-3 border border-isober-50 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-isober-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                Sign...
                            </button>
                            <div className="mt-4">
                                <button onClick={switchSignPen} type="button" className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Switch to Topaz signature
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}