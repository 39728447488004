import React, { useState } from 'react';
import PageHeader from './PageHeader';
import { useLocation } from 'react-router-dom';
import LoadingActivity from './LoadingActivity.js';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import * as XLSX from "xlsx"; // https://docs.sheetjs.com/docs/demos/frontend/react/

export default function ReportsPage() {
    // STATE
    const [loading, setLoading] = useState(false);
    const [reportResultset, setReportResultset] = useState(undefined);

    // HOOKS
    const firestore = useFirestore();
    const currentLocation = useLocation();

    const getReportData = async () => {
        setReportResultset(undefined); // reset
        setLoading(true);

        // configure query
        try {
            const collectionPath = `stock`;
            const stockCollection = collection(firestore, collectionPath);
            const collectionQuery = query(stockCollection, where('enabled', '==', true), orderBy('enabled', 'desc'), orderBy('category', 'asc'));
            const querySnapshot = await getDocs(collectionQuery);
            setReportResultset(querySnapshot);

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }


    }

    // Sort Object.keys :
    // See https://bobbyhadz.com/blog/javascript-sort-object-keys

    const handleDownload = () => {
        // adjust firestore result set slightly for the spreadsheet
        var regularStockData = reportResultset.docs.map(function (sku) {
            var skuData = sku.data();
            var retval = {};

            // work with a copy of the original data so we can control order of keys therefore columns in excel
            retval.category = skuData.category;
            retval.sku = skuData.sku;

            // unwrap balance
            if (skuData.balance) {
                if (skuData.balance.jhb) {
                    retval.jhb = skuData.balance.jhb;
                } else {
                    retval.jhb = 0;
                }
                if (skuData.balance.ct) {
                    retval.ct = skuData.balance.ct;
                } else {
                    retval.ct = 0;
                }
                if (skuData.balance.dbn) {
                    retval.dbn = skuData.balance.dbn;
                } else {
                    retval.dbn = 0;
                }
                if (skuData.balance.bw) {
                    retval.bw = skuData.balance.bw;
                } else {
                    retval.bw = 0;
                }
                if (skuData.balance.cs) {
                    retval.cs = skuData.balance.cs;
                } else {
                    retval.cs = 0;
                }
            } else {
                retval.jhb = 0;
                retval.ct = 0;
                retval.dbn = 0;
                retval.bw = 0;
                retval.cs = 0;
            }

            return retval;
        });

        var demoStockData = reportResultset.docs.map(function (sku) {
            var skuData = sku.data();
            var retval = {};

            // work with a copy of the original data so we can control order of keys therefore columns in excel
            retval.category = skuData.category;
            retval.sku = skuData.sku;

            // unwrap balance
            if (skuData.demobalance) {
                if (skuData.demobalance.jhb) {
                    retval.jhb = skuData.demobalance.jhb;
                } else {
                    retval.jhb = 0;
                }
                if (skuData.demobalance.ct) {
                    retval.ct = skuData.demobalance.ct;
                } else {
                    retval.ct = 0;
                }
                if (skuData.demobalance.dbn) {
                    retval.dbn = skuData.demobalance.dbn;
                } else {
                    retval.dbn = 0;
                }
                if (skuData.demobalance.bw) {
                    retval.bw = skuData.demobalance.bw;
                } else {
                    retval.bw = 0;
                }
                if (skuData.demobalance.cs) {
                    retval.cs = skuData.demobalance.cs;
                } else {
                    retval.cs = 0;
                }
            } else {
                retval.jhb = 0;
                retval.ct = 0;
                retval.dbn = 0;
                retval.bw = 0;
                retval.cs = 0;
            }

            return retval;
        });

        // create workbook and worksheet
        const workbook = XLSX.utils.book_new();

        const stockWorksheet = XLSX.utils.json_to_sheet(regularStockData);
        XLSX.utils.book_append_sheet(workbook, stockWorksheet, "Stock");

        const demoStockWorksheet = XLSX.utils.json_to_sheet(demoStockData);
        XLSX.utils.book_append_sheet(workbook, demoStockWorksheet, "Demo stock");

        // serve to browser
        XLSX.writeFile(workbook, "StockReport.xlsx", { compression: true });
    };

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                {loading && <LoadingActivity loading={loading} />}

                <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                    <div>
                        {/* <p className="mt-2 max-w-4xl text-sm text-gray-500">The following SKUs are loaded on the Stock Control system.</p> */}
                    </div>
                    <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    </div>
                </div>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="grid grid-cols-2">
                        <div className="mt-5 flex">
                            {!reportResultset && <button type="button" onClick={() => getReportData()} disabled={loading} className="disabled:opacity-50 mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                Generate report
                            </button>}

                            {reportResultset && <div>
                                <button type="button" onClick={() => handleDownload()} disabled={loading} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Download Excel
                                </button>
                            </div>}
                        </div>
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}
