import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MenuFragment from './menu.js';
import LogsPage from './LogsPage.js';
// import { useLocation } from 'react-router-dom';
// import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import UsersListPage from './userslistfragment.js';
import UserDetailsPage from './userdetailspage.js';
import EditUserPage from './edituserpage.js';
import CreateUserPage from './createuserpage.js';


export default function AdminPage(props) {
    // const currentLocation = useLocation();

    return (
        <Routes>
            <Route path="*" element={<MenuFragment />} />
            <Route path="logs/*" element={<LogsPage />} />
            <Route path="users/*" element={<UsersListPage userinfo={props.userinfo} />} />
            <Route path="users/user/:userId" element={<UserDetailsPage userinfo={props.userinfo} />} />
            <Route path="users/edit/:userId" element={<EditUserPage />} />
            <Route path="users/create" element={<CreateUserPage userinfo={props.userinfo} />} />
        </Routes>

        // <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        //     <div className="py-6 px-6">
        //         <PageHeaderAlt location={currentLocation.pathname} />
        //         <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        //             {/* Replace with your content */}
        //             <div className="py-4">
        //                 <Routes>
        //                     <Route path="*" element={<MenuFragment />} />
        //                     <Route path="logs/*" element={<LogsPage />} />
        //                     <Route path="users/*" element={<UsersListPage />} />
        //                     <Route path="users/user/:userId" element={<UserDetailsPage userinfo={props.userinfo} />} />
        //                     <Route path="users/edit/:userId" element={<EditUserPage />} />
        //                     <Route path="users/create" element={<CreateUserPage userinfo={props.userinfo} />} />
        //                 </Routes>
        //             </div>
        //             {/* /End replace */}
        //         </div>
        //     </div>
        // </main>
    )
}