import React from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { Link } from 'react-router-dom';
import { useStorage } from 'reactfire';

export default function ClientCard(props) {
    const client = props.client;
    const storage = useStorage();
    const logoRef = ref(storage, `logos/${client.logo}`);

    if (client.logo !== undefined && client.logo !== null) {
        getDownloadURL(logoRef)
        .then((url) => {
            const img = document.getElementById(`logo_${client.id}`);
            img.setAttribute('src', url);
        })
        .catch((error) => {
            // console.log("[ClientCard] " + error);
        })
    }

    return (
        <li key={client.id} className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div className="flex-1 flex flex-col p-8">
                <img className="w-20 flex-shrink-0 mx-auto bg-white" src="/images/logoplaceholder.png" alt="" id={`logo_${client.id}`} />
                <p>{/*status*/}</p>
                { /* status === 'loading' && <span>Loading...</span> */}
                { /* status !== 'loading' && <img className="w-20 flex-shrink-0 mx-auto bg-black" src={imageURL} alt="" /> */}
                <h3 className="mt-6 text-gray-900 text-sm font-medium">{client.name}</h3>
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="-ml-px w-0 flex-1 flex">
                        <Link to={`/system/clients/${client.id}`} className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-isober-900 font-medium border border-transparent rounded-br-lg hover:text-isober-1100">
                            <span className="ml-3">Manage</span>
                        </Link>
                    </div>
                </div>
            </div>
        </li>
    )
}