import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure, useHits } from 'react-instantsearch-hooks-web';

export default function CustomersPage(props) {
    // HOOKS
    const currentLocation = useLocation();

    const searchClient = algoliasearch('3SEANAS33X', '8ff6c7ff57ebf9447a56d076888484dd');

    function CustomerHits(props) {
        const { hits } = useHits(props);

        return (
            <>
                {hits.length > 0 && <div>
                    <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        {hits.map(customer => (
                            <li key={customer.name} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex items-center space-x-3">
                                            <h3 className="text-gray-900 text-sm font-medium truncate">{customer.name}</h3>
                                        </div>
                                        <p className="mt-1 text-gray-500 text-sm truncate">
                                            {customer.address1 !== undefined && customer.address1}{!customer.address1 && '—'}<br />
                                            {customer.address2 !== undefined && customer.address2}{!customer.address2 && '—'}<br />
                                            {(customer.city === undefined && customer.post === undefined) && '—'}
                                            {customer.city !== undefined && <span>{customer.city} &nbsp;</span>}
                                            {customer.postcode !== undefined && customer.postcode}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                        <div className="w-0 flex-1 flex">
                                            <Link to={`/calibrations/admin/clients/${customer.objectID}`} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                                                <button className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                    Details
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                }
            </>
        );
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The following customers are loaded on the system.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            <Link to="/calibrations/admin/clients/create">
                                <button type="button" className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Add new
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <InstantSearch searchClient={searchClient} indexName="clientNameIndex">
                    <Configure hitsPerPage={9} />
                    {/* Alolia search bar */}
                    <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Search calibrations by customer name</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter a customer name to search.</p>
                        </div>

                        <div className="px-6 pb-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <label htmlFor="query" className="block text-sm font-medium text-gray-700">Customer name</label>
                                    <SearchBox classNames={{ input: "ocus:ring-isober-500 focus:border-isober-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300" }}
                                        resetIconComponent={({ classNames }) => (<></>)} submitIconComponent={({ classNames }) => (<></>)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <CustomerHits {...props} />
                </InstantSearch>
            </div>
        </main>
    )
}