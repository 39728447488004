import { collection, query, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export default function usePaginatedLogEntries(rpp, startItem, include) {
    const firestore = useFirestore();
    const logsCollection = collection(firestore, 'adminlog');
    
    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(logsCollection, orderBy('dts', 'desc'), limit(rpp));
    } else {
        activeQuery = (include) ? query(logsCollection, orderBy('dts', 'desc'), limit(rpp), startAt(startItem)) : query(logsCollection, orderBy('dts', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: entries } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return entries;
}