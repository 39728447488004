import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';

export default function MenuFragment(props) {
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">Choose an action from the list below.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            {/*<button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Service&nbsp;history </button>*/}
                        </div>
                    </div>

                    <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        <Link to="/calibrations/admin/devices">
                            <li className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex justify-center space-x-3">
                                            <span className="text-white text-md font-medium truncate">Device models</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </Link>

                        <Link to="/calibrations/admin/crm">
                            <li className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex justify-center space-x-3">
                                            <span className="text-white text-md font-medium truncate">Certified reference material</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </Link>

                        <Link to="/calibrations/admin/lost">
                            <li className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex justify-center space-x-3">
                                            <span className="text-white text-md font-medium truncate">Lost &amp; stolen units</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </Link>

                        <Link to="/calibrations/admin/clients">
                            <li className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex justify-center space-x-3">
                                            <span className="text-white text-md font-medium truncate">Customer directory</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </Link>

                        <Link to="/calibrations/admin/logviewer">
                            <li className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex justify-center space-x-3">
                                            <span className="text-white text-md font-medium truncate">System logs</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </Link>
                    </ul>
                    {/* /End replace */}
                </div>
            </div>
        </main>

    )
}