import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

// hook to get a map of usernames indexed by id
// via: import useClientUsers from '../Components/UserInfo/clientusers.js';
//      const clientUsers = useClientUsers(clientId);

export default function useClientUsers(clientGuid) {
    // console.log("[useClientUsers] for " + clientGuid);

    // set up firestore query
    const firestore = useFirestore();
    const userinfoCollection = collection(firestore, 'userinfo');
    const userinfoQuery = query(userinfoCollection, where('clientid', '==', clientGuid));
    const { status, data: clientUsers } = useFirestoreCollectionData(userinfoQuery, {
        idField: 'id', // merge id into object created for each document
    });

    if (status === 'loading') {
        // console.log("[useClientUsers] loading ");
        return undefined;
    }

    const retval = new Map();

    clientUsers.forEach(userinfo => {
        retval.set(userinfo.id, `${userinfo.fname} ${userinfo.lname}`);
    });

    // console.log("[useClientUsers] returning " + retval.size);

    return retval;
}