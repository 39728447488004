import React, { useState } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation } from 'react-router-dom';
import ConfirmRemoveLostDevice from './confirmremovelost.js';
import { collection, query, doc, updateDoc, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { LoadingBanner } from '../Components/Helpers/index.js';

export default function LostDevicesPage(props) {
    // FIRESTORE
    const firestore = useFirestore();
    const lostDevicesCollection = collection(firestore, `loststolenunits`);
    const activeQuery = query(lostDevicesCollection, where('deleted', '==', false));
    const { status, data: lostdevices } = useFirestoreCollection(activeQuery);

    // HOOKS
    const currentLocation = useLocation();

    // STATE
    const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false)
    const [guidToRemove, setGuidToRemove] = useState(null)
    const [formLoading, setFormLoading] = useState(false);

    // FUNCTIONS

    function confirmDeactivate(guid) {
        console.log(`confirmDeactivate(${guid})`);
        setGuidToRemove(guid);
        setConfirmRemoveOpen(true);
    }

    async function deactivate(docId) {
        setFormLoading(true);

        const documentRef = doc(firestore, 'loststolenunits', docId);
        await updateDoc(documentRef, {
            deleted: true
        }).then(() => {
            console.log("Update complete");
        });

        setFormLoading(false);
        setGuidToRemove(null); // clear flag
    }

    // LIFTING STATE

    const handleConfirmChange = (newVal) => {
        if (newVal === true && guidToRemove !== undefined) {
            deactivate(guidToRemove);
            setConfirmRemoveOpen(false);
        } else {
            setGuidToRemove(null); // clear flag
            setConfirmRemoveOpen(false);
        }
    }

    // LOADING

    if (status === 'loading') {
        return undefined;
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <ConfirmRemoveLostDevice open={confirmRemoveOpen} onConfirmDeactivateOpenChange={handleConfirmChange} />

            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The following devices will be flagged as lost or stolen during receipt and calibration.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            <Link to="/calibrations/admin/lost/create">
                                <button type="button" className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Add new
                                </button>
                            </Link>
                        </div>
                    </div>

                    {formLoading === true && <LoadingBanner />}

                    {lostdevices.size === 0 && <div className="mt-5 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">No devices are currently marked as lost or stolen.</p>
                            </div>
                        </div>
                    </div>
                    }

                    {lostdevices.size > 0 && <table className="mt-6 min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sensor serial</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit serial</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lostdevices.docs.map((device, deviceIdx) => (
                                <tr key={device.id} className={deviceIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{device.data().sensorserial}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{device.data().deviceserial}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{device.data().notes}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <div className="flex-shrink-0">
                                            <button onClick={() => confirmDeactivate(device.id)} className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                Remove
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    }
                </div>
            </div>
        </main>
    )
}
/*
Alternate row highlighting:
className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
*/