import React from 'react';
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
    {
        id: 1,
        question: "I discovered a defect",
        answer: "Please, report all defects and feature requests to us at info@breathalysers.co.za."
    },
    // More questions...
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function FaqsTab(props) {

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Frequently Asked Questions</h3>
            </div>
            <div className="border-t border-gray-200">
                <dl className="px-8 pt-0 pb-8 mt-2 space-y-6 divide-y divide-gray-200">
                    {faqs.map((faq) => (
                        <Disclosure as="div" className="pt-6" key={`d-${faq.id}`}>
                            {({ open }) => (
                                <>
                                    <dt className="text-base" key={`dt-${faq.id}`}>
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                            <span className="font-normal text-gray-900">{faq.question}</span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <ChevronDownIcon
                                                    className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500">{faq.answer}</p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </dl>
            </div>
        </div>
    )
}