import { useEffect, useState } from 'react';
import { formatDate } from '../../Shared/Components/functions.js'
import useClientUsers from '../Components/UserInfo/clientusers.js';
import usePaginatedLogEntries from './paginatedLogEntries.js';

export default function LogsList(props) {

    // State
    const resultsPerPage = 10;
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedClientReadings()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    // get current user's userinfo record from its context
    const userinfoData = props.userinfo;

    // Hooks
    const clientUsers = useClientUsers(userinfoData.clientid); // get map of users in the account to map reading userids to their names

    // set up firestore query
    const entries = usePaginatedLogEntries(userinfoData.clientid, resultsPerPage, referenceItem, include);
    const currentLastVisibleItem = (entries !== undefined) ? entries.docs[entries.size - 1] : null;

    // Functions

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, entries.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (entries !== undefined && entries.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (entries !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, entries]);

    return (
        <div className="flex flex-col">
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 text-sm text-gray-500">Recent activty in your organisation appears below.</p>
                </div>
            </div>

            <div className="mt-5 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entries !== undefined && entries.docs.map((entry, entryIdx) => (
                                    <tr key={entryIdx} className={entryIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(entry.data().dts.toDate())}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {clientUsers.has(entry.data().userid) === true && clientUsers.get(entry.data().userid)}
                                            {clientUsers.has(entry.data().userid) !== true && "No name"}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{entry.data().message}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{entry.data().result}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* pagination start */}
            {entries.docs.length > 0 && /* pagination start */
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }
            {/* end pagination */}
        </div>
    )
}