import React from 'react';

export default function IosTab(props) {

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">iOS Subscriptions</h3>
                {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Subtitle</p>*/}
            </div>
            <div className="border-t border-gray-200">
                <div className="py-8 px-8">
                    <p>Welcome to the subscriptions page.</p>
                </div>
            </div>
        </div>
    )
}