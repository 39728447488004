import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import AlertsList from './alertslist.js';
import AlertDetails from './alertdetails.js';
import CreateAlert from './createalert.js';
import EditAlert from './editalert.js';
import AlertsByUser from './useralerts.js';
import useClientUsers from '../Components/UserInfo/clientusers.js';

function AlertsPage(props) {
    const userinfoData = props.userinfo;
    const currentLocation = useLocation();
    const clientUsers = useClientUsers(userinfoData.clientid); // map of users in the account
    // console.log("[AlertsPage] clientUsers has " + clientUsers.size + " items");

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        <Routes>
                            <Route path="*" element={<AlertsList userinfo={userinfoData} clientUsers={clientUsers} />} />
                            <Route path="create" element={<CreateAlert userinfo={userinfoData} clientUsers={clientUsers} />} />
                            <Route path="edit/:alertId" element={<EditAlert userinfo={userinfoData} clientUsers={clientUsers} />} />
                            <Route path=":alertId" element={<AlertDetails userinfo={userinfoData} clientUsers={clientUsers} />} />
                            <Route path="user/:userId" element={<AlertsByUser userinfo={userinfoData} />} />
                        </Routes>
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}

export default AlertsPage;
