import useSubscriptionHistory from "./useSubscriptionHistory";
import { useParams, useNavigate } from 'react-router-dom';

export default function SubscriptionHistoryFragment(props) {
    const navigate = useNavigate();
    const { subscriptionId } = useParams();
    const entries = useSubscriptionHistory(subscriptionId);

    function handleBack() {
        navigate(-1);
    }

    if (entries === undefined) {
        return undefined;
    }

    /* const formatCancelReason = (r) => {
        switch (r) {
            case 0:
                return "Other";
            case 1:
                return "I don't use this service enough";
            case 2:
                return "Technical issues";
            case 3:
                return "Cost-related reasons";
            case 4:
                return "I found a better app";
            default:
                return "Unknown";
        }
    } */

    const formatPurchaseType = (r) => {
        switch (r) {
            case 0:
                return "Developer test";
            case 1:
                return "Promo code";
            default:
                return "Standard";
        }
    }

    const formatPaymentState = (r) => {
        switch (r) {
            case 0:
                return "Payment pending";
            case 1:
                return "Payment received";
            case 2:
                return "Free trial";
            case 3:
                return "Pending deferred upgrade/downgrade";
            default:
                return "-";
        }
    }

    const formatAutorenew = (q) => {
        if (q) {
            return "Yes";
        }
        return "No";
    }

    const formatPrice = (p) => {
        if (!isNaN(p)) {
            let q = p / 1000000;
            return q.toFixed(2);
        }

        return p;
    }

    const formatDate = (d) => {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear() + " " + strTime;
    }

    return (
        <div>
            <button type="button" onClick={handleBack} class="ml-1 mt-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-100 hover:bg-isober-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                </svg>
                &nbsp; Back
            </button>

            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription Event History</h3>
                    {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Subtitle</p>*/}
                </div>

                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Auto renew</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Purchase type</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment state</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entries.map(historyItem => (
                            <tr key={historyItem.uid}>
                                <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(historyItem.created.toDate())}</td>
                                <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{historyItem.lastStatus}</td>
                                <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{historyItem.priceCurrencyCode} {formatPrice(historyItem.priceAmountMicros)}</td>
                                <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatAutorenew(historyItem.autoRenewing)}</td>
                                <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatPurchaseType(historyItem.purchaseType)}</td>
                                <td component="th" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatPaymentState(historyItem.paymentState)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}