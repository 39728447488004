import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const unitOptions = [
    { name: '12 months' },
    { name: '6 months' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ValidityChooser({ handler, sfv}) {
    const [mem, setMem] = useState(unitOptions[0])

    // when selected changes, use callback to lift state up to parent
    React.useEffect(() => {
            handler(mem.name, sfv);
    }, [mem, handler, sfv])

    return (
        <RadioGroup value={mem} onChange={setMem} className="mt-1">
            <RadioGroup.Label className="sr-only">Choose a validity option</RadioGroup.Label>
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 pr-3">
                {unitOptions.map((option) => (
                    <RadioGroup.Option key={option.name} value={option}
                        className={({ active, checked }) =>
                            classNames(
                                active ? 'ring-2 ring-offset-2 ring-isober-500' : '',
                                checked
                                    ? 'bg-isober-600 border-transparent text-white hover:bg-isober-700'
                                    : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                'cursor-pointer focus:outline-none border rounded-md py-1 px-2 flex items-center justify-center text-sm font-medium sm:flex-1'
                            )
                        } >
                        <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}