import React, { useRef, useCallback, useEffect } from 'react';
import DeviceChooser from '../Components/DeviceChooser/AsDropdown.js';

export default function DeviceBulkCapture({ handler, noteHandler, sfv, sft }) {
    // REFS
    const serialRef = useRef();
    const sensorRef = useRef();
    const textAreaRef = useRef();
    const handleDeviceModelChange = useCallback((newVal, setFieldValue) => {
        setModel(newVal);

        // serial kind in deviceList is invalid, clear it
        textAreaRef.current.value = "";
        clearItems();
    }, []);

    // STATE
    const [deviceList, setDeviceList] = React.useState([]);
    const [model, setModel] = React.useState(undefined);
    const [useUnitSerial, setUseUnitSerial] = React.useState(true);
    const [useDeviceSerial, setUseDeviceSerial] = React.useState(false);

    useEffect(() => {
        handler(deviceList, sfv, sft);
    }, [deviceList, handler, sfv, sft]);

    // ACTIONS

    /*const insertItems = async () => {
        try {
            await formSchema.validate({ "serialNumber": serialRef.current.value, "sensorSerial": sensorRef.current.value, "modelName": model.modelName, "modelGuid": model.id });
        } catch (err) {
            window.alert("Please choose a model and enter a serial number");
            return;
        }

        // check we don't already have this model/serial number
        var isDuplicate = false;
        deviceList.forEach(item =>{
            if (serialRef.current.value.trim() && (item.modelGuid === model.id) && (item.serial.trim() === serialRef.current.value.trim() || item.serial.trim() === sensorRef.current.value.trim())) {
                isDuplicate = true;
            } else if (sensorRef.current.value.trim() && (item.modelGuid === model.id) && (item.serial.trim() === sensorRef.current.value.trim() || item.serial.trim() === sensorRef.current.value.trim())) {
                isDuplicate = true;
            }
        });

        if (isDuplicate) {
            window.alert('That serial number has already been captured below');
            return;
        }

        await setDeviceList([{ "modelGuid": model.id, "modelName": model.modelName, "serial": serialRef.current.value, "sensorSerial": sensorRef.current.value }, ...deviceList]);
        serialRef.current.value = null;
        sensorRef.current.value = null;
        serialRef.current.focus();

        // finally, pass the current set of devices/serials upwards
        // useEffect will ensure handler() is called when deviceList changes
        // per https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
    }*/

    const clearItems = async (key) => {
        await setDeviceList([]);

        // useEffect will ensure handler() is called when deviceList changes
        // per https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
    }

    const handleRadioChange = () => {
        // serial kind in deviceList is invalid, clear it
        clearItems();
        textAreaRef.current.value = "";

        setUseUnitSerial(!useUnitSerial);
        setUseDeviceSerial(!useDeviceSerial);
    }

    const handleTextAreaChange = (event) => {
        // global validation
        if (!model || !model.id || !model.modelName) {
            window.alert("Please choose a device model before continuing");

            // serial kind in deviceList is invalid, clear it
            event.target.value = "";
            clearItems();
            return;
        }

        // per item handling with checks for case and uniqueness
        var lines = event.target.value.split('\n');
        var devices = lines.reduce(function (result, lineItem) {
            const lineItemTrimmed = lineItem.trim();
            if (lineItemTrimmed && !result.some(e => { return (e.serial && e.serial.toUpperCase() === lineItemTrimmed.toUpperCase()) || (e.sensorSerial && e.sensorSerial.toUpperCase() === lineItemTrimmed.toUpperCase()) }) && isAlphaNumeric(lineItemTrimmed)) {
                var newRecord = {modelGuid: model.id, modelName: model.modelName };
                newRecord.ledStyle = (model.ledStyle !== null && model.ledStyle === true) ? true : false;
                if (useUnitSerial === true) {
                    newRecord.serial = lineItemTrimmed;
                } else {
                    newRecord.sensorSerial = lineItemTrimmed;
                }
                result.push(newRecord);
            }
            return result;
        }, []);

        setDeviceList(devices);
    }

    const isAlphaNumeric = (str) => {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false;
            }
        }
        return true;
    };

    return (
        <div className="shadow sm:rounded-md overflow-y-visible">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Device List</h3>
                    <p className="mt-1 text-sm text-gray-500">
                        Enter the devices being received below.
                        {deviceList && deviceList.length === 1 && <span className="text-sm font-medium text-blue-900">&nbsp; Adding 1 device</span>}
                        {deviceList && deviceList.length > 1 && <span className="text-sm font-medium text-blue-900">&nbsp; Adding {deviceList.length} devices</span>}
                    </p>
                </div>

                {/* start form */}
                <div className="grid grid-cols-10 gap-6">
                    <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                            Device model: &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <DeviceChooser handler={handleDeviceModelChange} sfv={undefined} />
                        </dd>
                    </div>

                    <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                            Serial number kind: &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                    <div className="mt-2 space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                        <div key="sn1" className="flex items-center">
                                            <input type="radio"
                                                ref={sensorRef}
                                                id="unitsn"
                                                value="unit"
                                                defaultChecked={true}
                                                name="serial-number-kind"
                                                onChange={handleRadioChange}
                                                className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                            <label htmlFor="unitsn" className="ml-3 block text-sm text-gray-700">
                                                Unit serial
                                            </label>
                                        </div>

                                        <div key="sn2" className="flex items-center">
                                            <input type="radio"
                                                ref={serialRef}
                                                id="sensorsn"
                                                value="sensor"
                                                name="serial-number-kind"
                                                onChange={handleRadioChange}
                                                className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                            <label htmlFor="sensorsn" className="ml-3 block text-sm text-gray-700">
                                                Sensor serial
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>

                    {/*<div className="sm:col-span-3">
                        <p className="text-sm font-medium text-red-800">Fixme: duplicate serials</p>
                    </div>*/}

                    <div className="sm:col-span-10">
                        <div className='text-sm font-medium text-gray-500'>Device serial numbers, one per line: </div>
                        <textarea onChange={handleTextAreaChange} ref={textAreaRef} className='w-full h-32 rounded-md border-gray-300 shadow-sm focus:border-isober-500 focus:ring-isober-500' />
                    </div>
                </div>
                {/* end form */}

                {deviceList.length > 0 && <div className='grid grid-cols-6'>
                    {deviceList.map((device, idx) => (
                        <div key={`note_${device.modelGuid}_${device.serial}`} className="gap-6 my-2">
                            <span className="inline-flex items-center rounded-md bg-indigo-50 px-2.5 py-0.5 text-sm font-medium text-indigo-900">
                                <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                    <circle cx={4} cy={4} r={3} />
                                </svg>
                                {device.serial && device.serial}
                                {device.sensorSerial && device.sensorSerial}
                            </span>
                        </div>
                    ))}
                </div>}

            </div>
        </div>
    )
}