import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet-async';
import { formatDateShort } from '../../Shared/Components/functions.js'

// Parameters:
//   * props.open                 Drives dialog visibility
//   * props.item                 Calibration record from firestore [unitserial, dts*, validity, authUserName, unitserial, sensorserial, macaddress]
//                                    NB: dts will be undefined from the calibrate screen
//   * props.onPrintClosed()      Close handler

export default function PrintLabelOverlay(props) {
    // STATE
    const [open, setOpen] = useState(props.open)
    const [isBrowserSupported, setIsBrowserSupported] = useState(undefined);
    const [isFrameworkInstalled, setIsFrameworkInstalled] = useState(undefined);
    const [isWebServicePresent, setIsWebServicePresent] = useState(undefined);
    const [statusMsg, setStatusMsg] = useState(undefined);
    const [selectedPrinterName, setSelectedPrinterName] = useState(undefined);
    const [label, setLabel] = useState(undefined);
    const [pngData, setPngData] = useState(undefined);

    // FLOW: checkEnvironment() -> loadPrinters() -> loadLabelFile() -> injectData() -> canPrint() -> printLabel()

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    const scriptOnload = (el) => {
        if (window.dymo.label.framework.init) {
            window.dymo.label.framework.init();
        }
    }

    function closeDialog() {
        setOpen(false);
        props.onPrintClosed();
    }

    // FUNCTIONS

    const injectData = (labelX) => {
        if (!props.item) {
            setStatusMsg("Label data not set, cannot inject");
            return;
        }

        if (!labelX) {
            setStatusMsg("Label not set, cannot inject");
            return;
        }

        // generic fields:
        labelX.setObjectText("SERIALNO", displaySerialNumber());
        labelX.setObjectText("BARCODE", props.item.unitserial);
        labelX.setObjectText("NEXTCAL", formatDateShort(nextCaldate(props.item.validity)));
        labelX.setObjectText("TECHNICIAN", props.item.authUserName);

        // calibration date logic:
        var caldateDisplay = formatDateShort(new Date());
        if (props.item.backdateString !== undefined) {
            // turn string into a Date first, then format
            const t1 = yyyymmddToDate(props.item.backdateString);
            caldateDisplay = formatDateShort(t1);
        } else if (props.item.backdate !== undefined && props.item.backdate instanceof Date) {
            caldateDisplay = formatDateShort(props.item.backdate.toDate());
        } else if (props.item.dts !== undefined) {
            caldateDisplay = formatDateShort(props.item.dts.toDate());
        }
        labelX.setObjectText("CALDATE", caldateDisplay);
    }

    const displaySerialNumber = () => {
        if (props.item.unitserial && props.item.sensorserial) {
            return `${props.item.unitserial} / ${props.item.sensorserial}`;
        } else if (props.item.unitserial) {
            return props.item.unitserial;
        } else if (props.item.sensorserial) {
            return props.item.sensorserial;
        } else if (props.item.macaddress) {
            return props.item.macaddress;
        }

        return "Unknown";
    }

    const yyyymmddToDate = (dateString) => {
        const regex = /^(\d{4})\/(\d{2})\/(\d{2})$/;
        const matches = dateString.match(regex);

        if (!matches) {
            return undefined;
        }

        const year = parseInt(matches[1], 10);
        const month = parseInt(matches[2], 10) - 1; // month is 0-based
        const day = parseInt(matches[3], 10);

        if ((year >= 2023 && year <= 2035) && (month >= 0 && month <= 12) && (day >= 1 && day <= new Date(year, month, 0).getDate())) {
            // return the backdated date, preserving the current time
            var currentDate = new Date();
            var hh = currentDate.getHours();
            var mm = currentDate.getMinutes();
            var ss = currentDate.getSeconds();
            return new Date(year, month, day, hh, mm, ss);
        }
        
        console.log('[calibrate] Backdate date is invalid');
        return undefined;
    }

    // validity = 6 months or 12 months
    const nextCaldate = (validity) => {
        const addition = (validity && validity === "6 months") ? 6 : 12;
        
        // logic for next calibration of a backdated cal
        // duplicated in certificate.js ± ln 104
        var calDate = new Date();
        if (props.item.backdateString !== undefined) {
            calDate = yyyymmddToDate(props.item.backdateString);
        } else if (props.item.backdate !== undefined && props.item.backdate instanceof Date) {
            calDate = props.item.backdate.toDate();
        } else if (props.item.dts !== undefined) {
            calDate = props.item.dts.toDate();
        }
        
        const nextDate = new Date(calDate.setMonth(calDate.getMonth() + addition));

        return nextDate;
    }

    const loadPrinters = async () => {
        window.dymo.label.framework.getPrintersAsync().then(function (printers) {
            if (printers.length === 0) {
                setStatusMsg("No DYMO printers are installed. Install DYMO printers.");
                return;
            } else {
                setSelectedPrinterName(printers[0].name); // also: printerType, name, modelName, isConnected, isLocal, printerUri, originalPrinterName, isTwinTurbo
                loadLabelFile();
            }
        });
    }

    const loadLabelFile = () => {
        fetch('/template.label')
            .then((r) => r.text())
            .then(text => {
                var labelLabel = window.dymo.label.framework.openLabelXml(text)

                if (!labelLabel.isValidLabel()) {
                    setStatusMsg(" The file is not a valid label");
                    setLabel(undefined);
                    return;
                } else if (labelLabel.isDCDLabel() || labelLabel.isDLSLabel()) {
                    injectData(labelLabel);
                    var renderedData = labelLabel.render();
                    setPngData(renderedData);
                    setLabel(labelLabel); // "DYMO Connect label" or "DLS label"
                }
            });
    }

    const checkEnvironment = () => {
        // reset
        setStatusMsg(undefined);
        setSelectedPrinterName(undefined);

        try {
            if (window.dymo.label.framework.init) {
                var result = window.dymo.label.framework.checkEnvironment();
                setIsBrowserSupported(result.isBrowserSupported);
                setIsFrameworkInstalled(result.isFrameworkInstalled);
                setIsWebServicePresent(result.isWebServicePresent);
                setStatusMsg(result.errorDetails);

                loadPrinters();
            }
        } catch (e) {
            setStatusMsg(e.message || e);
        }
    }

    const printLabel = () => {
        if (label === undefined) {
            setStatusMsg("No label, cannot print");
            return;
        }

        if (selectedPrinterName === undefined) {
            setStatusMsg("No printer selected, cannot print");
            return;
        }

        label.print(selectedPrinterName);
    }

    const canPrint = () => {
        return (isBrowserSupported === true && isFrameworkInstalled === true && isWebServicePresent === true);
    }

    const mustCheckStatus = () => {
        return (isBrowserSupported === undefined || isFrameworkInstalled === undefined || isWebServicePresent === undefined);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
                <Helmet>
                    <script async defer src="/dymo.connect.framework.full.js" onLoad={`(${scriptOnload.toString()})(this)`} />
                </Helmet>

                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500" onClick={() => closeDialog()}>
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Print label
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className="text-sm text-gray-500">
                                            {props.item !== undefined && <div>
                                                <p className="text-sm text-gray-500">Label for {displaySerialNumber()} by {props.item.authUserName}</p>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="sm:divide-y sm:divide-gray-200">
                                        {props.item === undefined && <div>
                                            No data received!
                                        </div>}

                                        {statusMsg !== undefined && statusMsg !== "" && <div className='mt-2'>{statusMsg}</div>}

                                        {props.item !== undefined && canPrint() && pngData && <div>
                                            <img className='mt-3' src={`data:image/png;base64,${pngData}`} alt="Preview" width="260" height="160" />
                                        </div>}

                                        {(props.item === undefined || !pngData || !canPrint) && <div>
                                            <img className='mt-3' src="/images/labelplaceholder.png" alt="Preview" width="260" height="160" />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog()}>
                                    Close
                                </button>

                                {props.item !== undefined && mustCheckStatus() && <button type='button' className="inline-flex justify-center mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500" onClick={() => checkEnvironment()}>Preview</button>}

                                {props.item !== undefined && canPrint() && pngData && <button type='button' className="inline-flex justify-center mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500" onClick={() => printLabel()}>Print</button>}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}