import React from 'react';
import Header from '../Components/Header/index';
import Footer from '../Components/Footer/index';

function TermsPage(props) {
    return (
        <div>
            <Header title="Terms of Use" />


            <div className="relative py-16 bg-white overflow-hidden">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                    </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <p className="mt-8 text-xl text-gray-500 leading-8">The iSober App and Cloud service is a cutting-edge bluetooth breathalyser test management platform for day-to-day capturing, sharing, tracking and storage of breathalyser test data for social users, drivers, business owners, fleet operators and virtually anyone who has a truck, bus or vehicle who needs to test drivers remotely without having to physically test the User themselves.</p>
                        <p className="mt-8 text-xl text-gray-500 leading-8">iSober enables the owners of vehicles to prompt their drivers in remote locations to perform breathalyser tests on themselves where the test data then becomes available in real-time for sharing and analysis. Breathalyser tests are matched with photographic identity, GPS position, date, time, serial number of device and test result.</p>
                        <p className="mt-8 text-xl text-gray-500 leading-8">Enterprise users who conduct daily, random or mandatory breathalyser tests on drivers, employees or contractors are able to utilise the iSober App and Cloud service to obtain definitive breath alcohol test results that enables operators to prevent drink drivers from operating vehicles, to prevent intoxicated employees entering work-places and to gather substantive evidence for disciplinary action if need be.</p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default TermsPage;
