import { collection, query } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export default function useJobCardDevices(jobcardGuid) {
    const firestore = useFirestore();
    const devicesCollection = collection(firestore, `jobcards/${jobcardGuid}/devices`);
    var devicesQuery = query(devicesCollection);
    const { status, data: devices } = useFirestoreCollectionData(devicesQuery, {
        idField: 'id', // merge id into object created for each document
    });

    if (status !== 'success') {
        return undefined;
    }
    
    return devices;
}