import React, { useState, useEffect } from 'react';
import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

// deconstruct props.initialSelection
export default function DevicesAsCheckboxes({ initialSelection, form }) {

    // FIRESTORE
    const firestore = useFirestore();
    const devicesCollection = collection(firestore, `devicemodels`);
    const activeQuery = query(devicesCollection, where('deleted', '==', false));
    const { data: devices } = useFirestoreCollection(activeQuery);

    // STATE

    const [selectedCheckboxes, setSelectedCheckboxes] = useState((initialSelection !== undefined && initialSelection !== null && Array.isArray(initialSelection)) ? initialSelection : []);

    // INTERNAL

    function checkboxChanged(e) {
        const isChecked = e.target.checked
        const value = e.target.value

        if (isChecked === true) {
            const temp = [...selectedCheckboxes, value];
            setSelectedCheckboxes(temp);
        } else {
            var temp = selectedCheckboxes.filter(item => item !== value);
            setSelectedCheckboxes(temp);
        }
    }

    // lifts state up to edit/create CRM page
    // can't print debug output of setSelectedCheckboxes immediately since setState is asyncronous
    // NB don't trigger validation on touched per https://github.com/jaredpalmer/formik/issues/2059
    useEffect(() => {
        console.log("[DevicesAsCheckboxes] useEffect() " + JSON.stringify(selectedCheckboxes));
        form.setFieldValue('validdevices', selectedCheckboxes);
        form.setFieldTouched('validdevices', true, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCheckboxes, form.setFieldTouched, form.setFieldValue]);

    // EMPTY STATE
    if (devices.size === 0) {
        return (
            <div className="mt-5 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">No device models are currently loaded.</p>
                    </div>
                </div>
            </div>
        );
    }

    // GO
    return (
        <div>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-5">
                {devices.docs.map((device, deviceIdx) => (
                    <li key={`li_${deviceIdx}`} className="col-span-1 flex shadow-sm rounded-md">
                        <div className="flex-shrink-0 flex items-center justify-center w-10 border-l border-t border-b border-gray-200 rounded-l-md">
                            <input id={deviceIdx} type="checkbox" onChange={(e) => checkboxChanged(e)} name="validForDevices" value={device.id} defaultChecked={initialSelection.includes(device.id)} className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300 rounded" />
                        </div>
                        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex items-center justify-center h-12">
                                <label htmlFor={deviceIdx} className="text-sm text-gray-700">{device.data().displayname}</label>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}