import React, { useState } from 'react';

// https://www.pluralsight.com/guides/event-listeners-in-react-components

function TopazSignatureCanvas(props) {
    const [signatureImage, setSignatureImage] = useState(undefined);
    const [topazStatusOpen, setTopazStatusOpen] = useState(false);
    const [topazStatusResult, setTopazStatusResult] = useState(undefined);
    const [topazSignOpen, setTopazSignOpen] = useState(false);
    const [topazSignResult, setTopazSignResult] = useState(undefined);

    const handleTopazDeviceStatusResponse = (event) => {
        var str = event.target.getAttribute("msgAttribute");
        var obj = JSON.parse(str);

        switch (obj.deviceStatus) {
            case 0:
                setTopazStatusResult("No device was detected.");
                break;
            case 1:
                setTopazStatusResult("Topaz signature pad was detected.");
                break;
            case 2:
                setTopazStatusResult("GemView Tablet Display was detected.");
                break;
            case -1:
                setTopazStatusResult("An error occured detecting the device.");
                break;
            case -2:
                setTopazStatusResult("SigPlusExtLite is not installed.");
                break;
            case -3:
                setTopazStatusResult("SigPlus drivers are not installed.");
                break;
            case -4:
                setTopazStatusResult("An older version of SigPlus drivers are installed.");
                break;
            default:
                setTopazStatusResult("Device status is unknown.");
                break;
        }
    }

    // need to use a callback due to onSignatureReceived which introduced lint errors that effect will cause constant re-renders
    const handleTopazSignResponse = React.useCallback(
        (event) => {
            var str = event.target.getAttribute("msgAttribute");
            var obj = JSON.parse(str);

            // an image was received
            if (obj.imageData !== "") {
                setSignatureImage("data:image/png;base64," + obj.imageData); // need to prepend in order to match what the canvas version also sends to tabRecorded
                setTopazSignOpen(false);
                props.onSignatureReceived(obj.imageData); // lift up to index component
            }

            // sign modal is not open and no image received - possibly user dismissed sign modal, then dismissed topaz sign window
            if (obj.imageData === "") {
                setTopazSignResult("No image received.");
            }
        }, [props],
    );

    React.useEffect(() => {
        // register for events this component can receive
        window.addEventListener('GetVersionInfoResponse', handleTopazVersionInfoResponse);
        window.addEventListener('SignResponse', handleTopazSignResponse);
        window.addEventListener('UnsupportedCommandResponse', handleUnsupportedCommandResponse);
        window.addEventListener('GetDeviceStatusResponse', handleTopazDeviceStatusResponse);

        // remove listeners on cleanup
        return () => {
            window.removeEventListener('GetVersionInfoResponse', handleTopazVersionInfoResponse);
            window.removeEventListener('UnsupportedCommandResponse', handleUnsupportedCommandResponse);
            window.removeEventListener('GetDeviceStatusResponse', handleTopazDeviceStatusResponse);
            window.removeEventListener('SignResponse', handleTopazSignResponse);
        };
    }, [handleTopazSignResponse]);

    const handleUnsupportedCommandResponse = (event) => {
        window.alert("Unsupported command response from Topaz");
    }

    const handleTopazVersionInfoResponse = (event) => {
        var str = event.target.getAttribute("msgAttribute");
        if (str == null || str === "") {
            str = event.target.getAttribute("msg-Attribute");
        }
        var obj = JSON.parse(str);
        window.alert("handleTopazVersionInfoResponse: " + JSON.stringify(obj));

        // status: If the value is “1”, version information was retrieved successfully. If value is “0”, the version information could not be retrieved. -1 is returned if SigPlusExtLite is not installed or an older version of SigPlusExtLite is installed.
        // spelVersion: The version of SigPlusExtLite installed. The value of “spelVersion” attribute will have the version of SPEL if the value of “status” attribute is 1.
        // errorMsg: Error message returned from SigPlusExtLite SDK in case an error has occurred. The value of “errorMsg” attribute will have the error message if the value of “status” attribute is 0 or -1.
    }

    function isTopazInstalled() {
        var isInstalled = document.documentElement.getAttribute('SigPlusExtLiteExtension-installed');

        if (!isInstalled) {
            return false;
        }

        return true;
    }

    function requestTopazSignature() {
        var message = {
            "metadata": { "version": 1.0, "command": "SignatureCapture" },
            "firstName": "", "lastName": "", "eMail": "",
            "location": "",
            "imageFormat": 1, "imageX": 750, "imageY": 150,
            "imageTransparency": false, "imageScaling": false,
            "maxUpScalePercent": 0,
            "rawDataFormat": "ENC",
            "minSigPoints": 25,
            "penThickness": "1", "penColor": "#000000",
            "encryptionMode": "0", "encryptionKey": "EncryptionKey",
            "sigCompressionMode": 1,
            "customWindow": false,
        }
        var messageData = JSON.stringify(message);
        var element = document.createElement("MyExtensionDataElement");
        element.setAttribute("messageAttribute", messageData);
        document.documentElement.appendChild(element);
        var evt = document.createEvent("Events");
        evt.initEvent("SignStartEvent", true, false);
        element.dispatchEvent(evt);

        setTopazSignResult(undefined);
        setTopazSignOpen(true);
    }

    function requestTopazDeviceStatus() {
        if (!isTopazInstalled()) {
            setTopazStatusOpen(true);
            setTopazStatusResult("Topaz is not installed.");
            return;
        }

        var deviceStatusRequest = JSON.stringify({ "metadata": { "version": 1.0, "command": "GetDeviceStatus" }, "deviceStatus": "" })
        var element = document.createElement("MyExtensionDataElementDeviceStatus");
        element.setAttribute("msgeAttributeDeviceStatus", deviceStatusRequest);
        element.setAttribute("msge-Attribute-VersionInfo", deviceStatusRequest);
        document.documentElement.appendChild(element);
        var evt = document.createEvent('Events');
        evt.initEvent('GetDeviceStatusEvent', true, false);
        element.dispatchEvent(evt);

        setTopazStatusResult(undefined);
        setTopazStatusOpen(true);
    }

    function requestTopazVersionInfo() {
        var versionInfoRequest = JSON.stringify({ "metadata": { "version": 1.0, "command": "GetVersionInfo" } })
        var element = document.createElement("MyExtensionDataElementVersionInfo");
        element.setAttribute("msgAttributeVersionInfo", versionInfoRequest);
        element.setAttribute("msg-Attribute-VersionInfo", versionInfoRequest);
        document.documentElement.appendChild(element);
        var evt = new Event("GetVersionInfoEvent", {"bubbles":true, "cancelable":false});
        element.dispatchEvent(evt);
    }

    function hideStatusDialog() {
        setTopazStatusResult(undefined)
        setTopazStatusOpen(false);
    }

    function hideSignDialog() {
        setTopazSignResult(undefined)
        setTopazSignOpen(false);
    }

    // Lifting state up
    function switchSignPen() {
        props.onSignatureMethodChange(false);
    }

    return (
        <div>
            { // Topaz status page modal
                topazStatusOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                        {/*  This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    {/* Heroicon name: outline/check */}
                                    <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Topaz Device Status</h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {topazStatusResult === undefined && "Loading..."}
                                            {topazStatusResult !== undefined && topazStatusResult}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <button onClick={hideStatusDialog} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                    {topazStatusResult === undefined && "Cancel"}
                                    {topazStatusResult !== undefined && "Close"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            { // Topaz sign modal
                topazSignOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                        {/*  This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    {/* Heroicon name: outline/check */}
                                    <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Topaz Sign</h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {topazSignResult === undefined && "Loading..."}
                                            {topazSignResult !== undefined && topazSignResult}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <button onClick={hideSignDialog} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                    {topazSignResult === undefined && "Cancel"}
                                    {topazSignResult !== undefined && "Close"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white px-4 space-y-6 sm:p-4">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Topaz Signature</h3>
                        <p className="mt-1 text-sm text-gray-500">Use the Topaz signature pad to accept the information above as correct.</p>
                    </div>

                    <div className="flex flex-row">
                        <div className="border-2 border-gray-300 rounded-md w-[28rem] h-[6rem]">
                            <div className="w-[28rem] h-[6rem]">
                                {signatureImage !== undefined &&
                                    <img src={signatureImage} className='mt-1' alt="Signature" style={{ width: '27rem', height: '5rem' }} />
                                } </div>
                        </div>
                        <div className=""> {/* wrap button in div or becomes part of flex on height */}

                            <button onClick={requestTopazSignature.bind(this)} type="button" className="ml-5 bg-isober-50 text-isober-900 py-2 px-3 border border-isober-50 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-isober-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                Sign...
                            </button>
                            <button onClick={requestTopazDeviceStatus.bind(this)} type="button" className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Topaz status
                            </button>
                            <button onClick={requestTopazVersionInfo.bind(this)} type="button" className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Topaz version
                            </button>

                            <div className="mt-4">    
                                <button onClick={switchSignPen} type="button" className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Switch to Tablet signature
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopazSignatureCanvas;

// <img src={`data:image/png;base64,${signatureImage}`} alt="Signature" width="100%" height="100%" />