import React, { Fragment, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
import { useIdTokenResult } from 'reactfire';
import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import DashboardPage from './Dashboard/index.js';
import LogsPage from './Logs/index.js';
import ReportsPage from './Reports/index.js';
import SupportPage from './Support/index.js';
import AccountPage from './Account/index.js';
import UsersSection from './Users/index.js';
import AlertsPage from './Alerts/index.js';
import SystemChooser from './Components/SystemChooser/index.js';
import SideNavigation from './Components/SideNavigation/index.js';
import useUserInfo from './Components/UserInfo';

export default function ApplicationHome(props) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const routerLocation = useLocation();
    const userinfo = useUserInfo(props.authUser.uid);
    const { data: userIdToken } = useIdTokenResult(props.authUser);

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" static className="fixed inset-0 flex z-40 md:hidden" open={sidebarOpen} onClose={setSidebarOpen} >
                    <Transition.Child as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0" >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full" >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-isober-900">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0" >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img className="h-10 w-auto" src="/images/logo_header.png" alt="iSober Cloud" />
                                    <p className="px-3 text-base font-bold text-white">iSober Cloud</p>
                                </div>
                                <SideNavigation path={routerLocation.pathname} claims={userIdToken.claims} />
                            </div>
                            <div className="flex-shrink-0 flex border-t border-isober-1000 p-4">
                                <div className="flex items-center">
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-white">{`${userinfo.fname} ${userinfo.lname}`}</p>
                                        {(userinfo.role === 'superadmin') &&
                                            <p className="text-xs font-medium text-white group-hover:text-white">iSober administrator</p>
                                        }
                                        {(userinfo.role === 'clientadmin') &&
                                            <p className="text-xs font-medium text-white group-hover:text-white">Administrator</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden bg-isober-900 md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col h-0 flex-1">

                        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                            <div className="flex items-center flex-shrink-0 px-4">
                                <img className="h-10 w-auto" src="/images/logo_header.png" alt="iSober Cloud" />
                                <p className="px-3 text-base font-bold text-white">iSober Cloud</p>
                            </div>

                            {(userIdToken.claims &&
                                (
                                    (userIdToken.claims.superadmin && userIdToken.claims.superadmin === true) ||
                                    (userIdToken.claims.calibrations && userIdToken.claims.calibrations === true) ||
                                    (userIdToken.claims.stock && userIdToken.claims.stock === true)
                                )) &&
                                <SystemChooser claims={userIdToken.claims} />
                            }

                            <SideNavigation path={routerLocation.pathname} claims={userIdToken.claims} />

                        </div>

                        <div className="flex-shrink-0 flex border-t border-isober-1000 p-4">
                            <div className="flex items-center">
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-white">{`${userinfo.fname} ${userinfo.lname}`}</p>
                                    {(userinfo.role === 'superadmin') &&
                                        <p className="text-xs font-medium text-white group-hover:text-white">iSober administrator</p>
                                    }
                                    {(userinfo.role === 'clientadmin') &&
                                        <p className="text-xs font-medium text-white group-hover:text-white">Administrator</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)} >
                        <span className="sr-only">Open sidebar</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                <Routes> {/* we are in /app/ */}
                    <Route path="*" element={<DashboardPage userinfo={userinfo} idToken={userIdToken} />} />
                    <Route path="account/*" element={<AccountPage userinfo={userinfo} />} />
                    <Route path="alerts/*" element={<AlertsPage userinfo={userinfo} />} />
                    <Route path="logs/*" element={<LogsPage userinfo={userinfo} />} />
                    <Route path="reports/*" element={<ReportsPage userinfo={userinfo} />} />
                    <Route path="support/*" element={<SupportPage userinfo={userinfo} claims={userIdToken.claims} />} />
                    <Route path="users/*" element={<UsersSection userinfo={userinfo} />} />
                </Routes>
            </div>
        </div>
    );
}