import React from 'react';
import { initializeFirestore } from 'firebase/firestore';
import { AuthProvider, FirestoreProvider, StorageProvider, useFirebaseApp, useInitFirestore } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import AuthCheck from './AuthCheck';

export default function Calibrations(props) {
    const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
    const auth = getAuth(app);
    const { /*status,*/ data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
        const db = initializeFirestore(firebaseApp, {});
        // await enableIndexedDbPersistence(db);
        return db;
      });

    // get user from signin page
    // https://stackoverflow.com/questions/64566405/react-router-dom-v6-usenavigate-passing-value-to-another-component
    //const { state } = useLocation();
    //const [userState /*, setUserState*/ ] = useState(state);

    //console.log("Application user:" + JSON.stringify(userState));

    // Need a sub component because useSigninCheck can't be used at this level,
    // and can't put the AuthProvider in the root level index either, 
    // hence ApplicationHome component

    // Ditto for a context to hold the user info record 

    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestoreInstance}>
                <StorageProvider sdk={getStorage(app)}>
                    <AuthCheck />
                </StorageProvider>
            </FirestoreProvider>            
        </AuthProvider>
    );
}