import React from 'react';
import { BreadcrumbsAlt} from '../Breadcrumbs/index.js';

// title= ; breadcrumbs= 
export function PageHeaderAlt(props) {
    var headerTitle = "Dashboard";
    var breadcrumbs = [];

    if (props.location.startsWith("/app/reports")) {
        headerTitle = "Measurement Reports"
        breadcrumbs = [];
    } else if (props.location.startsWith("/app/logs")) {
        headerTitle = "Activity Logs"
        breadcrumbs = [];
    } else if (props.location.startsWith("/app/alerts/user/")) {
        headerTitle = "Alerts by User"
        breadcrumbs = [{title: "Manage alerts", href: "/app/alerts"}];
    } else if (props.location.startsWith("/app/alerts/create")) {
        headerTitle = "Create Alert"
        breadcrumbs = [{title: "Manage alerts", href: "/app/alerts"}];
    } else if (props.location.startsWith("/app/alerts/edit")) {
        headerTitle = "Edit Alert Details"
        breadcrumbs = [{title: "Manage alerts", href: "/app/alerts"}];
    } else if (props.location.startsWith("/app/alerts/")) {
        headerTitle = "Alert Details"
        breadcrumbs = [{title: "Manage alerts", href: "/app/alerts"}];
    } else if (props.location.startsWith("/app/alerts")) {
        headerTitle = "Manage Alerts"
        breadcrumbs = [];
    } else if (props.location.startsWith("/app/reading/")) {
        headerTitle = "Measurement detail"
        breadcrumbs = [{title: "Dashboard", href: "/app"}];
    } else if (props.location.startsWith("/app/readings/user/")) {
        headerTitle = "User Measurements"
        breadcrumbs = [{title: "Dashboard", href: "/app"}, {title: "Measurement", href: "/app"}];
    } else if (props.location.startsWith("/app/account")) {
        headerTitle = "My Account"
        breadcrumbs = [];
    } else if (props.location.startsWith("/app/users/user")) {
        headerTitle = "User details"
        breadcrumbs = [{title: "Manage users", href: "/app/users"}];
    } else if (props.location.startsWith("/app/users/edit")) {
        headerTitle = "Edit User Details"
        breadcrumbs = [{title: "Manage users", href: "/app/users"}];
    } else if (props.location.startsWith("/app/users/create")) {
        headerTitle = "Create New User"
        breadcrumbs = [{title: "Manage users", href: "/app/users"}];
    } else if (props.location.startsWith("/app/users")) {
        headerTitle = "Manage Users"
        breadcrumbs = [];
    } else if (props.location.startsWith("/app/support")) {
        headerTitle = "Support"
        breadcrumbs = [];
    } 

    return (
        <div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{headerTitle}</h2>
                </div>
            </div>

            <BreadcrumbsAlt items={breadcrumbs} />
        </div>
    )
}

export default function PageHeader(props) {
    return (
        <div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{props.title}</h2>
                </div>
            </div>

            <BreadcrumbsAlt items={[{title: "Dashboard", href: "/app"}, {title: "B", href: "#2"}]} />
        </div>
    )
}