import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <footer className="bg-gray-800">
            <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
                    <div className="px-5 py-2">
                        <a href="https://breathalysers.co.za" className="text-base leading-6 text-gray-400 hover:text-gray-300">Alcohol Breathalysers</a>
                    </div>
                    <div className="px-5 py-2">
                        <Link to="/privacy" className="text-base leading-6 text-gray-400 hover:text-gray-300">Privacy statement</Link>
                    </div>
                    <div className="px-5 py-2">
                        <Link to="/terms" className="text-base leading-6 text-gray-400 hover:text-gray-300">Terms of Use</Link>
                    </div>
                </nav>
                <div className="mt-8 flex justify-center space-x-6">
                    <a href="https://www.facebook.com/alcoholbreathalysers" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Facebook</span>
                        <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/breathalyserguy" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Instagram</span>
                        <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                        </svg>
                    </a>
                    <a href="https://twitter.com/BreathalyserGuy" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Twitter</span>
                        <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/alcohol-breathalysers-pty-ltd" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">LinkedIn</span>
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                            <g id="linkedin-logo-svgrepo-com" fill="currentcolor" fillRule="nonzero">
                                <path d="M24,0 L24,24 L0,24 L0,0 L24,0 L24,0 Z M7.44375,9.21375 L3.86475,9.21375 L3.86475,19.97925 L7.44375,19.97925 L7.44375,9.21375 Z M7.67775,5.88525 C7.65525,4.82775 6.9015,4.02375 5.67675,4.02375 C4.4535,4.02375 3.65325,4.82775 3.65325,5.88525 C3.65325,6.9165 4.4295,7.7445 5.63025,7.7445 L5.6535,7.7445 C6.9015,7.7445 7.67775,6.9165 7.67775,5.88525 Z M20.34675,13.806 C20.34675,10.5 18.5805,8.96175 16.22625,8.96175 C14.328,8.96175 13.47825,10.005 13.0005,10.73775 L13.0005,9.21375 L9.423,9.21375 C9.46875,10.22325 9.423,19.97925 9.423,19.97925 L13.0005,19.97925 L13.0005,13.96725 C13.0005,13.64175 13.02375,13.326 13.11825,13.09425 C13.37775,12.45375 13.96725,11.78475 14.95425,11.78475 C16.251,11.78475 16.76775,12.77025 16.76775,14.21925 L16.76775,19.97925 L20.346,19.97925 L20.34675,13.806 L20.34675,13.806 Z" id="Shape"></path>
                            </g>
                        </svg>
                    </a>
                </div>
                <p className="mt-8 text-center text-base leading-6 text-gray-500">&copy; 2021 Alcohol Breathalysers (Pty) Ltd. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;