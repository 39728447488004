import React from 'react';

export function BranchControl({ handler, sfv, sft }) {
    const branches = [
        { id: '1', title: 'Johannesburg' },
        { id: '2', title: 'Cape Town' },
        { id: '3', title: 'Durban' },
        { id: '4', title: 'Client site' },
        { id: '5', title: 'Botswana' },
    ]

    const liftState = (el) => {
        if (el !== undefined && el.target !== undefined && el.target.value !== undefined) {
            handler(el.target.value, sfv, sft);
        }
    }

    return (
        <div className="sm:col-span-3">
            <dt className="text-sm font-medium text-gray-500">
                Branch
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6">
                        <div className="mt-0"></div>
                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            {branches.map((branch) => (
                                <div key={branch.id} className="flex items-center">
                                    <input type="radio"
                                        id={branch.id}
                                        value={branch.id}
                                        name="notification-method"
                                        onChange={liftState}
                                        defaultChecked={branch.id === '1'}
                                        className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                    <label htmlFor={branch.id} className="ml-3 block text-sm text-gray-700">
                                        {branch.title}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </dd>
        </div>
    )
}