import { collection, query, orderBy, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export default function useSkus(category='breathalyser') {
    // set up firestore query
    const firestore = useFirestore();
    const skusCollection = collection(firestore, 'stock/');
    const collectionQuery = query(skusCollection, where('enabled', '==', true), where('category', '==', category), orderBy('enabled', 'desc'), orderBy('sku', 'asc'));
    const { status, data: skus } = useFirestoreCollectionData(collectionQuery, {
        idField: 'id', // merge id into object created for each document
    });

    if (status === 'loading') {
        return undefined;
    }

    return skus;
}
