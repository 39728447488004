import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export default function useCustomer(customerGuid) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'calibrationclients', customerGuid);
    const { status, data: customer } = useFirestoreDocDataOnce(documentRef, { idField: "id" });

    if (status !== 'success') {
        console.log("Customer for " + status + " = undefined");
        return undefined;
    }

    return customer;
}